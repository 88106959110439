:root {
  --text-title:#374151;
  --text-body:#6F6F6F;
  --text-caption:#969696;
  --text-disabled: #B6B6B6;

  --red: #F7685B;
  --red-dark: #CB1C23;
  --orange:#FF9500;
  --orange-dark:#CE5B1A;
  --yellow:#FFCC00;
  --yellow-dark:#E3BA24;
  --blue:#007AFF;
  --blue-dark:#1E58BB;
  --orange:#AF52DE;
  --orange-dark:#7F3AA4;
  --green:#1E983A;
  --green-dark:#039C42;

  --mono-gray1:#333333;
  --mono-gray2:#4F4F4F;
  --mono-gray3:#828282;
  --mono-gray4:#BDBDBD;
  --mono-gray5:#E0E0E0;
  --mono-gray6:#F2F2F2;
  --mono-gray7:#FFFFFF;

  --main-gray:#F2F2F2;

  --primary1:#F9A215;
  --primary2: #D68605;
  --primary3:#FFF8F0;

  --secondary1:#8DC5E9;
  --secondary2:#387297;
  --secondary3:#EFFAFF;
}
.ct-primary1{
  color:var(--primary1);
}
.ct-primary2{
  color:var(--primary2) !important;
}
.ct-title{
  color:var(--text-title);
}
.ct-red{
  color:var(--red);
}
.ct-body{
  color:var(--text-body);
}
.ct-caption{
  color:var(--text-caption);
}
.ct-disabled{
  color:var(--text-disabled);
}
.bg-gray{
  background-color:var(--main-gray);
}
.bg-light-brown{
  background-color:var(--primary3);
}
.bg-primary3{
  background-color:var(--primary3);
}
.ct-mono-gray7{
  color:var(--mono-gray7) !important;
}