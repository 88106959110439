.filter-sort {
    margin-bottom: 30px;
}
.filter-category, .filter-submit {
    padding-top: 0px;
}

.filter-submit {
    margin-top: auto;
    margin-bottom: 30px;
}

.filter-sort h5, .filter-category h5 {
    margin-bottom: 15px;
    font-weight: 600;
}

.filter-submit .btn {
    padding:12px 20px;
}

.filter-sort .btn, .filter-category .btn {
    margin: 10px 10px 0px 0px;
    color:var(--text-body);
    border: 1px solid var(--mono-gray5);
}
.filter-sort .btn-disabled, .filter-category .btn-disabled{
    background: #FFFF;
}

.filter-sort .btn:focus, .filter-category .btn:focus{
    box-shadow: none;
}

.filter-sort .btn:hover, .filter-category .btn:hover {
    background-color: #FFEED9;
    border: 1px solid var(--primary1);
    color: var(--primary1);
    font-weight: 600;
}
.filter-category .btn-filter-active, .filter-sort .btn-filter-active{
    color:#FFFF !important;
    border:none !important;
    background: var(--primary1) !important;
}