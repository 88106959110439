
.form-hook .form-group label, .error-input{
	margin-left:16px;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	color:var(--text-caption);
}
.form-hook .error-input{
	color:var(--red);
	margin-top:4px;
}
.form-hook input, .form-hook textarea, .form-hook select, .form-control{
    border: 1px solid var(--text-disabled);
    box-sizing: border-box;
    border-radius: 8px;
    padding:10.5px 16px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color:#242424;
    height:auto;
}
.form-hook .fh-input-group{
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

.form-hook textarea{
	min-height: 128px;
}

.form-hook .input-group input{
	border-right:none;
}
.form-hook .input-group .input-group-prepend > span{
	background: #ffffff;
    border-radius: 0px 5px 5px 0px;
    border: 1px solid #D4D2D3;
    border-left: none;
}


.form-hook .ig-left, .form-hook .ig-right {
    position: relative;
}
.form-hook .ig-right .form-control{
	padding-right:45px;
	cursor: pointer;
}
.form-hook .ig-right .input-right{
  position: absolute;
  right: 0;
  margin-right: 16px;
  top: 12px;
}

.form-hook .ig-left .form-control{
	padding-left:64px;
}
.form-hook .ig-left .input-left{
	position: absolute;
    left: 0;
    top: 1px;
    border-right: 1px solid var(--text-disabled);
    padding: 11px 16px;
}

.form-hook .btn-next{
	margin-top:40px;
}

.input-file .container-file{
	border-radius: 5px;
	border: 1px solid var(--text-disabled);
	padding:10px 16px;
}

/* ck editor */

.ck.ck-editor__editable_inline{
	border: 1px solid var(--text-disabled);
	min-height: 300px;
  }
  .ck-toolbar, .ck-content{
	border-radius:5px !important;
	border:none !important;
  }
  
  .container-ckeditor{
	border: 1px solid #c4c4c4;
	border-radius: 4px;
  }
  .ck-focused{
	box-shadow: none !important;
  } */


/* form alert */
.error-input {
	color: var(--red);
	margin-top:5px;
}

/* Responsive */
@media (min-width: 411px){
	/* body */
	.form-hook input, .form-hook textarea, .form-hook select, .form-hook .fh-input-group, .form-control{
	  /* font-size: 14px; */
	  /* line-height: 22px; */
	}
  }
  
  
  @media (max-width: 420px) {
	.form-hook input, .form-hook textarea, .form-hook select, .form-hook .fh-input-group, .form-control{
		/* font-size: 12px; */
		/* line-height: 18px; */
	}
	.form-hook .form-group label, .error-input{
		/* font-size: 10px !important; */
		/* line-height: 16px; */
	}
  }


/* toggles */
@keyframes ripple {
	 0% {
		 box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.0);
	}
	 50% {
		 box-shadow: 0px 0px 0px 15px rgba(0, 0, 0, 0.1);
	}
	 100% {
		 box-shadow: 0px 0px 0px 15px rgba(0, 0, 0, 0);
	}
}
.md-radio {
	margin: 16px 0;
}
.md-radio.md-radio-inline {
	display: inline-block;
}
.md-radio input[type="radio"] {
	display: none;
}
.md-radio input[type="radio"]:checked + label:before {
	border-color: var(--primary1);
	background: var(--primary1);
	animation: ripple 0.2s linear forwards;
}
.md-radio input[type="radio"]:checked + label:after {
	transform: scale(1);
}
.md-radio label {
	display: inline-block;
	min-height: 20px;
	position: relative;
	padding: 0 30px;
	margin-bottom: 0;
	cursor: pointer;
	vertical-align: bottom;
}
.md-radio label:before, .md-radio label:after {
	 position: absolute;
	 content: '';
	 border-radius: 50%;
	 transition: all 0.3s ease;
	 transition-property: transform, border-color;
}
.md-radio label:before {
	left: 0;
    width: 20px;
    height: 20px;
    border: 1px solid var(--primary1);
}
.md-radio label:after {
    left: 3px;
    width: 14px;
    height: 14px;
	transform: scale(0);
	background: var(--primary1);
	top:3px;
}
*, *:before, *:after {
	 box-sizing: border-box;
}


.styled-checkbox {
	position: absolute;
	opacity: 0;
}
.styled-checkbox + label {
	position: relative;
	cursor: pointer;
	padding: 0;
	color:var(--text-body);
	margin-left:25px !important;
	/* margin-left:45px !important; */
}
.styled-checkbox + label:before {
	content: '';
	margin-right: 10px;
	display: inline-block;
	vertical-align: text-top;
	width: 21px;
    height: 21px;
    border: 1px solid var(--mono-gray4);
	background-color: #ffffff;
	border-radius: 4px;
	margin-top:-2px;
	position:absolute;
	left:-32px;
	top:4px;
}
/* .styled-checkbox:hover + label:before { */
.styled-checkbox:hover {
	background: var(--primary1);
	border-color: var(--primary1);
}
.styled-checkbox:focus + label:before {
	box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
}
.styled-checkbox:checked + label:before {
	background: var(--primary1);
	border-color: var(--primary1);
}
.styled-checkbox:disabled + label {
	color: #b8b8b8;
	cursor: auto;
}
.styled-checkbox:disabled + label:before {
	box-shadow: none;
	background: #ddd;
}
.styled-checkbox:checked + label:after {
	content: '';
    position: absolute;
	left: -27px;
    top: 11px;
    background: white;
    width: 3px;
    height: 3px;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
    transform: rotate(45deg);
}
