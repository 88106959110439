@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
body {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-size: 12px;
  background-color: #e5e5e5;
}
a,
a:hover {
  color: var(--primary1);
  text-decoration: none;
}
h5 {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.17px;
}
h6 {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.17px;
}
.cursor-pointer {
  cursor: pointer;
}

.w-40 {
  width: 40% !important;
}
.w-60 {
  width: 60% !important;
}
.w-90 {
  width: 90% !important;
}
.w-80 {
  width: 80% !important;
}
.w-70 {
  width: 70% !important;
}
.w-30 {
  width: 30% !important;
}
.ma-0 {
  margin: auto;
}
.mt-10 {
  margin-top: 10px;
}
.m-20 {
  margin: 20px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-16 {
  margin-top: 16px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-15 {
  margin-bottom: 15px;
}
.mb-16 {
  margin-bottom: 16px;
}
.mb-20 {
  margin-bottom: 20px;
}
.pt-10 {
  padding-top: 10px;
}
.pt-20 {
  padding-top: 20px;
}
.pb-10 {
  padding-bottom: 10px;
}
.pb-20 {
  padding-bottom: 20px;
}
.pl-20 {
  padding-left: 20px;
}
.pr-20 {
  padding-right: 20px;
}
main {
  min-height: calc(100vh);
  position: relative;
  width: 100%;
  box-sizing: border-box;
  background-color: #ffffff;
  padding-top: 57px;
  padding-bottom: 62px;
}
.top-logo {
  padding: 12px 16px;
  background: var(--primary3);
  text-align: center;
}
.container {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 30px;
}
.section {
  padding: 16px 20px;
}
.box-bordered {
  border: 1px solid var(--mono-gray5);
  border-radius: 8px;
  padding: 12px 16px;
  margin-top: 5px;
}
.box-gradient-bottom {
  background: linear-gradient(
    360deg,
    #ffeed9 18.53%,
    rgba(255, 255, 255, 0) 100%
  );
}
.content-space {
  position: relative;
  bottom: 0;
  width: 100%;
  color: black;
  height: 3px;
  border-bottom: 5px solid #f2f2f2;
  /* box-shadow: 0px 6px 16px rgb(151 151 151 / 16%); */
}
.content-space::before {
  content: "";
  position: absolute;
  width: 99%;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
  margin-left: 2px;
  top: -11px;
  height: 10px;
}

.content-space::after {
  content: "";
  position: absolute;
  width: 100%;
  bottom: 1px;
  height: 14px;
  background: white;
}
.nav,
main {
  margin: 0 auto !important;
  max-width: 480px;
}

.no-header {
  padding-top: 25px;
}

.no-border {
  border: none !important;
}
.no-padding {
  padding: 0px !important;
}
.no-bg {
  background: none !important;
}
.no-bs {
  box-shadow: none !important;
}

.nav {
  padding-top: 10px;
}

.navbar .btn {
  margin-left: 20px;
  margin-right: 20px;
}

/*---------------------------------*/

/* custom button */
button:focus {
  outline: none !important;
}

.btn {
  letter-spacing: -0.165px;
  border-radius: 10px;
  font-weight: 600;
  padding: 13.5px 16px;
  font-size: 14px;
  line-height: 22px;
}
.btn .arrow-right {
  margin-left: 12px;
}
.btn-lg {
  padding: 16.5px 16px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}
.btn-sm {
  font-weight: 600;
  padding: 8px 16px;
  font-size: 14px;
  line-height: 22px;
}

.btn-primary {
  background: linear-gradient(99.96deg, #ff8514 6.49%, var(--primary1) 92.13%);
  box-shadow: 0px 6px 16px rgba(255, 133, 20, 0.16);
  border: none;
  color: #ffffff;
}
.btn-primary:hover {
  background-color: var(--primary2);
  border: 1px solid var(--primary2);
  color: #ffffff;
}
.btn-primary.focus,
.btn-primary:focus,
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active {
  background: linear-gradient(99.96deg, #ff8514 6.49%, var(--primary1) 92.13%);
  box-shadow: 0px 6px 16px rgba(255, 133, 20, 0.16);
  border: none;
}
.btn-primary.disabled,
.btn-primary:disabled {
  background-color: #e5e7e9;
  border: 1px solid #e5e7e9;
  /* color:var(--text-disabled); */
}

.btn-donate {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  font-style: normal;
  background: #007ff8;
  box-shadow: 0px 1px 3px rgba(0, 127, 248, 0.1),
    0px 1px 2px -1px rgba(0, 127, 248, 0.1);
  border-radius: 8px;
  border: none;
  color: #ffffff;
}
.btn-donate:hover {
  background: #66b2fb;
  box-shadow: 0px 1px 3px rgba(0, 127, 248, 0.1),
    0px 1px 2px -1px rgba(0, 127, 248, 0.1);
  border: none;
  color: #ffffff;
}
.btn-donate.focus,
.btn-donate:focus,
.btn-donate:not(:disabled):not(.disabled).active,
.btn-donate:not(:disabled):not(.disabled):active {
  background: linear-gradient(92.07deg, #3399f9 0%, #0066c6 99.85%);
  box-shadow: 0px 1px 3px rgba(0, 127, 248, 0.1),
    0px 1px 2px -1px rgba(0, 127, 248, 0.1);
  border-radius: 8px;
  border: none;
}
.btn-donate.disabled,
.btn-donate:disabled {
  background-color: #e5e7e9;
  border: 1px solid #e5e7e9;
  /* color:var(--text-disabled); */
}

.btn-secondary {
  background-color: transparent;
  border: 1px solid var(--primary1);
  color: var(--primary2);
}
.btn-secondary:hover {
  background-color: #ffeed9;
  color: var(--primary2);
  border: 1px solid var(--primary1);
}
.btn-secondary.focus,
.btn-secondary:focus,
.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active {
  background: #fff8f0;
  border: 1px solid #f9a215;
  box-sizing: border-box;
  box-shadow: inset 2px 2px 10px #f9a215;
  color: var(--primary2);
}
.btn-secondary.disabled,
.btn-secondary:disabled {
  background-color: #e5e7e9;
  border: 1px solid #e5e7e9;
  color: var(--text-disabled);
}
.btn-disabled,
.btn-disabled:hover {
  color: var(--text-disabled);
  border-color: #e5e7e9;
  background-color: #e5e7e9;
  cursor: inherit;
}

.btn-disabled2,
.btn-disabled2:hover {
  color: var(--text-disabled);
  border-color: #e5e7e9;
  cursor: inherit;
}
.btn-dashed {
  border-style: dashed;
  border-color: var(--mono-gray4);
  box-shadow: none;
  background-color: #ffffff;
}

.btn-gray,
.btn-gray:hover {
  border: 1px solid var(--text-caption);
  color: var(--text-caption);
}
.btn img {
  margin-top: -3px;
  margin-right: 3px;
  border-radius: unset;
}

/* dropdown */
.dropdown-menu {
  border-radius: 8px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  padding: 0px;
  border: none;
}
.dropdown-item {
  padding: 8px 18px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.65);
  border-bottom: 1px solid var(--mono-gray6);
}
.dropdown-item:last-child {
  border: none;
}
.btn-dropdown > .btn-primary,
.btn-dropdown > .btn-primary:hover,
.btn-dropdown .show > .btn-primary.dropdown-toggle,
.btn-dropdown > .btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  background: none;
  border: none;
  padding: 0px !important;
  box-shadow: none;
  outline: none;
}
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
  border: none;
  outline: none;
}
.btn-dropdown > .btn-primary::after {
  margin: 0;
  margin-right: 2px;
}
.dropdown-item.active,
.dropdown-item:active,
.dropdown-item:hover {
  background-color: var(--mono-gray6);
}
.dropdown-divider {
  margin: 0px 10px;
  border-top: 1px solid #ffeed9;
}
.btn-dropdown {
  margin-right: 23px;
  margin-left: -6px;
}
.btn-dropdown .btn {
  margin-right: -20px;
}
.btn-dropdown .btn img {
  margin-left: 16px;
}
@media (max-width: 410px) {
  .btn {
    /* font-size: 12px; */
    /* line-height: 18px; */
    /* padding:13.5px 16px;  */
  }
  .btn-sm {
    /* padding:8px 16px; */
  }
  .spinner-border {
    width: 1rem !important;
    height: 1rem !important;
  }
}

@media (max-width: 321px) {
  .btn {
    /* font-size: 11px; */
    /* line-height: 17px;
        padding: 13.5px 16px;
        padding: 10px 12px; */
    border-radius: 8px;
  }
}

/* -------------------------------- */
/* Flex */
.content-flex {
  display: flex;
  align-items: baseline;
}
.content-flex > div {
  margin-right: 2px;
}
.content-flex > div:last-child {
  margin-right: 0px;
}

/* Grid */
.content-grid {
  display: grid;
  align-items: center;
}
.grid-2 {
  grid-template-columns: auto auto;
}
.grid-4-6 {
  grid-template-columns: 4fr 6fr;
}
.grid-3-7 {
  grid-template-columns: 3fr 7fr;
}
.grid-2-8 {
  grid-template-columns: 2fr 8fr;
}
.grid-1-9 {
  grid-template-columns: 1fr 9fr;
  grid-column-gap: 16px;
}
.grid-1-8-1 {
  grid-template-columns: 1fr 8fr 1fr;
}

.grid-8-2 {
  grid-template-columns: 8fr 2fr;
}
.content-grid > div:last-child {
  text-align: right;
}

/* modal */

.modal-content {
  max-width: 480px;
  margin: auto;
  border-radius: 8px;
  border: none;
  padding: 12px;
}
.modal-content .modal-title {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
}
.modal-content .close {
  font-size: 40px;
  font-weight: normal;
  color: #333333;
  padding: 0px;
  padding-top: 10px;
  padding-right: 10px;
}
.modal-content.modal-footer {
  min-height: 40px;
}
.modal-content .modal-header {
  padding: 8px 12px;
  border: none;
  margin-bottom: 10px;
}
.modal-content .modal-body {
  padding: 0px;
}
.modal-content .modal-body > div {
  border-bottom: 1px solid #d4d2d3;
  padding: 8px 10px;
}
.modal-content .close > span:first-child {
  color: #333333;
  font-weight: 300;
}

/* -------------------------------- */

p {
  margin-bottom: 20px;
}
.clear-both {
  clear: both;
}

/* container firebase */
.container-firebase {
  margin-bottom: 10px;
}

/* Toastify */

.Toastify__toast-container--top-center {
  width: 427px !important;
  top: 6em;
}

@media (max-width: 427px) {
  .Toastify__toast-container--top-center {
    width: 90% !important;
    margin-left: 5%;
  }
}
.Toastify__toast {
  min-height: 10px !important;
  padding-left: 15px !important;
  border-radius: 4px;
}
.Toastify__toast--error {
  color: var(--red);
  background-color: #faeded;
  border: 1px solid #fbcacb;
}
.Toastify__toast--info {
  color: var(--blue);
  background-color: #ebf1f8 !important;
  border: 1px solid #b9dffc;
}
.Toastify__toast svg > path {
  color: var(--mono-gray1);
  opacity: 0.2;
}

.Toastify__toast--success {
  color: var(--green);
  background-color: #e7f9f1;
  border: 1px solid #c6f1cf;
}

.Toastify__toast--warning {
  color: #ffb443;
  background-color: #fff8e4;
  border: 1px solid #fff0c2;
}

/* Progress Bar */
.progress {
  width: 100%;
  height: 6px;
  background: #ebebeb;
  border-radius: 3px;
  overflow: hidden;
}
.progress-bar {
  display: block;
  height: 100%;
  background-color: #f9a215;
  background-size: 300% 100%;
  animation: progress-animation 2s linear infinite;
  width: 17%;
  border-radius: 3px;
}
.progress-big {
  height: 8px;
  border-radius: 4px;
}
.progress-big .progress-bar {
  border-radius: 4px;
}

/* spinner */
.spinner-border {
  width: 1.2rem;
  height: 1.2rem;
}
.spinner-border-sm {
  width: 1rem;
  height: 1rem;
}

/* text */
.text-middle {
  vertical-align: middle;
  margin: auto;
  text-align: center;
  margin-top: 40vh;
}
.text-left {
  text-align: left !important;
}

/* Alert */
.alert {
  padding: 8px;
  text-align: center;
  border-radius: 8px;
}
.alert-danger {
  color: var(--text-title) !important;
  background-color: var(--primary3);
  border: none;
}
.alert-info {
  color: #018dc0 !important;
  background-color: #cbebfb;
  border: 1px solid #3abbe9;
}
.alert-info label {
  color: #018dc0 !important;
}
.alert-info a {
  color: var(--secondary2);
  font-weight: 600;
}

.alert-info .styled-checkbox:checked + label:before {
  background-color: #018dc0 !important;
  border: 1px solid #387297;
}
.alert-sm {
  padding: 3px 10px !important;
  font-size: 10px;
  line-height: 15px;
}

.info-green {
  background: rgba(17, 192, 91, 0.1);
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 10px;
}

.info-red {
  background: #f63f441a;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 10px;
}
.info-red2 {
  background: #f9c8c7;
  border-radius: 8px;
  padding: 12px 16px;
  margin-bottom: 10px;
  border: 1px solid #e95e5e;
  color: #e95e5e;
  justify-content: start;
}

.info-form {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  padding: 12px 16px;
  color: #6b7280;
  justify-content: start;
  background: #fef3c7;
  border: 1px solid #fddaa1;
  border-radius: 4px;
}
.info-form p {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #6b7280;
}
.info-form .notice {
  top: -15px;
  position: relative;
}
.info-form a {
  color: var(--secondary2);
}
.info-form2 {
  background: #cbebfb;
  border-radius: 8px;
  padding: 10px 16px;
  color: #018dc0;
  border: 1px solid #3abbe9;
  justify-content: start;
}

.info-form2 a {
  color: var(--secondary2);
  font-weight: 600;
}

.info-form3 {
  border-radius: 5px;
  padding: 10px 16px;
  color: var(--text-body);
  background: #ebf1f8;
  border: 1px solid #b9dffc;
}

.info-conditions {
  background: #f2f2f2;
  border-radius: 8px;
  padding: 10px 16px;
  color: #6f6f6f;
  border: 1px solid #f2f2f2;
  justify-content: start;
}

.hide {
  display: none;
}
.show {
  display: block;
}

.react-datepicker-wrapper {
  display: block !important;
}

.campaign-content {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 10px;
}

/* Loading text */
.gradient {
  animation-duration: 1.8s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background-color: #f6f7f8;
  background-color: linear-gradient(
    to right,
    #fafafa 8%,
    #f4f4f4 38%,
    #fafafa 54%
  );
  background-size: 1000px 640px;
  border-radius: 4px;
  margin-top: 10px;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.cover-loading {
  height: 200px;
  width: 100%;
}

.text-loading {
  height: 10px;
}
.text-loading-90 {
  width: 90%;
}
.text-loading-80 {
  width: 80%;
}
.text-loading-70 {
  width: 70%;
}
.text-loading-60 {
  width: 60%;
}
.text-loading-50 {
  width: 50%;
}
.text-loading-40 {
  width: 40%;
}
.text-loading-30 {
  width: 30%;
}
.text-loading-20 {
  width: 20%;
}
.height-5 {
  height: 5px;
}
.height-20 {
  height: 20px;
}
.height-30 {
  height: 30px;
}
.height-50 {
  height: 50px;
}
.height-70 {
  height: 70px;
}
.height-100 {
  height: 100px;
}

.react-confirm-alert-body {
  color: #333 !important;
}
.react-confirm-alert-body h1 {
  font-size: 16px !important;
  color: #803d37 !important;
}
.react-confirm-alert-button-group > button {
  /* background-color: var(--primary1) !important; */
  background-color: #fbc3dc !important;
  color: #803d37;
  border: 0.1px solid #e8abc6;
  padding: 4px 15px;
  border-radius: 4px;
}

.react-confirm-alert-button-group > button:last-child {
  background-color: white !important;
}

.checkbox {
  margin-left: 16px;
  position: relative;
  color: #777777;
  border-radius: 4px;
}
.checkbox input {
  width: 20px;
  height: 20px;
}
.checkbox span {
  position: absolute;
  left: 30px;
  top: 2px;
}

.grid-3 {
  grid-template-columns: 0.4fr auto auto;
}
.grid-top {
  align-items: flex-start;
}

/* accordion */
.accordion {
  border: none;
  margin-top: 10px;
}
.accordion__item {
  margin-bottom: 8px;
  border-radius: 8px;
  border: 1px solid var(--mono-gray5);
}
.accordion__button {
  background-color: #ffffff;
  border-radius: 5px;
  font-weight: 600;
  font-size: 12px;
  padding: 15px;
  border-color: var(--mono-gray4);
}
.accordion__button:hover {
  background-color: var(--mono-gray6);
}
.accordion__button:before {
  color: var(--text-disabled);
  position: absolute;
  right: 26px;
  transform: rotate(45deg);
}
.accordion__button:focus {
  outline: none;
}
.accordion__panel {
  padding-top: 10px;
}
.accordion__panel ul {
  padding: 0px 20px;
}

/* card */
.cp-card {
  padding: 16px;
  border: none;
  margin-bottom: 10px;
  background: #ffffff;
  box-shadow: 0px 6px 16px rgba(151, 151, 151, 0.16);
  border-radius: 8px;
}
.card-state {
  border: 1px solid var(--mono-gray4);
  box-sizing: border-box;
  border-radius: 16px;
  color: var(--text-caption);
  padding: 2px 16px;
}
.state-green {
  border-color: var(--green);
  color: var(--green);
}
.state-yellow {
  border-color: var(--yellow);
  color: var(--yellow);
}
.state-primary1 {
  border-color: var(--primary1);
  color: var(--primary1);
}

.state-red {
  border-color: var(--red);
  color: var(--red);
}
.cp-card hr {
  margin-top: 10px;
  margin-bottom: 10px;
  border-color: var(--mono-gray5);
}
.cp-card .img-campaign {
  width: 63px;
}
.cp-card .progress {
  height: 3px;
  border-radius: 3px;
  margin: 10px 0px;
}

.cp-card .card-actions {
  display: flex;
  padding-left: 0;
  justify-content: space-between;
  align-items: center;
}
.cp-card .card-actions > div,
.cp-card .card-actions > a {
  margin: 0px 5px;
}
.flex-start {
  align-items: flex-start;
}

/* Firebase UI */
.firebaseui-idp-icon-wrapper {
  display: block !important;
}
.firebaseui-container {
  max-width: 100% !important;
}
.firebaseui-card-content {
  padding: 0px !important;
}
.firebaseui-idp-list {
  display: flex;
  justify-content: center;
}
.firebaseui-list-item {
  border: 1px solid var(--mono-gray4);
  margin: 0px !important;
  padding: 0px 6px !important;
  border-radius: 10px;
  /* width: 80%; */
  width: 56%;
}
.firebaseui-idp-button {
  box-shadow: none !important;
  text-align: center !important;
  display: flex !important;
  justify-content: center !important;
  align-items: baseline !important;
}
.firebaseui-idp-button .firebaseui-idp-icon-wrapper {
  position: relative;
}
.firebaseui-idp-text,
.firebaseui-idp-google > .firebaseui-idp-text {
  font-weight: 600;
  font-size: 12px !important;
  line-height: 18px;
  color: #6f6f6f !important;
  font-family: "Rubik";
  margin-top: 3px !important;
  padding-left: 12px !important;
}
.firebaseui-idp-text,
.firebaseui-idp-text.firebaseui-idp-text-long {
  display: none !important;
}

.firebaseui-idp-text,
.firebaseui-idp-text.firebaseui-idp-text-short {
  display: table-cell !important;
}
.firebaseui-idp-icon {
  width: 20px !important;
  height: 20px !important;
}
.firebaseui-idp-facebook {
  background-color: #ffffff !important;
}
.firebaseui-idp-facebook .firebaseui-idp-icon-wrapper {
  background-color: #3b5998;
}
.firebaseui-idp-facebook .firebaseui-idp-icon {
  background-color: #3b5998;
}

.firebaseui-idp-list > li:first-child {
  margin-right: 8px !important;
}
.firebaseui-idp-list > li:last-child {
  margin-left: 8px !important;
}
.opacity {
  opacity: 0.7;
}
.loading-screen {
  position: absolute;
  margin: auto;
  left: 50%;
  top: 50%;
}

/* React Datepicker UI*/
.react-datepicker-wrapper {
  cursor: pointer;
}
.react-datepicker-popper {
  top: -9px;
  right: 0px;
  left: inherit !important;
}
.react-datepicker {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-size: 12px;
  border: 1px solid var(--mono-gray4);
  filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.15));
  border-radius: 5px;
}
.react-datepicker__current-month {
  font-size: 14px;
  color: var(--text-title);
  font-weight: normal;
}
.react-datepicker__day-name {
  margin-top: 15px;
  font-size: 14px;
}
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: var(--text-title);
}
.react-datepicker__day--disabled {
  color: var(--text-disabled);
}
.react-datepicker__header {
  background-color: #f0f0f0;
  border-bottom: 1px solid var(--mono-gray4);
}
.react-datepicker__navigation--next {
  border-left-color: #333333;
}
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: transparent;
}
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: var(--primary1);
  color: #ffffff;
  border: none;
  outline: none;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  background-color: var(--primary3);
  border: none;
  outline: none;
}
.react-datepicker__day--disabled:hover,
.react-datepicker__month-text--disabled:hover,
.react-datepicker__quarter-text--disabled:hover,
.react-datepicker__year-text--disabled:hover {
  background-color: transparent;
}
.react-datepicker__day--today {
  border: 1px solid var(--primary1);
  color: var(--text-title);
  font-weight: normal;
  border-radius: 0.3rem;
}

/* slick */
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

/* Rectangle */
.box-gradient-botup-brown {
  background: linear-gradient(
    360deg,
    #ffeed9 18.53%,
    rgba(255, 255, 255, 0) 100%
  );
}
.box-gradient-botup-brown .content-space::after {
  background: #ffefda;
  height: 20px;
}

.box-gradient-botup-gray {
  background: linear-gradient(
    360deg,
    #e2e2e2 18.53%,
    rgba(255, 255, 255, 0) 100%
  );
}
.box-gradient-botup-gray .content-space::after {
  background: #e5e5e5;
  height: 20px;
}

/* limit string */
.str-limit {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-break: break-word;
  overflow: hidden;
}
.str-limit-1 {
  -webkit-line-clamp: 1;
}
.str-limit-2 {
  -webkit-line-clamp: 2;
}
.str-limit-3 {
  -webkit-line-clamp: 3;
}

.list-option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--mono-gray5);
  cursor: pointer;
  gap: 8px;
  color: var(--text-title);
}
.list-option:hover {
  color: var(--text-title);
}
.list-option > div:first-child,
.list-option > div:last-child {
  padding-top: 9px;
  padding-bottom: 9px;
  margin-top: 2px;
  margin-bottom: 2px;
}

.my-donate .state {
  display: flex;
  list-style: none;
  padding-left: 0;
  justify-content: space-between;
  overflow-x: auto;
}
.my-donate .state li {
  border: 1px solid var(--mono-gray5);
  border-radius: 8px;
  text-align: center;
  margin-left: 5px;
  margin-right: 5px;
  line-height: 18px;
  padding: 8px 12px;
  width: 100%;
  color: var(--text-caption);
  cursor: pointer;
  font-weight: 600;
  align-items: center;
  white-space: nowrap;
  display: flex;
  justify-content: space-between;
}
.my-donate .state li:first-child {
  margin-left: 0px;
}
.my-donate .state li:last-child {
  margin-right: 0px;
}
.my-donate .state .active {
  background-color: var(--primary1);
  border: 1px solid var(--primary1);
  color: #ffffff;
}

.modal-filter {
  display: flex;
  list-style: none;
  padding-left: 0;
  justify-content: space-between;
  overflow-x: auto;
}
.modal-filter li {
  border: 1px solid var(--mono-gray5);
  border-radius: 8px;
  text-align: center;
  margin-left: 5px;
  margin-right: 5px;
  line-height: 18px;
  padding: 8px 12px;
  width: 100%;
  color: var(--text-caption);
  cursor: pointer;
  font-weight: 600;
  align-items: center;
  white-space: nowrap;
  display: flex;
  justify-content: space-between;
}
.modal-filter li:first-child {
  margin-left: 0px;
}
.modal-filter li:last-child {
  margin-right: 0px;
}
.modal-filter .active {
  background-color: var(--primary3);
  border: 1px solid var(--primary1);
  color: var(--primary1);
}
.border-dashed {
  border-style: dashed;
}

.modal-confirm .modal-header {
  display: block;
  text-align: center;
}
.modal-confirm .modal-title {
  font-size: 18px;
  color: var(--text-title);
  font-weight: 700;
}
.modal-confirm .modal-body {
  text-align: center;
  margin-bottom: 16px;
}
.modal-confirm .modal-footer {
  justify-content: space-around;
  font-size: 14px;
  padding: 0px;
  padding-top: 12px;
}

.modal-bottom .modal-content {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.modal-bottom .modal-title {
  display: flex;
  justify-content: space-between;
  gap: 16px;
}
.modal-bottom .modal-body {
  max-height: 500px;
  overflow: auto;
}

.favourite-card {
  display: block;
  width: 180px;
  margin: 10px 15px 0px 0px;
  border: 1px solid #f2f2f2;
  margin-bottom: 8px;
  border-radius: 8px;
  margin-left: 1.8px;
}
.favourite-card:hover {
  text-decoration: none;
  color: #374151;
}
.favourite-card-image {
  width: 100%;
  height: 100px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.favourite-card-name {
  color: #374151;
  margin-top: 8px;
  margin-bottom: 4px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-word;
  overflow: hidden;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
}
.favorite-card-campaigner-name {
  margin-bottom: 5px;
  font-size: 10px;
  line-height: 16px;
  color: var(--primary1);
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-word;
  overflow: hidden;
  max-width: 105px;
}
.favorite-card-campaigner-name img {
  display: unset !important;
  margin-left: 3px;
}
.favourite-card-info {
  height: 97px;
  width: 90%;
  position: relative;
  padding: 0px 5px;
}
.favourite-card-progress-percentage {
  height: 3px !important;
}
.favourite-card-progress-info {
  width: 100%;
  position: absolute;
  bottom: 0px;
}
.favourite-card-progress-nominal {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 4px;
}
.favourite-card-progress-info-left {
  text-align: left;
}
.favourite-card-progress-info-right {
  flex: 1;
  text-align: right;
}
.favourite-card-progress-nominal p {
  margin: 3px 0px 0px 0px;
  color: #969696;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}

.preverification .campaigner-type-v1 {
  display: block;
  flex: unset;
}

.preverification .campaigner-type-v1 .active {
  background: #fef2de;
  border: 1px solid #f9a215;
}

.preverification .campaigner-type-v1 > div {
  position: relative;
  border: 1px solid var(--mono-gray5);
  border-radius: 8px;
  text-align: left;
  padding: 32px 16px;
  cursor: pointer;
  min-height: 80px;
  margin: 8px;
}

.icon-verified-fav {
  top: 4px;
  position: relative;
}

.txt-icon-menu {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #6b7280;
}
.txt-hide {
  display: none;
}
.txt-checkbox {
  max-width: 85%;
}

.section.description .txt-body-m p {
  line-height: 20px;
  margin-bottom: 16px;
}

.blog-card {
  display: block;
  width: 240px;
  margin: 10px 15px 0px 0px;
  border: 1px solid #f2f2f2;
  margin-bottom: 8px;
  border-radius: 8px;
  margin-left: 1.8px;
}
.blog-card:hover {
  text-decoration: none;
  color: #374151;
}
.blog-card-image {
  width: 100%;
  height: 136px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.blog-card-name {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #374151;
  margin-top: 8px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-word;
  overflow: hidden;
}
.blog-card .txt-date {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #9ca3af;
}
.favorite-card-campaigner-name img {
  display: unset !important;
  margin-left: 3px;
}
.favourite-campaign-home .section-title {
  display: flex;
}
.favourite-campaign-home .section-title a {
  display: flex;
  float: right;
  right: 35px;
  position: absolute;
  margin-top: 5px;
}
.favourite-campaign-home .section-title img {
  margin-left: 6px;
}
.favourite-campaign-home .section-title .txt-to-blog {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #f9a215;
}
.blog-card-info {
  height: 72px;
  width: 90%;
  position: relative;
  padding: 0px 5px;
}
.blog-card-progress-percentage {
  height: 3px !important;
}
.blog-card-progress-info {
  width: 100%;
  position: absolute;
  bottom: 0px;
}
.blog-card-progress-nominal {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 4px;
}
.blog-card-progress-info-left {
  text-align: left;
}
.blog-card-progress-info-right {
  flex: 1;
  text-align: right;
}
.blog-card-progress-nominal p {
  margin: 3px 0px 0px 0px;
  color: #969696;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}

@media (max-width: 450px) {
  .campaign-card-campaigner-name {
    max-width: 100px;
  }
}

@media (max-width: 375px) {
  .favourite-card-info {
    height: 85px;
  }
  .favourite-card-name {
    font-size: 12px;
    line-height: 18px;
  }
  .favourite-card-progress-nominal p {
    font-size: 10px;
    line-height: 16px;
  }
  .favorite-card-campaigner-name {
    font-size: 8px;
    line-height: 14px;
  }
  .campaign-card-campaigner-name {
    max-width: 90px;
  }
}
@media (max-width: 330px) {
  .campaign-card-campaigner-name {
    max-width: 65px;
  }
}
.custom-toggle-react.react-toggle--checked .react-toggle-track {
  background-color: #f9a215;
  border: #f9a215;
}
.custom-toggle-react.react-toggle--checked:hover .react-toggle-track {
  background-color: #f9a215;
}

.custom-toggle-react .react-toggle-track {
  background-color: #e5e7eb;
}

.custom-toggle-react:hover .react-toggle-track {
  background-color: #e5e7eb;
}

.react-toggle-thumb {
  border: none;
}
.modal-content-pwa .modal-content {
  padding: unset;
  max-width: 380px;
}
.modal-content-pwa .modal-content .img-thumb-pwa {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  height: 231px;
  width: 100%;
  object-fit: cover;
  object-position: center;
}
.modal-install-pwa {
  align-items: center;
  background: #FFFFFF;
  border-radius: 8px;
  padding: 16px 24px 16px 24px;
}
.modal-install-pwa  h6 {
  color: var(--neutral-n-700, #374151);
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 155.556% */
  padding-bottom: 6px;
}
.modal-install-pwa p {
  color: var(--neutral-n-500, #6B7280);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.modal-install-pwa button {
  margin: 0 auto;
  display: flex;
}
.modal-install-pwa button svg{
  margin-right: 8px;
}
.modal-install-pwa .btn-back {
  margin-top: 8px;
}
.modal-install-pwa #install-pwa {
  display: flex;
  border-radius: 6px;
  padding: 12px;
  margin: 16px auto;
}
.modal-install-pwa #install-pwa span {
  display: flex;
}
.modal-install-pwa #install-pwa span svg {
  margin-right: 8px;
} 
.bg-footer-modal {
  border-radius: 8px;
}
.bg-footer-modal button {
  width: 100%;
  border: unset;
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  background: var(--neutral-n-200, #E5E7EB);
  cursor: pointer;
  display: flex;
  color: var(--neutral-n-500, #6B7280);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  border-radius: 8px;
}
.txt-desc-payment {
  border-radius: 8px;
  background: var(--neutral-n-100, #F3F4F6);
  color: var(--neutral-n-500, #6B7280);
  font-family: 'Rubik';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  margin-top: 20px;
  padding: 2px 12px;
  display: inline-block;
}
.data-group-payment {
  display: -webkit-box;
}
