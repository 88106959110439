@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import "./colors.css"; 
/* Fonts */

/* medium */
.fw-5{
  font-weight: 500 !important;
}
/* semi bold */
.fw-6{
  font-weight: 600 !important;
}
.fw-7{
  font-weight: 700 !important;
}
/* bold */
.fw-9{
  font-weight: 900 !important;
}
.fw-bold{
  font-weight: bold !important;
}

/* heading */
h1, h2, h3{
  font-weight: 900;
}
h1{
  font-size: 64px;
  line-height: 78px;
}
h2{
  font-size: 50px;
  line-height: 65px;
}
h3{
  font-size: 40px;
  line-height: 52px;
}

h4, h5, b, strong{
  font-weight: 600;
}
h4{
  font-size: 32px;
  line-height: 42px;
}
h5{
  font-size: 28px;
  line-height: 36px;
}

/* title */
.txt-title{
  font-size: 24px;
  line-height: 32px;
  color:var(--text-title);
}
.txt-title-m{
  font-size: 16px;
  line-height: 30px;
  color:var(--text-title);
}
.txt-title-s{
  font-size: 18px;
  line-height: 26px;
  color:var(--text-title);
}

/* subtitle */
.txt-subtitle{
  font-size: 18px;
  line-height: 26px;
  color:var(--text-title);
}
.txt-subtitle-m{
  font-size: 16px;
  line-height: 24px;
  color:var(--text-title);
}
.txt-subtitle-s{
  font-size: 14px;
  line-height: 22px;
  color:var(--text-title);
}

/* body */
.txt-body, .txt-body p{
  font-size: 16px;
  line-height: 24px;
  color:var(--text-body);
}
.txt-body-m, .txt-body-m p{  
  font-size: 14px;
  line-height: 22px;
  color:var(--text-body);
}
.txt-body-s, .txt-body-s p{
  font-size: 12px;
  line-height: 18px;
  color:var(--text-body);
}

.txt-caption{
  font-size: 12px;
  line-height: 18px;
  color:var(--text-caption);
}

.txt-caption-m{
  font-size: 10px;
  line-height: 16px;
  color:var(--text-caption);
}

.txt-caption-s{
  font-size: 8px;
  line-height: 14px;
  color:var(--text-caption);
}

/* Responsive */
@media (min-width: 411px){
  .txt-title{
    /* font-size: 20px; */
    /* line-height: 30px; */
  }
  
  /* subtitle */
  .txt-subtitle{
    /* font-size: 16px; */
    /* line-height: 24px; */
  }
  
  /* body */
  .txt-body, .txt-body p{
    /* font-size: 14px; */
    /* line-height: 22px; */
  }
}


@media (max-width: 410px) {
  .txt-title, .txt-title-m{
    /* font-size: 18px !important; */
    /* line-height: 26px; */
  }
  
  /* subtitle */
  .txt-subtitle, .txt-subtitle-m{
    /* font-size: 14px !important; */
    /* line-height: 22px; */
  }
  
  /* body */
  .txt-body{
    /* font-size: 12px !important; */
    /* line-height: 18px; */
  }
  .txt-body-m, .txt-body-m p{
    /* font-size: 12px; */
    /* line-height: 18px; */
  }
  
  .txt-caption{
    /* font-size: 10px !important; */
    /* line-height: 16px; */
  }
}