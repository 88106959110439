@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);

header{
    width: 100%;
    top: 0px;
    left: 0px;
    z-index: 11;
    position: fixed;
    background: #ffffff;
    border-bottom: none;
    /* box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25); */
    box-shadow: 0px 6px 16px rgba(151, 151, 151, 0.16);
}

header .nav{
    margin: auto;
    max-width: 480px;
}

header .nav > div{
    width: 100%;
}

header .nav {
    grid-template-columns: 0.1fr 9fr;
    display: grid;
    grid-gap: 0.5rem;
    align-items: center;
    justify-items: left;
    padding:0px 20px;
    height: 56px;
}
header .nav-col-3{
    /* grid-template-columns: 0.1fr 7fr 2fr; */
    grid-template-columns: 0.1fr auto 0.1fr;
}
header .nav-col-1{
    grid-template-columns: 12fr;
}
header .nav .nav-link{
    padding:0px;
    padding-top:5px;
}

header .head-title {
    padding-left:10px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #374151;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-word;
    overflow: hidden;
}

header .nav.col-2 div:last-child svg {
    grid-column-start: 2;
}

header .nav > .last-nav {
    text-align: right;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1.2rem;
    justify-self: right;
}
header .nav-splash{
    display:block;
    padding:12px 16px;
}

.header-menu-link .btn-dropdown, .nav .btn-dropdown{
    margin-right:0px;
}
.wizard{
    /* position: fixed; */
    height: auto;
    z-index: 1;
    position: absolute;
    background: var(--primary1);
    top: 54px;
    max-width: 480px;
    left:0;
    right:0;
    margin:auto;

    /* height: auto;
    background: var(--primary1);
    max-width: 480px;
    margin: auto;
    margin-top: 54px; */
}

.wizard .steps{
    display:flex;
    justify-content: space-between;
    margin:0px 20px;
    height: 56px;
    align-items: center;
}
.wizard .steps > div{
    min-width: 30%;
    height:auto;
    text-align: center;
    position: relative;
    cursor:pointer;
}
.wizard .steps > div:first-child:after, .wizard .steps > div:nth-child(2):after{
    content: '';
    position:absolute;
    border:1px solid #ffffff;
    background:#ffffff;
    width: 18%;
    right: -18%;
    top: 22%;
    opacity: 0.2;
}
.wizard .steps > .last-step:after{
    opacity:0 !important;
}
.wizard .steps .step-active .step-number, .wizard .steps .step-active .step-label{
    opacity: inherit;
}
.wizard .steps .step-number, .wizard .steps .step-label{
    margin: auto;
    opacity: 0.2;
}
.wizard .steps .step-hide{
    opacity: 0;
}

.wizard .steps .step-label{
    color:#ffffff;
    margin-top:4px;
}
.wizard .steps .step-number span{
    padding:0px 4px;
    border-radius: 50%;
    width:12px;
    height: 12px;
    background:#ffffff;
    font-weight: 600;
    color:var(--primary1);
}
.search-bar{
    height:56px;
}
.search-bar .nav{
    grid-template-columns: 1fr 9fr;
    padding: 5px 20px;
}
.search-bar .nav > div:nth-last-child(1){
    width:100%;
}
.search-bar .nav-link img{
    margin-top: -5px;
}
.search-bar .nav > div:nth-last-child(1) {
    grid-template-columns: 10fr;
}
.search-bar .form-group {
    margin-bottom: 0px;
}
.search-bar .form-label {
    display: none;
}
.search-bar .input-group {
    background: white;
    border-radius: 4px;
    box-shadow: none !important;
    padding-left:5px;
    padding-right:5px;
}
.search-bar .form-control, .search-bar .input-group-prepend, .search-bar .input-group-text {
    background: transparent;
}
.search-bar .form-control {
    color: #FFFFFF;
    height: 40px;
}

/** Modify Placeholder Color */
/* Chrome, Firefox, Opera, Safari 10.1+ */
.search-bar .form-control::-webkit-input-placeholder {
    color: #FFFFFF;
    opacity: 1; /* Firefox */
}
.search-bar .form-control:-ms-input-placeholder {
    color: #FFFFFF;
    opacity: 1; /* Firefox */
}
.search-bar .form-control::-ms-input-placeholder {
    color: #FFFFFF;
    opacity: 1; /* Firefox */
}
.search-bar .form-control::placeholder {
    color: #FFFFFF;
    opacity: 1; /* Firefox */
}
/* Internet Explorer 10-11 */
.search-bar .form-control:-ms-input-placeholder { 
    color: #FFFFFF;
}
/* Microsoft Edge */
.search-bar .form-control::-ms-input-placeholder { 
    color: #FFFFFF;
}

.transparant{
    background:none;
    box-shadow: none;
}
.search-input{
    background: #F2F2F2;
    height: 40px;
    display: grid;
    align-items: center;
    grid-template-columns: 0fr 11fr;
    border-radius: 4px;
    padding: 10px 10px;
}
.search-input div{
    text-align: left;
}
.search-input div:first-child{
    padding-bottom: 1px;
    width: 30px;
    padding-left: 4px;
}
.search-input div:last-child{
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #C4C4C4;
}

.search-button{
    margin-left: auto;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    background-color: white;
    border-radius: 50%;
}

.search-button.with-shadow{
    box-shadow: 0px 6px 16px rgba(151, 151, 151, 0.16);
}

.navbar {
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    right: 0;
    margin: 0 auto;
    padding: 10px 5px 10px;
    background: #fff;
    z-index: 9;
    box-shadow: 0px -4px 16px rgba(0, 0, 0, 0.16);
    max-width: 480px;
}
.navbar .nav-item{
    font-weight: normal;
    font-size: 10px;
    line-height: 14px;
    color:var(--text-disabled);
}
.navbar .nav-item span{
    display: block;
}
.navbar .nav-item a{
    font-size: 10px;
    line-height: 14px;
    color:var(--text-disabled);
    font-weight: 500;
    text-decoration: none;
}
.navbar .nav-item .active{
    color: var(--primary1);
    font-weight: 600;
}
.navbar ul{
    list-style: none;
    padding: 0;
    margin: 0;
}
.navbar .nav-item{
    width: 20%;
    float: left;
    text-align: center;
}

.navbar {
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    right: 0;
    margin: 0 auto;
    padding: 10px 5px 10px;
    background: #fff;
    z-index: 9;
    box-shadow: 0px -4px 16px rgba(0, 0, 0, 0.16);
    max-width: 480px;
}
.navbar .nav-item{
    font-weight: normal;
    font-size: 10px;
    line-height: 14px;
    color:var(--text-disabled);
}
.navbar .nav-item span{
    display: block;
}
.navbar .nav-item a{
    font-size: 10px;
    line-height: 14px;
    color:var(--text-disabled);
    font-weight: 500;
    text-decoration: none;
}
.navbar .nav-item .active{
    color: var(--primary1);
    font-weight: 600;
}
.navbar ul{
    list-style: none;
    padding: 0;
    margin: 0;
}
.navbar .nav-item{
    width: 20%;
    float: left;
    text-align: center;
}
.nav-tbt .btn-primary:hover {
    background-color: var(--primary2);
    border: unset;
    color: #FFFFFF;
}

:root {
  --text-title:#374151;
  --text-body:#6F6F6F;
  --text-caption:#969696;
  --text-disabled: #B6B6B6;

  --red: #F7685B;
  --red-dark: #CB1C23;
  --orange:#FF9500;
  --orange-dark:#CE5B1A;
  --yellow:#FFCC00;
  --yellow-dark:#E3BA24;
  --blue:#007AFF;
  --blue-dark:#1E58BB;
  --orange:#AF52DE;
  --orange-dark:#7F3AA4;
  --green:#1E983A;
  --green-dark:#039C42;

  --mono-gray1:#333333;
  --mono-gray2:#4F4F4F;
  --mono-gray3:#828282;
  --mono-gray4:#BDBDBD;
  --mono-gray5:#E0E0E0;
  --mono-gray6:#F2F2F2;
  --mono-gray7:#FFFFFF;

  --main-gray:#F2F2F2;

  --primary1:#F9A215;
  --primary2: #D68605;
  --primary3:#FFF8F0;

  --secondary1:#8DC5E9;
  --secondary2:#387297;
  --secondary3:#EFFAFF;
}
.ct-primary1{
  color:#F9A215;
  color:var(--primary1);
}
.ct-primary2{
  color:#D68605 !important;
  color:var(--primary2) !important;
}
.ct-title{
  color:#374151;
  color:var(--text-title);
}
.ct-red{
  color:#F7685B;
  color:var(--red);
}
.ct-body{
  color:#6F6F6F;
  color:var(--text-body);
}
.ct-caption{
  color:#969696;
  color:var(--text-caption);
}
.ct-disabled{
  color:#B6B6B6;
  color:var(--text-disabled);
}
.bg-gray{
  background-color:#F2F2F2;
  background-color:var(--main-gray);
}
.bg-light-brown{
  background-color:#FFF8F0;
  background-color:var(--primary3);
}
.bg-primary3{
  background-color:#FFF8F0;
  background-color:var(--primary3);
}
.ct-mono-gray7{
  color:#FFFFFF !important;
  color:var(--mono-gray7) !important;
}
/* Fonts */

/* medium */
.fw-5{
  font-weight: 500 !important;
}
/* semi bold */
.fw-6{
  font-weight: 600 !important;
}
.fw-7{
  font-weight: 700 !important;
}
/* bold */
.fw-9{
  font-weight: 900 !important;
}
.fw-bold{
  font-weight: bold !important;
}

/* heading */
h1, h2, h3{
  font-weight: 900;
}
h1{
  font-size: 64px;
  line-height: 78px;
}
h2{
  font-size: 50px;
  line-height: 65px;
}
h3{
  font-size: 40px;
  line-height: 52px;
}

h4, h5, b, strong{
  font-weight: 600;
}
h4{
  font-size: 32px;
  line-height: 42px;
}
h5{
  font-size: 28px;
  line-height: 36px;
}

/* title */
.txt-title{
  font-size: 24px;
  line-height: 32px;
  color:var(--text-title);
}
.txt-title-m{
  font-size: 16px;
  line-height: 30px;
  color:var(--text-title);
}
.txt-title-s{
  font-size: 18px;
  line-height: 26px;
  color:var(--text-title);
}

/* subtitle */
.txt-subtitle{
  font-size: 18px;
  line-height: 26px;
  color:var(--text-title);
}
.txt-subtitle-m{
  font-size: 16px;
  line-height: 24px;
  color:var(--text-title);
}
.txt-subtitle-s{
  font-size: 14px;
  line-height: 22px;
  color:var(--text-title);
}

/* body */
.txt-body, .txt-body p{
  font-size: 16px;
  line-height: 24px;
  color:var(--text-body);
}
.txt-body-m, .txt-body-m p{  
  font-size: 14px;
  line-height: 22px;
  color:var(--text-body);
}
.txt-body-s, .txt-body-s p{
  font-size: 12px;
  line-height: 18px;
  color:var(--text-body);
}

.txt-caption{
  font-size: 12px;
  line-height: 18px;
  color:var(--text-caption);
}

.txt-caption-m{
  font-size: 10px;
  line-height: 16px;
  color:var(--text-caption);
}

.txt-caption-s{
  font-size: 8px;
  line-height: 14px;
  color:var(--text-caption);
}

/* Responsive */
@media (min-width: 411px){
  .txt-title{
    /* font-size: 20px; */
    /* line-height: 30px; */
  }
  
  /* subtitle */
  .txt-subtitle{
    /* font-size: 16px; */
    /* line-height: 24px; */
  }
  
  /* body */
  .txt-body, .txt-body p{
    /* font-size: 14px; */
    /* line-height: 22px; */
  }
}


@media (max-width: 410px) {
  .txt-title, .txt-title-m{
    /* font-size: 18px !important; */
    /* line-height: 26px; */
  }
  
  /* subtitle */
  .txt-subtitle, .txt-subtitle-m{
    /* font-size: 14px !important; */
    /* line-height: 22px; */
  }
  
  /* body */
  .txt-body{
    /* font-size: 12px !important; */
    /* line-height: 18px; */
  }
  .txt-body-m, .txt-body-m p{
    /* font-size: 12px; */
    /* line-height: 18px; */
  }
  
  .txt-caption{
    /* font-size: 10px !important; */
    /* line-height: 16px; */
  }
}
.prayer-card, .prayer-card:hover{
    color: #374151;
}
.prayer-card:hover {
    cursor: pointer;
    text-decoration: none;
}
.prayer-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 12px;
    border: 1px solid #E5E7EB;
    border-radius: 8px;
    margin-right: 8px;
    max-width: 320px;
    height: auto;
}
.prayer-card-image{
    width:100%;
}
.prayer-card-image .center-cropped {
    background-color: #f1f4f6;
    height: 120px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size:cover;
    overflow: hidden;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
.prayer-card .txt-support {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #6B7280;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    word-break: break-word;
    overflow: hidden;
}
.prayer-card .box-doa {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 8px 0px 0px;
    grid-gap: 8px;
    gap: 8px;
    border-top: 1px solid #E5E7EB;
}
.prayer-card .box-support {
    height: 90px;
    width: 90%;
    position: relative;
}
.prayer-card .box-doa .txt-title {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #6B7280;  
}
.prayer-card .box-doa img {
    display: inline;
    margin-right: 9.6px;
}
.prayer-card .box-doa .txt-count span {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    text-align: right;
    color: #6B7280;
}
.prayer-card-info-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    grid-gap: 12px;
    gap: 12px;
}
.prayer-card-info-box .campaign-card-image {
    width: 40px;
    height: 40px;
    border: 1px solid #E5E7EB;
    border-radius: 64px;
}
.prayer-card-info-box .campaign-card-info {
    margin-left: 12px;
}
.prayer-card-info-box .campaign-card-info .txt-campaigner {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #F9A215;
}
.prayer-card-info-box .campaign-card-info .desc-campaign {
    display: flex;
    align-items: flex-start;
    padding: 0px;
}
.prayer-card-info-box .campaign-card-info .desc-campaign .txt-title {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-word;
    overflow: hidden;
    max-width: 145px;
}
.prayer-card-info-box .campaign-card-info .desc-campaign p {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #9CA3AF;
}
.prayer-card-info-box .campaign-card-info .desc-campaign p ul {
    margin: unset;
    padding-left: 13px;
 }
.prayer-card-campaign-name {
    margin: 0px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-word;
    overflow: hidden;
    height:44px;
    font-size: 12px;
    line-height: 22px;
    font-weight: 500;
}
.prayer-card-campaigner-name {
    margin-top: 2px;
    margin-bottom: 4px;
    color: var(--primary1) !important;
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-word;
    overflow: hidden;
    max-width: 140px;
}
.prayer-card-campaigner-name img{
    margin-left: 2px;
    margin-bottom: 6px;
}
.prayer-card-campaign-progress {
    height: 3px !important;
}
.prayer-card-progress-info {
    margin-top: 4px;
    width: 100%;
    display: flex;
    flex-direction: row;
}
.prayer-card-progress-info-left {
    flex: 1 1;
    text-align: left;
}
.prayer-card-progress-info-right {
    /* flex: 1; */
    flex: none;
    text-align: right;
}
.prayer-card-campaign-progress .progress-bar{
    background-color: #F9A215;
}

.prayer-card.txt-caption{
    font-size: 12px;
    line-height: 18px;
    color:#333333;
}

.prayer-card-progress-info > div > div:first-child{
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: #969696;
    margin-bottom: 2px;
}

.prayer-card-progress-info > div > div:last-child{
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #333333;
}

.icon-lembaga {
    margin-top: -9px;
    position: absolute;
}


@media (max-width: 414px){
    .prayer-card-info-box{
        padding:4px 8px;
    }
    /* .prayer-card{
        height:110px;
    } */
    .prayer-card-image .center-cropped {
        /* width: 195px; */
        height: 110px;
    }

    .prayer-card-campaign-name{
        /* font-size: 12px; */
        /* line-height: 22px; */
    }
}



@media (max-width: 375px){
    .prayer-card{
        /* height:102px; */
    }
    .prayer-card-image .center-cropped {
        /* width: 160px; */
        height: 102px;
    }
    .prayer-card-campaign-name {
        height:36px;
        /* font-size: 12px; */
        /* line-height: 18px; */
    }
    
    .prayer-card.txt-caption{
        /* font-size: 10px; */
        /* line-height: 16px; */
    }
    .prayer-card.txt-caption-m{
        /* font-size: 8px; */
        /* line-height: 14px; */
    }
    .prayer-card-campaigner-name{
        /* font-size: 8px; */
        /* line-height: 14px; */
    }
    .prayer-card-progress-info > div > div:first-child{
        /* font-size:8px; */
        /* line-height: 14px; */
    }
    .prayer-card-progress-info > div > div:last-child{
        /* font-size: 10px; */
        /* line-height: 16px; */
    }
}

@media (max-width: 320px){
    .prayer-card-image .center-cropped {
        /* width: 150px; */
        height: 102px;
    }
}
.campaign-card, .campaign-card:hover{
    color: #374151;
}
.campaign-card:hover {
    cursor: pointer;
    text-decoration: none;
}
.campaign-card {
    display: block;
    border-radius: 8px;
    border: 1px solid var(--mono-gray6);
    margin-bottom: 8px;
}
.campaign-card-image{
    width:100%;
}
.campaign-card-image .center-cropped {
    /* width: 212px; */
    background-color: #f1f4f6;
    height: 120px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size:cover;
    overflow: hidden;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
.campaign-card-info-box {
    padding: 8px;
    width: 100%;
    height: 120px;
}
.campaign-card-campaign-name {
    margin: 0px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-word;
    overflow: hidden;
    height:44px;
    font-size: 12px;
    line-height: 22px;
    font-weight: 500;
}
.campaign-card-campaigner-name {
    margin-top: 2px;
    margin-bottom: 4px;
    color: var(--primary1) !important;
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-word;
    overflow: hidden;
    max-width: 140px;
}
.campaign-card-campaigner-name img{
    margin-left: 2px;
    margin-bottom: 6px;
}
.campaign-card-campaign-progress {
    height: 3px !important;
}
.campaign-card-progress-info {
    margin-top: 4px;
    width: 100%;
    display: flex;
    flex-direction: row;
}
.campaign-card-progress-info-left {
    flex: 1 1;
    text-align: left;
}
.campaign-card-progress-info-right {
    /* flex: 1; */
    flex: none;
    text-align: right;
}
.campaign-card-campaign-progress .progress-bar{
    background-color: #F9A215;
}

.campaign-card.txt-caption{
    font-size: 12px;
    line-height: 18px;
    color:#333333;
}

.campaign-card-progress-info > div > div:first-child{
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: #969696;
    margin-bottom: 2px;
}

.campaign-card-progress-info > div > div:last-child{
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #333333;
}

.icon-lembaga {
    margin-top: -9px;
    position: absolute;
}


@media (max-width: 414px){
    .campaign-card-info-box{
        padding:4px 8px;
    }
    /* .campaign-card{
        height:110px;
    } */
    .campaign-card-image .center-cropped {
        /* width: 195px; */
        height: 110px;
    }

    .campaign-card-campaign-name{
        /* font-size: 12px; */
        /* line-height: 22px; */
    }
}



@media (max-width: 375px){
    .campaign-card{
        /* height:102px; */
    }
    .campaign-card-image .center-cropped {
        /* width: 160px; */
        height: 102px;
    }
    .campaign-card-campaign-name {
        height:36px;
        /* font-size: 12px; */
        /* line-height: 18px; */
    }
    
    .campaign-card.txt-caption{
        /* font-size: 10px; */
        /* line-height: 16px; */
    }
    .campaign-card.txt-caption-m{
        /* font-size: 8px; */
        /* line-height: 14px; */
    }
    .campaign-card-campaigner-name{
        /* font-size: 8px; */
        /* line-height: 14px; */
    }
    .campaign-card-progress-info > div > div:first-child{
        /* font-size:8px; */
        /* line-height: 14px; */
    }
    .campaign-card-progress-info > div > div:last-child{
        /* font-size: 10px; */
        /* line-height: 16px; */
    }
}

@media (max-width: 320px){
    .campaign-card-image .center-cropped {
        /* width: 150px; */
        height: 102px;
    }
}
.campaign-card, .campaign-card:hover{
    color: #374151;
}
.campaign-card:hover {
    cursor: pointer;
    text-decoration: none;
}
.campaign-card {
    display: block;
    border-radius: 8px;
    border: 1px solid var(--mono-gray6);
    margin-bottom: 8px;
}
.campaign-card-image{
    width:100%;
}
.campaign-card-image .center-cropped {
    /* width: 212px; */
    background-color: #f1f4f6;
    height: 120px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size:cover;
    overflow: hidden;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
.campaign-card-info-box {
    padding: 8px;
    width: 100%;
    height: auto;
}
.campaign-card-campaign-name {
    margin: 0px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-word;
    overflow: hidden;
    height:44px;
    font-size: 12px;
    line-height: 22px;
    font-weight: 500;
}
.campaign-card-campaigner-name {
    margin-top: 2px;
    margin-bottom: 4px;
    color: var(--primary1) !important;
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-word;
    overflow: hidden;
    max-width: 140px;
}
.campaign-card-campaigner-name img{
    margin-left: 2px;
    margin-bottom: 6px;
}
.campaign-card-campaign-progress {
    height: 3px !important;
}
.campaign-card-progress-info {
    margin-top: 4px;
    width: 100%;
    display: flex;
    flex-direction: row;
}
.campaign-card-progress-info-left {
    flex: 1 1;
    text-align: left;
}
.campaign-card-progress-info-right {
    /* flex: 1; */
    flex: none;
    text-align: right;
}
.campaign-card-campaign-progress .progress-bar{
    background-color: #F9A215;
}

.campaign-card.txt-caption{
    font-size: 12px;
    line-height: 18px;
    color:#333333;
}

.campaign-card-progress-info > div > div:first-child{
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: #969696;
    margin-bottom: 2px;
}

.campaign-card-progress-info > div > div:last-child{
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #333333;
}

.icon-lembaga {
    margin-top: -9px;
    position: absolute;
}


@media (max-width: 414px){
    .campaign-card-info-box{
        padding:4px 8px;
    }
    /* .campaign-card{
        height:110px;
    } */
    .campaign-card-image .center-cropped {
        /* width: 195px; */
        height: 110px;
    }

    .campaign-card-campaign-name{
        /* font-size: 12px; */
        /* line-height: 22px; */
    }
}



@media (max-width: 375px){
    .campaign-card{
        /* height:102px; */
    }
    .campaign-card-image .center-cropped {
        /* width: 160px; */
        height: 102px;
    }
    .campaign-card-campaign-name {
        height:36px;
        /* font-size: 12px; */
        /* line-height: 18px; */
    }
    
    .campaign-card.txt-caption{
        /* font-size: 10px; */
        /* line-height: 16px; */
    }
    .campaign-card.txt-caption-m{
        /* font-size: 8px; */
        /* line-height: 14px; */
    }
    .campaign-card-campaigner-name{
        /* font-size: 8px; */
        /* line-height: 14px; */
    }
    .campaign-card-progress-info > div > div:first-child{
        /* font-size:8px; */
        /* line-height: 14px; */
    }
    .campaign-card-progress-info > div > div:last-child{
        /* font-size: 10px; */
        /* line-height: 16px; */
    }
}

@media (max-width: 320px){
    .campaign-card-image .center-cropped {
        /* width: 150px; */
        height: 102px;
    }
}
.search-card {
    width: 100%;
    display: grid;
    grid-template-columns: 65px 11fr;
    grid-column-gap: 16px;
    margin-top:10px;
}
.search-card-image {
    width: 65px;
    border-radius: 4px;
    object-fit: cover;
    object-position: center;
}
.search-card-info {
    flex: 3 1;
    overflow-y: auto;
    text-align: left;
}
.search-card-info-title {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #374151;
}
.search-card-info-campaigner{
    font-weight: 500;
}

input:-internal-autofill-selected {
    background-color: none;
}
.donation-image {
    margin-right: 16px;
    display: flex;
    align-items: center;
}
.donation-image image{
    border-radius: 4px;
}
.cp-card .d-flex{
    justify-content: space-between;
}
.news-card {
    display: block;
    border-radius: 4px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    margin: 10px 0px;
}
.news-card-image {
    /* height: 181px; */
    border-radius: 4px 4px 0px 0px;
    overflow: hidden;
}
.news-card-info {
    padding: 10px 12px;
}
.news-card:hover {
    text-decoration: none;
}
.news-card-info .campaigner-name{
    position:relative;
    margin-left:14px;
    font-weight: 500;
}
.news-card-info .campaigner-name:before{
    content: " ";
    position: absolute;
    background: var(--text-caption);
    width: 4px;
    height: 4px;
    border-radius: 50%;
    border: 1px solid var(--text-caption);
    top: 5px;
    left: -9px;
}


.news .favourite-card-name {
    -webkit-line-clamp: 3;
}
.disbursement-image {
    margin-right: 16px;
}
.share-modal .modal-content {
    max-width: 400px;
    margin: auto;
}
.share-modal .modal-header {
    border-bottom: 4px solid #E5E5E5;
    padding: 15px;
}
.share-modal .modal-title {
    font-weight: 600;
}
.share-modal .share-btn {
    width: 100%;
    margin: 5px 0px;
}
.share-modal .share-btn .share-icon {
    border-radius: 5px;
}
.share-copy {
    display: flex;
    width: 100%;
}
.share-copy .share-copy-icon {
    border: 1px solid rgb(238, 238, 238);
    padding: 5px;
}
.share-copy .share-copy-text {
    width: 100%;
    border: 1px solid rgb(238, 238, 238);
    padding: 5px 10px
}
.share-copy .share-copy-text p {
    margin: 0px;
}
.share-copy .share-copy-btn {
    max-width: 100px;
}
.share-copy .share-copy-btn button {
    margin: 0px;
    border-radius: 0px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}
.filter-sort {
    margin-bottom: 30px;
}
.filter-category, .filter-submit {
    padding-top: 0px;
}

.filter-submit {
    margin-top: auto;
    margin-bottom: 30px;
}

.filter-sort h5, .filter-category h5 {
    margin-bottom: 15px;
    font-weight: 600;
}

.filter-submit .btn {
    padding:12px 20px;
}

.filter-sort .btn, .filter-category .btn {
    margin: 10px 10px 0px 0px;
    color:var(--text-body);
    border: 1px solid var(--mono-gray5);
}
.filter-sort .btn-disabled, .filter-category .btn-disabled{
    background: #FFFF;
}

.filter-sort .btn:focus, .filter-category .btn:focus{
    box-shadow: none;
}

.filter-sort .btn:hover, .filter-category .btn:hover {
    background-color: #FFEED9;
    border: 1px solid var(--primary1);
    color: var(--primary1);
    font-weight: 600;
}
.filter-category .btn-filter-active, .filter-sort .btn-filter-active{
    color:#FFFF !important;
    border:none !important;
    background: var(--primary1) !important;
}
/* Banners Stylesheet */

.main-home .section{
    padding:20px;
}

.main-home .banner-home {
    min-height: 150px;
}
.main-home .banner-home img {
    width: 100%;
    height: auto;
    max-height: 280px;
}
.main-home .banner-home .slick-next {
    right: 30px;
}
.main-home .banner-home .slick-prev {
    left: 20px;
    z-index: 1;
}
.main-home .banner-home .slick-next::before,
.main-home .banner-home .slick-prev::before {
    font-size: 30px;
}
.main-home .banner-home .slick-dots {
    bottom: 5px;
}
.main-home .banner-home .slick-dots .slick-active button::before {
    color: var(--primary1);
    font-size: 8px;
    opacity: 1;
}
.main-home .banner-home .slick-dots li button::before {
    color: #FFFFFF;
    font-size: 8px;
    opacity: 1;
}
.main-home .slick-dots li {
    margin:0px;
}

/* Favourite Campaign Stylesheet */
.main-home .favourite-campaign-home {
    margin: 30px 0px 30px 0px;
    padding: 0px;
}

.main-home .favourite-campaign-home .slick-next {
    top: 40%;
    z-index: 1;
    right: 15px;
}
.main-home .favourite-campaign-home .slick-prev {
    top: 40%;
    left: 5px;
    z-index: 1;
}
.main-home .favourite-campaign-home .slick-next::before, 
.main-home .favourite-campaign-home .slick-prev::before {
    color: #fff;
    font-size: 30px;
    -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.main-home .favourite-campaign-home .more-campaign{
    height: 191px;
    margin-top:10px;
    padding-top: 70px;
    width:180px;
    text-align: center;
}
.main-home .favourite-campaign-home .more-campaign img{
    margin:auto;
}

/* Campaign Stylesheet */
.main-home .campaign-home {
    
}
.main-home .campaign-home-category {
    overflow-x: auto;
    white-space: nowrap;
    margin-top: 10px;
}
.main-home .campaign-home-category button {
    margin: 0px 5px 0px 0px;
    display: inline-block;
    font-size:12px;
    font-weight: 600;
    line-height: 15px;
    padding:6px 10px !important;
    border-radius: 4px;
}


.main-home .campaign-home-category .btn-disabled {
    background-color: var(--mono-gray7);
    border-color: var(--mono-gray5);
    color:var(--text-body);
}
.main-home .campaign-home .campaign-home-header .campaign-home-header-text {
    text-align: left;
    z-index: 1;
}
.main-home .campaign-home .campaign-home-header .campaign-home-header-action {
    text-align: right;
    font-weight: normal;
}

/** Loading */
.main-home .image-cover {
    width: 100%;
}
.main-home .cover-loading {
    height: 169px;
}
.main-home .akun-saya{
    padding-top:0px;
    margin-top:-20px;
}
.main-home .akun-saya .main-menu{
    /* background: linear-gradient(360deg, rgba(255, 255, 255, 0) 36.53%, #FFEED9 100%), #FFFFFF; */
    background: #FFFFFF;
    border: unset;
    box-sizing: border-box;
    box-shadow: 0px 6px 16px rgba(215, 168, 105, 0.2);
    border-radius: unset;
    margin-top:14px;
    display: -webkit-box;
    justify-content: center;
    padding: 16px;
    margin-bottom:unset;
    overflow-x: auto;
}
.main-home .akun-saya .main-menu > div {
    /* margin: 14px 4%; */
    margin: 14px 6%;
    text-align: center;
    max-width: 80px;
}

.main-home .footer .container{
    background-color: #FEF6E8;
    padding:32px 20px 20px 20px;
    color: #6B7280;
}
.main-home .footer .container .line-footer{
    border-bottom: 1px solid #D1D5DB;
}
.main-home .footer .address{
    margin-top:16px;
    line-height: 22px;
    margin-bottom: 32px;
}
.main-home .footer .about {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #6B7280;
}
.main-home .footer-logo{
    margin-bottom: 20px;
}
.main-home .footer-menu{
    display:flex;
    justify-content: space-between;
}
.main-home .menu-footer {
    margin: auto;
    display: table;
    padding-top: 30px;
    padding-bottom: 12px;
}

.main-home .menu-footer a {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    color: #6B7280;
    border-right: 1px solid #6B7280;
    grid-gap: 4px;
    gap: 4px;
    padding: 4px;
}

.main-home .menu-footer-contact {
    margin: auto;
    display: table;
    padding-bottom: 24px;
}

.main-home .menu-footer-contact a {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    color: #6B7280;
    border-right: 1px solid #6B7280;
    grid-gap: 4px;
    gap: 4px;
    padding: 4px;
}

.main-home .menu-footer a:last-child {
    border: unset;
}

.main-home .footer-menu > div div{
    margin-bottom: 8px;
}

.main-home .footer .copyright{
    padding: 8px 20px 64px 20px;
    background-color: #FEF6E8;
    text-align: center;
    color: #6B7280;
}

.main-home .footer .sosmed {
    margin-top: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    grid-gap: 16px;
    gap: 16px;
}
.footer .btn-wa {
    position: fixed;
    z-index: 20;
    bottom: 0px;
    margin-bottom: 69px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: end;
    justify-content: end;
    width: 100%;
    max-width: 460px;
}

.btn-wa-news {
    position: fixed;
    z-index: 20;
    bottom: 0px;
    margin-bottom: 69px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: end;
    justify-content: end;
    width: 100%;
    max-width: 940px;
}

.img-banner-tbt {
    width: 100%;
}

/** Button Show All */
.btn-home-showall {
    width: 100%;
    text-align: center;
}

.btn-home-showall .btn-show-more{
    margin-top: 10px;
    box-shadow: none;
    padding: 8px 20px;
    background: #FEF6E8;
    border-radius: 8px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #F9A215;
}    
.btn-home-showall .btn-show-more img{
    margin-left: 10px;
}
.card-news-update {
    padding: 12px 0px;
    grid-gap: 12px;
    gap: 12px;
    border-bottom: 1px solid #E5E7EB;
    width: 100%;
    display: grid;
    grid-template-columns: 110px 11fr;
    grid-column-gap: 16px;
}
.card-news-update .txt-date {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #9CA3AF;
}
.card-news-update .txt-campaigner {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #F9A215;
}
.card-news-update .search-card-image {
    width: 112px;
    height: 64px;
    border-radius: 4px;
    object-fit: cover;
    object-position: center;
}
.section-banner-tbt {
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
} 
.container-fav-campaign {
    padding: 12px 20px 12px !important;
}
.favourite-campaign-home:first-child {
    margin: 10px 0;
}
@media (max-width: 1400px){
    .btn-wa-news {
        max-width: 860px;
    }
}
@media (max-width: 1025px){
    .btn-wa-news {
        max-width: 730px;
    }
}
@media (max-width: 769px){
    .btn-wa-news {
        max-width: 580px;
    }
}
@media (max-width: 760px){
    .btn-wa-news {
        max-width: 540px;
    }
}
@media (max-width: 320px){
    .main-home .section {
        padding: 0px 10px;
    }
}
.akun-saya{
    padding-top:38px;
}
.akun-saya .container{
    padding-top:10px;
}
.akun-saya .card-profile{
    border-radius: 4px;
    display: grid;
    grid-template-columns: 1fr 10fr 1fr;
    grid-gap: 16px;
    padding:15px;
    margin-top:10px;
    margin-bottom:5px;
    align-items:center;
}
.akun-saya .card-profile > div:first-child > img{
    width:48px;
    height:48px;
}

.akun-saya .account_menus{
    margin-top:10px;
}
.akun-saya .account_menus a{
    color:#212529;
    padding-left:12px;
}
.akun-saya .account_menus .nav-menu{
    margin-bottom: 0px;
}

.akun-saya .account_menus a:hover{
    text-decoration: none;
}
.akun-saya hr{
    border-color: var(--mono-gray5);
}

.akun-saya .category-profile{
    display:flex;
    width: 100%;
    border: 1px solid var(--mono-gray5);
    border-radius: 8px;
}
.akun-saya .category-profile .certified{
    margin-left:3px;
    margin-bottom:1px;
}
.akun-saya .category-profile div{
    padding:4px 10px;
}
.akun-saya .category-profile div:first-child{
    background: var(--primary3);
    width:40px;
    border-radius: 5px 0px 0px 5px;
    border:1px solid var(--primary3);
    text-align: center;
}
.akun-saya .category-profile div:nth-child(2){
    padding:4px 10px;
    width: 100%;
}

.akun-saya .main-menu{
    background: linear-gradient(360deg, rgba(255, 255, 255, 0) 36.53%, #FFEED9 100%), #FFFFFF;
    border: 1px solid rgba(249, 162, 21, 0.32);
    box-sizing: border-box;
    box-shadow: 0px 6px 16px rgba(215, 168, 105, 0.2);
    border-radius: 8px;
    margin-top:14px;
    display: flex;
    justify-content: center;
    margin-bottom:10px;
}
.akun-saya .main-menu > div{
    margin:14px 5%;
    text-align: center;
}
.akun-saya .main-menu > div > a > div{
    margin-top:-5px;
}
.akun-saya .hr-am{
    margin-top:30px;
}


.nav-menu{
    display: grid;
    grid-template-columns: 0.5fr 7fr 2.5fr;
    grid-gap: 10px;
    margin-bottom:20px;
}
.nav-menu > div{
    justify-content: left;
    align-items: center;
    display: grid;
}

.nav-menu > div:last-child{
    text-align: right;
    justify-content: right;
    font-weight: 500;
}

.summary{
    border: 2px solid var(--primary2);
    box-sizing: border-box;
    border-radius: 100px;
    text-align: center;
    font-size: 12px;
    color: var(--primary2);
    font-weight: 500;
    min-width: 45px;
}

.nav-menu a{
    color:#5555;
}
.modal-header{
    padding: .5em 1.4em;
}
.modal-title{
    font-size:14px;
}

.img-preview{
    display: grid;
    justify-content: center;
    margin-top:10px;
    padding-top:10px;
    margin:auto;
    border:2px dotted #007DBE;
    border-radius:10px;
    background: #E8F7FF;
    color:#007DBE;
    width:80%;
}
.img-preview img{
    margin:auto;
    margin-top:10px;
    margin-bottom: 20px;
    height: 100px;
}

.img-container{
    margin:auto;
    margin-bottom:.5em;
    width:80%;
    display: grid;
    justify-content: center;
    border-radius:10px;
}
.img-container img{
    width: 80%;
}
.img-preview .avatar{
    border-radius: 50%;
}

.form-upload{
    border: 1px dashed var(--primary1);
    padding: 10px;
    border-radius: 8px;
    position:relative;
}
.otp-field{
    width: 50px;
    height: 65px;
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    margin: 15px 5px;
    background: #FAFAFA;
    border-radius: 4px;
    border: 1px solid #e0e0e0cc;
}
.confirm-alert .modal-header, .confirm-alert .modal-body, .confirm-alert .modal-footer{
    padding:0px 16px !important;
}
.confirm-alert .modal-content{
    max-width: 80%;
    text-align: center;
    padding:0px;
}
.confirm-alert .modal-title{
    margin:auto;
}
.confirm-alert .modal-header{
    margin-bottom:0 !important;
    padding-top:20px !important;
}
.confirm-alert .modal-body{
    padding-bottom:10px !important;
}
.confirm-alert .modal-body > div{
    border-bottom: 0px !important;
}
.confirm-alert .modal-footer{
    display: block;
    padding:0px !important;
    border-top: 1px solid var(--mono-gray4) !important;
}
.confirm-alert .modal-footer .content-grid{
    margin:0px;
}
.confirm-alert .modal-footer button{
    width:100% !important;
    border:none !important;
    border-radius: 0 !important;
}
.confirm-alert .modal-footer .grid-2 > div:first-child > button{
    border-right: 0.5px solid var(--mono-gray4) !important;
}
.confirm-alert .modal-footer .grid-2 > div:last-child > button{
    border-left: 0.5px solid var(--mono-gray4) !important;
}
.search-page > header .nav{
    padding:10px 20px;
}

.search-page .search-form {
    margin-bottom: 0px;
    background: var(--mono-gray6);
    border-radius: 8px;
    margin-left:10px;
}
.search-page .form-control::-webkit-input-placeholder
{
    color:#C4C4C4;
}
.search-page .form-control:-ms-input-placeholder
{
    color:#C4C4C4;
}
.search-page .form-control::-ms-input-placeholder
{
    color:#C4C4C4;
}
.search-page .form-control::placeholder
{
    color:#C4C4C4;
}

.search-page .form-control, .search-page .input-group-text{
    border:none;
    color:#242424;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
}
.search-page .form-control:focus{
    outline: none;
    box-shadow: none;
}
.search-page .search-form .form-label {
    display: none;
}
.search-page .search-form .input-group {
    border-radius: 8px;
    background: #FFFFFF;
    border: 1px solid #D1D5DB;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
}
.search-page .search-form .form-control {
    height: 36px;
    font-size:12px;
    padding-left:0px;
}
.search-page .search-form .form-control, 
.search-page .search-form .input-group-prepend, 
.search-page .search-form .input-group-text {
    background: #FFFFFF;
    border-radius: 8px;
}
.search-page .input-group{
    padding:0px
}
.search-page .head-title{
    padding-left:0px;
}
.search-page .btn-show-more{
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    margin: 20px auto;
    display: table;
    flex: none;
    order: 0;
    flex-grow: 0;
    background: #FEECD0;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #F9A215;
    border-radius: 8px;
    box-shadow: none;
    padding: 8px 16px;
    grid-gap: 4px;
    gap: 4px; 
}

.search-results .search-loader {
    text-align: center;
}
.popular-list .popular-loader {
    padding: 10px 0px;
    text-align: center;
}
.search-page .popular-title{
    font-weight: 600;
}
.search-card-info-campaigner img, .search-card-info-title img{
    margin-left:3px;
}
.search-result-campaigner .search-card-image{
    border-radius: 50%;
    height: 37px;
    width: 37px;
    margin-left: 17px;
}

.search-not-found img{
    width:266px;
    height:188px;
    margin-bottom: 20px;
}
.search-not-found .txt-subtitle{
    margin-top:10px;
    font-weight: 700;
}
.search-not-found .txt-body{
    margin-top:7px;
    font-weight: 500;
    margin-bottom: 10px;
}
.search-not-found .txt-title{
    font-weight: 700;
}
.search-results .card-news-update {
   border: none;
}
.history-pencairan {
    padding-bottom: 75px;
    padding-top: 185px;
}
@media only screen and (max-width: 476px) {
    .history-pencairan {
        padding-top: 170px;
    }
}
.history-pencairan .filter{
    height: auto;
    position: fixed;
    background: white;
    z-index: 99;
    top: 46px;
    max-width: 480px;
    left:0;
    right:0;
    margin:auto;
}
.history-pencairan .filter .search-form{
    margin:20px 0px;
    margin-top:0px;
}
.history-pencairan .filter .section{
    padding-bottom: 0px;
}
.history-pencairan .list-campaign {
    margin-top:100px;
}

.history-pencairan .state{
    display:flex;
    list-style:none;
    padding-left:0;
    justify-content: space-between;
    overflow-x: auto;
}
.history-pencairan .state li:first-child{
    margin-left:0px;
}
.history-pencairan .state li:last-child{
    margin-right:0px;
}
.history-pencairan .state li{
    border: 1px solid var(--mono-gray4);
    border-radius: 4px;
    text-align: center;
    margin-left: 5px;
    margin-right: 5px;
    line-height:18px;
    padding:6px;
    width:100%;
    color:var(--text-caption);
    cursor: pointer;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
}
.history-pencairan .state .active{
    background-color: var(--primary1);
    border: 1px solid var(--primary1);
    color:#FFFFFF;
}

.history-detail {
    padding-bottom: 75px;
    padding-top: 185px;
}
@media only screen and (max-width: 476px) {
    .history-detail {
        padding-top: 180px;
    }
}
.history-detail .filter{
    height: auto;
    position: fixed;
    background: white;
    z-index: 99;
    top: 46px;
    max-width: 480px;
    left:0;
    right:0;
    margin:auto;
}
.history-detail .logo-lampiran {
    border: 1px solid #D4D2D3;
    border-radius: 8px;
    padding: 20px;
}
.history-steps {
    display: flex;
    align-content: flex-start;
}
.history-steps div {
    position: relative;
}
.history-steps .line {
    border-bottom: 2px solid var(--mono-gray4);
    margin-left: 10px;
    margin-top: 5px;
}
.history-steps .line.line-primary {
    border-bottom: 2px solid var(--primary1);
}
.history-steps .point {
    width: 24px;
    height: 24px;
    padding: 0px 3px;
    border-radius: 50%;
    position: absolute;
    background: #fff;
    border: 3px solid var(--mono-gray4);
    z-index: 9;
    top: -6px;
    left: 0px;
}

.history-steps .point-sm {
    width: 12px;
    height: 12px;
    margin-top: 3px;
    border-radius: 50%;
    background: var(--primary1);
}

.history-steps .point.point-right {
    top: -6px;
    right: 0px;
    left: auto;
}
.history-steps .point.point-border-primary {
    border: 3px solid var(--primary1);
}
.history-steps .point.point-primary {
    background: var(--primary1);
    border: 3px solid var(--primary1);
}

.history-steps-detail > div:first-child .steps-title {
    color: var(--primary1);
}

.history-steps-detail > div:last-child  > div:first-child{
    border-left: none;
}

.history-steps-items{
    display:grid;
    grid-template-columns: 1fr 11fr;
    position:relative;
}
.history-steps-items > div:first-child::before{
    content:"";
    width:12px;
    height:12px;
    border-radius: 50%;
    position: absolute;
    background:var(--primary1);
    left:5px;
    top:0px;
}
.history-steps-items > div:first-child{
    border-left: 2px solid rgba(208, 163, 107, 0.5);
    margin-left: 10px;
}
.history-steps-items > div:last-child{
    margin-top: -4px;
}
.history-steps-items .steps-title {
    margin-left: 3px;
    margin-bottom: 5px;
}

.history-pencairan .d-flex{
    margin-bottom: 8px;
    margin-top: 3px;
}
.news {
    padding-top: 20px;
}
.news .news-result{
    padding-top:10px;
}
.news.news-title {
    font-size: 18px
}
.search-bar-news .nav{
    grid-template-columns: 0.6fr 9fr;
}
.search-bar-news .search-input{
    height:36px;
    padding: 6px;
}
.search-bar-news .search-input div:last-child{
    margin-top:2px;
}
.search-input-gray{
    background: var(--mono-gray6);
}
.news-detail{
    padding-top:10px !important;
}
.news-detail .news-content .txt-subtitle-m{
    margin-bottom: 5px;
}
.news-image {
    width: 100%;
}
.news-image  img {
    width: 100%;
}

.news-detail .campaigner-name{
    position:relative;
    margin-left:14px;
    font-weight: 500;
}
.news-detail .campaigner-name:before{
    content: " ";
    position: absolute;
    background: var(--text-caption);
    width: 4px;
    height: 4px;
    border-radius: 50%;
    border: 1px solid var(--text-caption);
    top: 5px;
    left: -9px;
}
.news-detail img{
    width:100% !important;
    height:auto !important;
}
.program-page {
    padding-bottom: 50px;
}
.program-intro {
    padding: 8px 15px;
    border-radius: 5px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 20px;
}
.program-intro h6 {
    margin-bottom: 12px;
}
.program-intro p {
    margin: 0px;
    color: #000;
    line-height: 20px;
    font-size: 12px
}

.program-item {
    padding: 8px 15px;
    border-radius: 4px;
    border: 1px solid #DADADA;
    margin-bottom: 12px;
}
.program-item h6 {
    font-size: 12px;
    line-height: 18px;
}
.program-item p {
    color: #000;
}
.program-item-info {
    margin-right: 15px;
}
.program-item-image {
    padding: 10px 0px;
}
.program-item-image img {
    width: 135px;
    height: 100px;
}
.info li, .info ul{
    margin-top: 10px;
}
.info b{
    margin-bottom: 2px;
}
.info {
    text-align: justify;
}
.info h5{
    font-weight: 700;
    margin:30px;
    text-align: center;
}
.about .sosmed img{
    margin-top:10px;
    margin-left: 12px;
    margin-right: 12px;
}
.about .section{
    padding:20px;
}
.content-report {
    height: auto;
    background: #FEF2DE;
}
.content-report .txt-body-m {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.01em;
    color: #6F6F6F;
}
.list-report {
    margin-top: 16px;
}
.btn-report {
    padding: 10px 14px !important;
    height: 40px !important;
    background: #FFFFFF !important;
    border-radius: 8px !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 150% !important;
    letter-spacing: 0.01em !important;
    color: #F9A215 !important;
    text-align: left;
}
.section-all-report {
    margin-bottom: 12px;
}
.img-right {
    float: right;
    margin-top: 3px !important;
}
:root {
  --text-white: #f2f2f2;
  --text-caption: #969696;
  --text-body: #6F6F6F;
  --text-title: #242424;
}

.extend-campaign{

}

.extend-campaign .campaign-overview {
  background-color: white;
  padding: 16px 20px;
  display: flex;
  margin-bottom: 8px;
  border-bottom: 8px solid #f2f2f2;
  border-bottom: 8px solid var(--text-white);
}

.extend-campaign .campaign-overview .thumbnail {
  width: 66px;
  height: 32px;
  object-fit: cover;
  object-position: center;
}

.extend-campaign .campaign-overview .title {
  flex: 1 1;
  margin-left: 16px;
  font-size: 12px;
  color: #242424;
  color: var(--text-title);
  font-weight: 600;
}

.extend-campaign .overview{
  background-color: white;
  padding: 16px 20px;
}

.label{
  font-size: 12px;
  color: #969696;
  color: var(--text-caption);
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0.01em;
  margin-bottom: 4px;
}

.extend-campaign .overview .early-deadline{
  font-size: 16px;
  font-weight: 500;
  color: #6F6F6F;
  color: var(--text-body);
}

h1.title{
  font-weight: bold;
  font-size: 18px;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: #242424;
  color: var(--text-title);
}

.extend-main button.btn:disabled{
  background: #E5E7E9;
  color: #B6B6B6;
  box-shadow: none;
}

.extend-campaign .item-salur-type {
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 8px;
  height: 50px;
  border: 1px solid #E5E7EB;
  border-radius: 8px;
}
.extend-campaign .item-salur-type.active {
  border: 1px solid #F9A215;
}
.extend-campaign .item-salur-type .md-radio label {
  width: 100%;
  padding: 10px;
  display: block;
  margin: -23px 0 12px 0;
  align-items: center;
}
.extend-campaign .item-salur-type .md-radio label:before {
  top: 22px;
}
.extend-campaign .item-salur-type .md-radio label:after {
  top: 25px;
}

.extend-campaign .md-radio label{
  width:100%;
  padding:10px;
  display: flex;
  align-items: center;
}
.extend-campaign .md-radio label:before{
  right:10px;
  left:inherit;
}
.extend-campaign .md-radio label:after{
  right: 13px;
  left: inherit;
}
.extend-campaign .txt-extend {
  margin: 10px 0 0 0;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #374151;
}
.container-login{
    text-align: center;
}
.container-login .form-group{
    text-align: left;
}
.container-login .lbl-forgot-pass{
    margin-top:-10px;
    margin-bottom: 30px;
}
.container-login .or{
    position:relative;
    margin-top:20px;
    margin-bottom:10px;
}
.container-login .or:after{
    right: 0;
}
.container-login .or:before{
    left:0;
}
.container-login .or:after, .container-login .or:before{
    content: "";
    position: absolute;
    bottom: 8px;
    border: 1px solid var(--mono-gray4);
    width: 30%;
}
.container-login .container-firebase button{
    box-shadow: none !important;
    padding:0px 10px !important;
    border:1px solid #E5E7E9 !important;
    border-radius: 8px !important;
    margin-top:10px;
}


.card-profile img{
    border-radius: 50%;
}
.profil-avatar{
    margin-bottom: 20px;
    
}
.profil-avatar .wrapper-img .icon-upload{
    height: 30px;
    width: 30px;
    border: 1px solid black;
    border-radius: 50%;
    position: absolute;
    left:54px;
    bottom: 0;
    background-color: #C4C4C4;
    border: 3px solid #FFFFFF;
}
.profil-avatar .wrapper-img .icon-upload img{
    height: 15px;
    width: 15px;
    margin-left: 4px;
    cursor: pointer;
    margin-top:3px;
}
.profil-avatar .wrapper-img{
    height: 75px;
    width: 75px;
    position: relative;
    margin: auto;
}
.profil-avatar .wrapper-img img{
    width: 75px;
    height: 75px;
    border-radius: 50%;
}

.link-forgot-password{
    font-size: 14px;
    font-weight: 600;
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: center;
}
.page-otp{
    font-size:12px;
}
.otp-timer{
    margin-top:16px;

}
.ic-otp-timer{
    margin-top:-3px;
}
.otp-field{
    width: 20%;
    height: 70px;
    text-align: center;
    font-size: 32px;
    line-height: 42px;
    margin: 20px 2%;
    background: var(--mono-gray6);
    border: 1px solid var(--mono-gray6);
    border-radius: 8px;
}
.otp-field:focus{
    border: 1px solid #cccccc;
}
.otp-header{
    background: #ffffff !important;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25) !important;
}
.otp-header .nav{
    background-color: #ffffff !important;
}
.otp-header .head-title{
    color:var(--text-title) !important;
    font-weight: 600;
}
.verify-image {
    text-align: center;
    margin: 15% 0px;
}
.verify-image img {
    margin-left: 30px;
    width: 150px;
}
.verify-description p {
    text-align: center;
    white-space: pre-line;
    line-height: 24px;
    margin: 0px;
    color: #000;
    margin-bottom: 10%;
}
/* .error-input::before {
  display: inline;
  content: "";
}
.steps {
  color: #212529;
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  justify-content: center;
  text-align: center;
}

.steps a {
  color:#ffffff5e;
  text-decoration: none;
  font-size: 10px;
}
.steps a:hover,  .steps .active a{
  color:white;
}
.steps li:before{
  border:1px solid black;
}
.steps li {
  padding: 3px 10px;
}
.active a{
  color:#007DBE;
}

.steps li > a > .nav-circle{
  border: 2px solid #ffffff5e;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  position: relative;
  margin-bottom: 2px;
  margin: auto;
}
.steps li>a>.nav-circle>span{
  left: 3px;
  top: 1px;
  width: 5px;
  height: 8px;
  border: solid #ffffff;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(42deg);
  transform: rotate(42deg);
  position: absolute;
}

.steps .active>a>.nav-circle, .steps li > a > .nav-circle:hover{
  border-color: #ffffff;
}

.form-hook{
  margin-top:60px;
  margin-bottom:30px;
}

.form-hook select {
  -moz-appearance:none;
  -webkit-appearance:none;
  appearance:none;
}
.slash{
  font-size: 14px;
  text-align: center;
  padding-bottom: 6px;
  margin: 0;
}

.nav-circle2::before{
  border: 2px solid #c7c5c5;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  position: relative;
  margin-bottom: 2px;
  margin: auto;
}

.content-url-sosmed{
  display:grid;
  grid-template-columns: 1fr 1fr 8fr;
  align-items: self-end;
  margin-top:20px;
}
.content-url-sosmed .url{
  width:auto;
  border-bottom: 1px solid var(--mono-gray4);  
  padding-bottom: 7px;
  font-size: 1.1em;
}
.content-url-sosmed .username input{
  box-shadow: none !important;
  border:none;
  border-bottom: 1px solid var(--mono-gray4);
  border-radius: 0px;
  padding: 0px;
  height: auto;
  font-size: 1.1em;
  padding-bottom: 7px;
}

.nav-steps{
  position: fixed;
  width: 100%;
  left: 0;
  background: var(--primary1);
  top: 37px !important;
  padding-top: 30px;
  padding-bottom: 10px;
  max-width: 480px;
  margin: auto;
  right: 0;
  z-index: 1;
}
.nav-lbl{
  margin-top:5px;
}

.card-upload{
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding:20px;
  display: flex;
  align-items: center;
  margin-bottom:17px;
  padding-left: 30px;
}
.cover-upload{
  display:block !important;
  text-align: center;
  padding-left:20px;
}
.cover-upload .img-container, .cover-upload .img-preview{
  width:100% !important;
}
.cover-upload .img-preview img{
  height: auto !important;
  width:90% !important;
}
.cover-upload .img-preview .avatar{
  border-radius: 0px !important;
}
.cover-upload .form-upload{
  padding-left:0px !important;
  padding-right:0px !important;
}
.cover-upload .cover-priview{
  width:100%;
}
.card-upload .cover-img{
  width: 65px;
  height: 65px;
  display:flex;
  background: rgba(109, 147, 214, 0.3);
}
.card-upload .cover-img .file{
  height: 100%;
}
.card-upload .cover-img img{
  margin:auto;
  width: 65px;
}
.card-upload .form-upload{
  padding-left:30px;
  border:none;
}
.card-upload .form-upload label{
  font-size:14px;
}
.card-upload .form-upload input{
  box-shadow: none !important;
  border: 1px solid #d9d9d9;
  padding: 8px;
  display: block;
  width: 100%;
}
.border-radius50{
  border-radius: 50%;
}
.border-radius50 img{
  width: auto !important;
}

.step-line{
  position: relative;
}

.step-line .left, .step-line .right{
  position: absolute;
  width: 50%;
  height: 3px;
  background: #ffffff5e;
  top: 6px;
}

.done, .done div{
  background:white !important;
}


.step-line .left{
  left: -15px;
}
.step-line .right{
  right: -15px;
}
.ck.ck-editor__editable_inline{
  border: 1px solid #c4c4c4;
  min-height: 300px;
}
.ck-toolbar, .ck-content{
  border-radius:0px !important;
  border:none !important;
}

.container-ckeditor{
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 4px;
}
.ck-focused{
  box-shadow: none !important;
} */
.verifikasi-lembaga header{
  box-shadow: none !important;
  background: none;
}
.verifikasi-lembaga .form-hook{
  margin-top: 60px;
}
.verifikasi-lembaga header .head-title{
  color:#ffffff;
}
.verifikasi-lembaga header .nav{
  background-color: var(--primary1);
}
.verifikasi-lembaga .navbar{
  box-shadow: none;
}
.verifikasi-lembaga .container-step4 .label{
  margin-top:2px;
  margin-bottom:7px;
}
.verifikasi-lembaga .container-step4 .field{
  margin-bottom: 26px;
}
.verifikasi-lembaga .container-step4 .info-form{
  margin-bottom:30px;
}
.input-file{
  position: relative;
}
.input-file input{
  position:absolute;
  text-align: right;
	-moz-opacity:0 ;
	opacity: 0;
  width: 100%;
  z-index: 2;
  cursor: pointer;
}
.input-file .container-file{
  z-index: 1;
}
.input-file .container-file > div > div:first-child > img{
  margin-right:10px;
}
.input-file .container-file .loader-img{
  margin-top: -16px;
  margin-bottom: -16px;
}

.content-url-sosmed{
  display:grid;
  grid-template-columns: 1fr 1fr 8fr;
  align-items: self-end;
  margin-top:10px;
  margin-left:20px;
  margin-right:20px;
  margin-bottom: 10px;;
}
.content-url-sosmed .url{
  width:auto;
  border-bottom: 1px solid var(--mono-gray4);  
  padding-bottom: 7px;
  font-size: 1.1em;
}
.content-url-sosmed .username input{
  box-shadow: none !important;
  border:none;
  border-bottom: 1px solid var(--mono-gray4);
  border-radius: 0px;
  padding: 0px;
  height: auto;
  font-size: 1.1em;
  padding-bottom: 7px;
}
.content-url-sosmed .slash{
  font-size: 14px;
  text-align: center;
  padding-bottom: 6px;
  margin: 0;
}

.verifikasi-lembaga .otp-header{
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25) !important;
}
.verifikasi-lembaga .page-otp{
  margin-top:-75px;
}
.verifikasi-lembaga .phone-verified{
  margin-left:16px;
}

.verifikasi-lembaga .input-file .container-file{
  padding:0px;
  border-radius: 8px;
}
.verifikasi-lembaga .input-file .label, 
.verifikasi-lembaga .input-file .content-info,
.verifikasi-lembaga .input-file .review
{
  padding:12px 16px;
}
.verifikasi-lembaga .input-file .content-info{
  background-color: var(--primary3);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.btn-upload-penggalang-lembaga {
  position: absolute;
  float: right;
  right: 0;
  margin: -55px 10px 0 0;
  cursor: pointer;
}
.list-donatur article.nav img {
  width: 35%;
}
.verifikasi-personal header{
  box-shadow: none !important;
  background: none;
}
.verifikasi-personal .form-hook{
  margin-top: 60px;
}
.verifikasi-personal header .head-title{
  color:#ffffff;
}
.verifikasi-personal header .nav{
  background-color: var(--primary1);
}
.verifikasi-personal .navbar{
  box-shadow: none;
}
.verifikasi-personal .container-step4 .label{
  margin-top:2px;
  margin-bottom:7px;
}
.verifikasi-personal .container-step4 .field{
  margin-bottom: 26px;
}
.verifikasi-personal .container-step4 .info-form{
  margin-bottom:30px;
}
.input-file{
  position: relative;
}
.input-file input{
  position:absolute;
  text-align: right;
	-moz-opacity:0 ;
	opacity: 0;
  width: 100%;
  z-index: 2;
  cursor: pointer;
}
.input-file .container-file{
  z-index: 1;
}
.input-file .container-file > div > div:first-child > img{
  margin-right:10px;
}
.input-file .container-file .loader-img{
  margin-top: -16px;
  margin-bottom: -16px;
}


.verifikasi-personal .otp-header{
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25) !important;
}
.verifikasi-personal .page-otp{
  margin-top:-75px;
}
.verifikasi-personal .phone-verified{
  margin-left:16px;
}


.verifikasi-personal .input-file .container-file{
  padding:0px;
  border-radius: 8px;
}
.verifikasi-personal .input-file .label, 
.verifikasi-personal .input-file .content-info,
.verifikasi-personal .input-file .review{
  padding:12px 16px;
}
.verifikasi-personal .input-file .content-info{
  background-color: var(--primary3);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.btn-outline-primary {
  border: 1px solid #F9A215 !important;
  border-radius: 8px;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #F9A215;
}
.input-file .container-file .label .txt-body-m {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #374151;
}
.input-file .container-file .label .txt-caption {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #9CA3AF;
  max-width: 80%;
}
.btn-white {
  color: #F9A215 !important;
  background: #ffffff !important;
}
.img-profile-cropped {
  background: #F3F4F6;
  border: 1px solid #E5E7EB;
  border-radius: 50%;
  width: 60px;
  height: 60px;
}
.img-card-cropped {
  background: #F3F4F6;
  border: 1px solid #E5E7EB;
  border-radius: 4px;
  width: 60px;
  height: 40px;
}
.img-card-file {
  border-radius: 4px;
  width: 60px;
  height: 40px;
}
.btn-upload-penggalang {
  position: absolute;
  float: right;
  right: 0;
  margin: -65px 10px 0 0;
  cursor: pointer;
}
.btn-upload-penggalang input[type="file"] {
  cursor: pointer;
}
.verifikasi-komunitas header{
  box-shadow: none !important;
  background: none;
}
.verifikasi-komunitas .form-hook{
  margin-top: 60px;
}
.verifikasi-komunitas header .head-title{
  color:#ffffff;
}
.verifikasi-komunitas header .nav{
  background-color: var(--primary1);
}
.verifikasi-komunitas .navbar{
  box-shadow: none;
}
.verifikasi-komunitas .container-step4 .label{
  margin-top:2px;
  margin-bottom:7px;
}
.verifikasi-komunitas .container-step4 .field{
  margin-bottom: 26px;
}
.verifikasi-komunitas .container-step4 .info-form{
  margin-bottom:30px;
}
.input-file{
  position: relative;
}
.input-file input{
  position:absolute;
  text-align: right;
	-moz-opacity:0 ;
	opacity: 0;
  width: 100%;
  z-index: 2;
  cursor: pointer;
}
.input-file .container-file{
  z-index: 1;
}
.input-file .container-file > div > div:first-child > img{
  margin-right:10px;
}
.input-file .container-file .loader-img{
  margin-top: -16px;
  margin-bottom: -16px;
}


.verifikasi-komunitas .otp-header{
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25) !important;
}
.verifikasi-komunitas .page-otp{
  margin-top:-75px;
}
.verifikasi-komunitas .phone-verified{
  margin-left:16px;
}


.verifikasi-komunitas .input-file .container-file{
  padding:0px;
  border-radius: 8px;
}
.verifikasi-komunitas .input-file .label, 
.verifikasi-komunitas .input-file .content-info,
.verifikasi-komunitas .input-file .review{
  padding:12px 16px;
}
.verifikasi-komunitas .input-file .content-info{
  background-color: var(--primary3);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.txt-label {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #6B7280;
}
.profil-campaigner .tab-menu{
    display:flex;
    justify-content: center;
}
.profil-campaigner .tab-menu > div{
    width: 100%;
    text-align: center;
    border-bottom:1px solid var(--mono-gray4);
    padding-bottom:4px;
}
.profil-campaigner .tab-menu .active{
    border-bottom:2px solid var(--primary1);
    color:var(--primary1);
    font-weight: 600;
}
.profil-campaigner .search-page .search-form{
    margin-left:0px;
    margin-top:20px;
}
.profil-campaigner .search-page .form-control, .profil-campaigner .search-page .input-group-text{
    background-color:none;
}
.campaigner-header {
    display: flex;
    padding-top: 20px;
    padding-bottom: 20px;
}
.campaigner-name {
    margin: 0px;
    font-weight: bold;
}
.campaigner-header p {
    font-size: 12px;
    margin-top: 0px
}
.campaigner-header-img {
    max-width: 50px;
    text-align: center;
    margin-right: 12px;
    position: relative;
}
.campaigner-header-img .campaigner-logo {
    border: 1px solid rgb(248, 248, 248);
    max-width: 50px;
    height: 50px;
    width: 100%;
    border-radius: 100px;
    object-fit: cover;
}
.campaigner-header-img .campaigner-verified {
    z-index: 1;
    position: absolute;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    position: absolute;
    bottom: 5px;
    right: -5px;
    background-color: #6D93D6;
    border: 3px solid #FFFFFF;
}
.campaigner-header-img .campaigner-verified img {
    height: 11px;
    width: 11px;
    margin-left: 2px;
    cursor: pointer;
    margin-top: -5px;
}
.campaigner-bio {
    display: flex;
    flex-direction: column;
    padding-top: 15px;
    padding-bottom: 15px;
    min-height: 200px;
}
.campaigner-fav {
    padding-top: 15px;
    padding-bottom: 15px;
}
.ic-check-campaigner {
    border-radius: unset !important;
}
.content-space-campaigner {
    position: relative;
    bottom: 0;
    width: 100%;
    color: black;
    height: 3px;
    border-bottom: 8px solid #F2F2F2;
}
.modal-filter div {
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    line-height: 18px;
    padding: 8px 12px;
    width: 100%;
    color: var(--text-caption);
    cursor: pointer;
    font-weight: 600;
    align-items: center;
    white-space: nowrap;
    display: flex;
    justify-content: space-between;
    border-right: 1px solid #E5E7EB;
}
.modal-filter div:last-child {
    border-right: unset;
}
.modal-filter span {
    margin-right: 26%;
    margin-left: 3%;
}
.informasi a {
    text-decoration: underline;
}
.profil-campaigner .main-info {
    padding-bottom: 0;
    margin-bottom: 12px;
}
.informasi-count-campaigner {
    text-align: center;
    margin-top: 16px;
}
.informasi-count-campaigner div {
    border-right: 1px solid #E5E7EB;
}
.informasi-count-campaigner div:last-child {
    border-right: unset;
}
.profil-campaigner .informasi-count-campaigner .txt-body-m, .txt-body-m p {
    margin: unset;
}
.akun-saya .profil-campaigner .card-profile{
    border-radius: 4px;
    display: grid;
    grid-template-columns: 1fr 10fr 1fr;
    grid-gap: 16px;
    padding: 0 10px;
    margin-top: 20px;
    align-items: center;
}
.preverification{
    margin-top:50px;
}
.preverification .box-gradient-bottom{
    min-height: calc(100vh - 250px);
    padding:30px 20px;
    padding-bottom:0px;
}
.preverification .title{
    margin-top:20px;
}
.preverification .desc{
    margin-top:7px;
    margin-bottom:135px;
}
.preverification .btn{
    margin-bottom:10px;
}
.preverification .grid-2{
    margin-top:150px;
    grid-column-gap: 10px;
}
.preverification .campaigner-types{
    display:flex;
    flex:1 1;
}
.preverification .campaigner-types > div{
    position:relative;
    border:1px solid var(--mono-gray5);
    border-radius: 8px;
    text-align: center;
    padding:32px 16px;
    cursor:pointer;
    min-height: 200px;
    margin:8px;
}
.campaigner-types .checked{
    position:absolute;
    top:0;
    right:0;
    margin-right:4px;
    margin-top:4px;
}
.preverification .campaigner-types > .active{
    border:1px solid var(--primary1);
}
.choose-campaigner{
    margin-top:20px;
}
.choose-campaigner .desc{
    margin-bottom: 20px;
}

.preverification-utama{
    margin-top:50px;
}
.preverification-utama .box-gradient-bottom{
    padding:30px 20px;
    margin-bottom:24px;
}
.preverification-utama .title{
    margin-top:20px;
}
.preverification-utama .desc{
    margin-top:16px;
    margin-bottom:24px;
}
.preverification-utama .btn{
    margin-bottom:10px;
}
.preverification-utama .grid-2{
    margin-top:150px;
    grid-column-gap: 10px;
}
.preverification-utama .campaigner-types{
    display:flex;
    flex:1 1;
}
.preverification-utama .campaigner-types > div{
    position:relative;
    border:1px solid var(--mono-gray5);
    border-radius: 8px;
    text-align: center;
    padding:32px 16px;
    cursor:pointer;
    min-height: 200px;
    margin:8px;
}
.preverification-utama .campaigner-types > .active{
    border:1px solid var(--primary1);
}
.preverification-utama .title-penggalang-dana {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #374151;
}
.preverification-utama .txt-url {
    color: #F9A215;
}

.box-timeline .title-penggalang-dana {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    text-align: left;
    letter-spacing: -0.02em;
    color: #374151;
    margin: 20px;
    max-width: 371px;
}
.box-timeline .boxed {
    padding: 12px;
    text-align: left;
    background: #FFFFFF;
    border: 1px solid #E5E7EB;
    margin-left: 26px;
    border-radius: 8px;
    margin-bottom: 16px;
}

.content-timeline{
    display: grid;
    grid-template-columns: 8fr 2fr;
    position: relative;
    margin-left: 15px;
}
.content-timeline > div:first-child::before{
    content: "1";
    border-radius: 50%;
    padding: 5px 4px 4px 3px;
    width: 28px;
    height: 28px;
    background: #F9A215;
    color: #FFFFFF;
    position: absolute;
    left: 5px;
    top: 5px;
}

.content-timeline > div:first-child{
    border-left: 1px solid #D1D5DB;
    margin-left: 19px;
    margin-top:5px;
}

.content-timeline.second > div:first-child::before{
    content: "2";
    border-radius: 50%;
    padding: 5px 4px 4px 3px;
    width: 28px;
    height: 28px;
    background: #F9A215;
    color: #FFFFFF;
    position: absolute;
    left: 5px;
    top: 5px;
}

.content-timeline.second > div:first-child{
    border-left: unset;
    margin-left: 19px;
    margin-top:5px;
}
.content-timeline .boxed img {
    margin-bottom: 8px;
}

.content-timeline .boxed .txt-title {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #374151;
    margin-bottom: 8px;
}

.content-timeline .boxed .txt-body {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #6B7280;
}

.box-tipsgalang {
    padding: 24px 20px;
    background: #FEF6E8;
    text-align: left;
    margin-top: 8px;
}
.box-tipsgalang h4{
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.02em;
    color: #374151;
}
.box-tipsgalang p {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #6B7280;
}
.box-tipsgalang .contentboxed {
    padding: 12px;
    background: #FFFFFF;
    border: 1px solid #E5E7EB;
    border-radius: 8px;
    margin-bottom: 16px;
}
.box-tipsgalang .contentboxed img {
    margin-bottom: 8px;
}
.box-tipsgalang .contentboxed h5 {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #374151;
}
.box-tipsgalang .contentboxed p {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #6B7280;
}
.box-galangdana {
    padding: 24px 20px 40px;
    background: #F9A215;
}
.box-galangdana p {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #FFFFFF;
}
.box-galangdana .btn-white {
    background: #FFFFFF;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #F9A215;
}
.onboarding-galangdana .nav-link{
    background: #FFFFFF;
    border: 1px solid #F3F4F6;
    box-shadow: 0px 1px 3px rgb(16 24 40 / 10%), 0px 1px 2px -1px rgb(16 24 40 / 10%);
    border-radius: 50%;
    width: 35px;
    height: 35px;
    padding: 9px 8px !important;
}
.onboarding .txt-title {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.02em;
    color: #374151;
}
.onboarding .txt-body {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #6B7280;
}
.onboarding .info-green {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #6B7280;
    display: flex;
}
.onboarding .info-red {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #6B7280;
    display: flex;
}
.onboarding .label.txt-body-m {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #6B7280;
}
.choose-campaigner .img-main {
    margin-bottom: 24px;
}
.choose-campaigner .head-main {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #374151;
    margin-bottom: 16px;
}
.choose-campaigner .paragh-main {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #6B7280;
    margin-bottom: 24px;
}
.confirm-update {
    border-radius: 8px;
}
.confirm-update .modal-content .modal-header {
    padding: 0 12px;
}
.confirm-update .txt-title.modal-title.h4 {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #374151;
    text-align: left !important;
}
.confirm-update .txt-body.modal-body {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #6B7280;
    text-align: left !important;
    padding: 0 12px;
}
.confirm-update .modal-footer {
    flex-wrap: unset;
    border: unset;
}
.confirm-update .btn-primary {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
    padding: 8px 20px;
    background: #F9A215;
    border-radius: 8px;
}
.confirm-update .btn-outline-secondary {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #6B7280;
    padding: 8px 20px;
    background: #FFFFFF;
    border: 1px solid #D1D5DB;
}


.rekening-item {
    display: flex;
    flex-direction: row;
    padding: 10px;
    border: 1px solid #D4D2D3;
    border-radius: 5px;
    margin-bottom: 10px;
}
.rekening-item .rekening-info {
    width: 75%;
}
.rekening-item .rekening-action {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap-reverse;
    height: inherit;
    vertical-align: middle;
}
.rekening-item .rekening-action button {
    font-size: 12px;
    padding: 5px 15px;
    border: 1px solid #D4D2D3;
    border-radius: 4px;
    color: #6F6F6F;
    margin-top: auto;
}
.documentation-item {
    padding: 16px;
    border: 1px solid var(--mono-gray5);
    border-radius: 8px;
    margin-bottom: 16px;
}
.documentation-item .documentation-info {
    width: 75%;
}
.documentation-item .documentation-action {
    display: flex;
    align-items: baseline;
    grid-gap:10px;
    gap:10px;
    margin-top: 12px;
}
.documentation-item .documentation-action .btn-dropdown {
    padding: 5px;
    border: 0px;
}
.documentation-item .documentation-action .btn-dropdown::after{
    display: none;
}
.document-preview {
    border-radius: 10px !important;
}
.news {
    padding-top: 20px;
}
.news .news-result{
    padding-top:10px;
}
.news.news-title {
    font-size: 18px
}
.search-bar-news .nav{
    grid-template-columns: 0.6fr 9fr;
}
.search-bar-news .search-input{
    height:36px;
    padding: 6px;
}
.search-bar-news .search-input div:last-child{
    margin-top:2px;
}
.search-input-gray{
    background: var(--mono-gray6);
}
.news-campaigner .txt-body {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #6B7280;
}
.ic-arrow-close {
    background: url("/assets/img/ic-arr-close.svg") no-repeat;
}

header{
    width: 100%;
    top: 0px;
    left: 0px;
    z-index: 11;
    position: fixed;
    background: #ffffff;
    border-bottom: none;
    /* box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25); */
    box-shadow: 0px 6px 16px rgba(151, 151, 151, 0.16);
}

header .nav{
    margin: auto;
    max-width: 480px;
}

header .nav > div{
    width: 100%;
}

header .nav {
    grid-template-columns: 0.1fr 9fr;
    display: grid;
    grid-gap: 0.5rem;
    align-items: center;
    justify-items: left;
    padding:0px 20px;
    height: 56px;
}
header .nav-col-3{
    /* grid-template-columns: 0.1fr 7fr 2fr; */
    grid-template-columns: 0.1fr auto 0.1fr;
}
header .nav-col-1{
    grid-template-columns: 12fr;
}
header .nav .nav-link{
    padding:0px;
    padding-top:5px;
}

header .head-title {
    padding-left:10px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #374151;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-word;
    overflow: hidden;
}

header .nav.col-2 div:last-child svg {
    grid-column-start: 2;
}

header .nav > .last-nav {
    text-align: right;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1.2rem;
    justify-self: right;
}
header .nav-splash{
    display:block;
    padding:12px 16px;
}

.header-menu-link .btn-dropdown, .nav .btn-dropdown{
    margin-right:0px;
}
.wizard{
    /* position: fixed; */
    height: auto;
    z-index: 1;
    position: absolute;
    background: var(--primary1);
    top: 54px;
    max-width: 480px;
    left:0;
    right:0;
    margin:auto;

    /* height: auto;
    background: var(--primary1);
    max-width: 480px;
    margin: auto;
    margin-top: 54px; */
}

.wizard .steps{
    display:flex;
    justify-content: space-between;
    margin:0px 20px;
    height: 56px;
    align-items: center;
}
.wizard .steps > div{
    min-width: 30%;
    height:auto;
    text-align: center;
    position: relative;
    cursor:pointer;
}
.wizard .steps > div:first-child:after, .wizard .steps > div:nth-child(2):after{
    content: '';
    position:absolute;
    border:1px solid #ffffff;
    background:#ffffff;
    width: 18%;
    right: -18%;
    top: 22%;
    opacity: 0.2;
}
.wizard .steps > .last-step:after{
    opacity:0 !important;
}
.wizard .steps .step-active .step-number, .wizard .steps .step-active .step-label{
    opacity: inherit;
}
.wizard .steps .step-number, .wizard .steps .step-label{
    margin: auto;
    opacity: 0.2;
}
.wizard .steps .step-hide{
    opacity: 0;
}

.wizard .steps .step-label{
    color:#ffffff;
    margin-top:4px;
}
.wizard .steps .step-number span{
    padding:0px 4px;
    border-radius: 50%;
    width:12px;
    height: 12px;
    background:#ffffff;
    font-weight: 600;
    color:var(--primary1);
}
.galang-dana .header-bg-white{
  background:#ffffff;
}
.galang-dana .header-bg-white{
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25) !important;
}

.galang-dana .header-bg-white .head-title{
  color:var(--text-title)
}
.galang-dana .header-bg-white .nav{
  background:none;
}
.galang-dana header{
    box-shadow: none !important;
    background: none;
}

.galang-dana .form-hook{
  margin-top: 60px;
}
.galang-dana header .head-title{
  color:#ffffff;
}
.galang-dana header .nav{
  background-color: var(--primary1);
}
.galang-dana .navbar{
  box-shadow: none;
}
.galang-dana .preverification .form-hook{
  margin-bottom:-75px;
}

.galang-dana .content-url-campaign{
  background:var(--primary3);
  display:grid;
  grid-template-columns: 2fr 8fr;
  align-items: center;
  box-shadow: none !important;
  border-radius: 4px;
  border:1px solid var(--text-disabled);
}
.galang-dana .content-url-campaign input{
  border:none;
}

.galang-dana .content-url-campaign input{
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  box-shadow: none !important;
}

.galang-dana .content-url-campaign .link{
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
  color:var(--primary2);
  padding-left: 10px;
  padding-right: 16px;
}

.galang-dana .form-upload{
  position: relative;
}
.galang-dana .form-upload input{
  position: absolute;
  text-align: right;
  -moz-opacity: 0;
  opacity: 0;
  width: 100%;
  height:120px;
  z-index: 2;
  top: 50px;
  cursor: pointer;
  left: 0;
  right: 0;
}
.galang-dana .container-file{
  z-index: 1;
  text-align: center;
  margin:auto;
}
.galang-dana .container-file .btn{
  margin-top:67px;
  margin-bottom:6px;
}
.galang-dana .container-file .txt-caption-m{
  margin-bottom:48px;
}
.galang-dana .img-container img{
  width:100%;
}
.galang-dana .cropper-view-box{
  /* outline-color: var(--primary1); */
  outline-color: #ffffff;
}
.galang-dana .cropper-point{
  /* background-color: var(--primary1); */
  background-color: #ffffff;
}

.galang-dana .alert-info{
  border-radius:5px;
  padding:16px;
  padding-left:0px;
  padding-bottom: 8px;
}
.galang-dana .styled-checkbox + label{
  font-size: 14px !important;
  line-height: 22px !important;
  margin-left:45px !important;
}
.galang-dana .galang-desc {
  color: #6B7280;
  color: var(--neutral-n-500, #6B7280);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
@media (max-width: 410px) {
  .galang-dana .styled-checkbox + label{
    /* font-size: 12px !important; */
    /* line-height: 18px !important; */
  }
}

.galang-dana .hr-last{
  margin-top:30px;
  margin-bottom:30px;
}
.campaign-saya {
    background:var(--mono-gray6);
}
.campaign-saya .filter{
    height: auto;
    position: fixed;
    background: white;
    z-index: 99;
    top: 46px;
    max-width: 480px;
    left:0;
    right:0;
    margin:auto;
}
.campaign-saya .filter .search-form{
    margin:20px 0px;
    margin-top:0px;
}
.campaign-saya .filter .section{
    padding-bottom: 0px;
}
.campaign-saya .filter .wrapper-search{
    position: relative;
    padding: 0 20px;
    margin-top: 20px;
    margin-bottom: 8px;
}
.campaign-saya .filter input[type=search]{
    color: rgba(36, 36, 36, 1);
    background-color: #F2F2F2;
    padding-left: 36px;
}
.campaign-saya .filter .icon {
    position: absolute;
    bottom: 0;
    top: 0;
    padding-left: 12px;
    display: flex;
    align-items: center;
}
.campaign-saya .list-campaign {
    margin-top: 104px;
}

.campaign-saya .state{
    display:flex;
    list-style:none;
    padding-left:0;
    justify-content: space-between;
}
.campaign-saya .state li:first-child{
    margin-left:0px;
}
.campaign-saya .state li:last-child{
    margin-right:0px;
}
.campaign-saya .state li{
    border: 1px solid var(--mono-gray4);
    border-radius: 4px;
    text-align: center;
    margin-left: 5px;
    margin-right: 5px;
    line-height:18px;
    padding:6px;
    width:100%;
    color:var(--text-caption);
    cursor: pointer;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
}
.campaign-saya .state .active{
    background-color: var(--primary1);
    border: 1px solid var(--primary1);
    color:#FFFFFF;
}

.campaign-saya .card-actions .btn-secondary{
    
    border: 1px solid var(--mono-gray4);
    color:var(--text-body);
}
.campaign-saya .card-actions .btn-secondary:hover{
    background: none;
}



/* .campaign-saya .list-campaign a{
    color:#212529;
}
.campaign-saya .list-campaign a:hover{
    text-decoration:none;
    color:#212529;
}
.campaign-saya .list-campaign .card{
    padding: 10px 15px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
    margin-bottom:15px;
    cursor:pointer;
}
.card .title{
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.165px;
}

.card .cardFunding {
    width: 100%;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    margin-top: 5px;
    margin-bottom: 5px;
    font-size:11px;
}

.card .cardFunding > div {
    display: flex;
    flex-direction: column;
}
.card .cardFunding .dayLeft {
    text-align: right;
}
.card .Publish{
    color:var(--primary2);
}
.card .Draft{
    color:#C4C4C4;
}

.btn-group{
    display:flex;
    flex-direction: row;
    margin-top:10px;
    justify-content: space-between;
}
.btn-group a, .btn-group .btn-group-share {
    background: #FFFFFF;
    border: 1px solid #777777;
    border-radius: 4px;
    padding:4px 8px;
    margin:5px;
    width: 100%;
    text-align: center;
}
.btn-group a > img, .btn-group .btn-group-share > img{
    margin-right: 5px;
}
.btn-group a:first-child{
    margin-left:0;
}
.btn-group .btn-group .btn-group-share{
    margin-right:0;
}
.btn-group-share {
    color: #212529;
    font-size: 12px;
    border-radius: 4px !important;
} */



.campaign-detail{
    margin-top:57px;
}

.campaign-detail .section{
    padding:10px 20px 20px 20px;
}
.campaign-detail .content-grid{
    margin-top:10px;
}
.campaign-detail .img-cover .img, .campaign-detail .wrap-content-news img{
    width: 100%;
}
.campaign-detail .img-cover .btn-groups{
    margin:5px 10px;
    display: flex;
}
.campaign-detail .btn-groups a{
    width:50%;
    margin:10px 5px;
    background: var(--primary1);
    border:1px solid var(--primary1);
    border-radius: 4px;
    color:#FFFFFF;
    padding:10px;
    font-size:10px;
}
.campaign-detail .btn-groups a:hover{
    color:#FFFFFF;
    text-decoration:none;
}

.campaign-detail .title{
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
}
.campaign-detail .target{
    color:rgba(0, 0, 0, 0.54);
}

.campaign-detail .wrap-campaigner .txt-body-m{
    color:#000000;
}


.campaign-detail .wrap-campaigner .wrapper-img .ic-legal{
    height: 12px;
    width: 12px;
    border-radius: 50%;
    position: absolute;
    bottom: 1px;
    left: 23px;
    border: 3px solid white;
}

.campaign-detail .wrap-campaigner .wrapper-img .ic-legal img{
    height: 10px;
    width: 10px;
    margin-left: -2px;
    cursor: pointer;
    margin-top: -14px;
}
.campaign-detail .wrap-campaigner .wrapper-img{
    position: relative;
    margin: auto;
}
.campaign-detail .wrap-campaigner .wrapper-img img{
    width: 32px;
    height: 32px;
    border-radius: 50%;
}

.campaign-detail .wrap-campaigner, .wrap-campaigner:hover{
    color: #000000 !important;
    text-decoration: none;
}
.campaign-detail .wrap-campaigner{
    grid-template-columns: 1fr 11fr;
    margin-top:10px;
}
.campaign-detail .wrap-campaigner .wrapper-img{
    margin-right:15px;
}

.campaign-detail .content-less::after {
    content: "";
    height: 104px;
    background: linear-gradient(rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%);
    position: absolute;
    width: 100%;
    bottom:0;
}

.campaign-detail .content-less{
    height:150px;
    position: relative;
    overflow: hidden;
}

.campaign-detail .description .txt-body-m{
    margin-top:20px;
}

.campaign-detail .read-more{
    background: var(--primary3);
    border-radius: 41px;
    border: 1px solid var(--primary3);
    font-size: 12px;
    line-height: 18px;
    color:var(--primary1);
    font-weight: 600;
    padding:6px 25px;
    padding-right:45px;
    position:relative;
}

.campaign-detail .read-more img{
    top: 9px;
    position: absolute;
    right: 18px;
}

.campaign-detail .action-campaign-donate a, .action-campaign-donate button {
    margin-top:20px !important;

}
.campaign-detail .action-campaign-donate button:first-child{
    width:34%;
    margin-right:3%;
    color:#bc7c2f;
}
.campaign-detail .action-campaign-donate a:last-child, .campaign-detail .action-campaign-donate button:last-child{
    width:100% !important;
    margin-right:0px;
}

.campaign-detail .action-campaign-donate a:last-child:hover{
    color:#FFFFFF;
}

.campaign-detail .donors{
    margin-bottom: 60px;
}

.campaign-detail .float-btn-donate{
    margin:0px;
    grid-template-columns:1fr 11fr;
    grid-column-gap: 10px;
}
.campaign-detail .float-btn-donate button:first-child {
    width: 100%;
}


.select-outline{
    background: #FFFFFF;
    border: 1px solid var(--primary1);
    box-sizing: border-box;
    border-radius: 4px;
    color:var(--primary1);
    box-shadow: none !important;
    height:auto !important;
    padding: 2px 2px;
    font-size: 11px;
}

.campaign-detail .btn-actions .btn-disabled{
    background-color: #E5E7E9;
    border: 1px solid #E5E7E9;
}

.campaign-detail .description .txt-body-m img{
    width:100% !important;
    height:auto !important;
}
.campaign-detail .description .read-more img{
    width:auto;
}

.campaign-header .btn-dropdown{
    margin-right: 0;
    margin-left:0;
}

.action-call .grid-2{
    grid-template-columns: 6fr 6fr;
    grid-gap:8px;
    gap:8px;
    margin-bottom:8px;
}

.campaign-detail .action-campaign-donate .btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 24px;
    grid-gap: 8px;
    gap: 8px;
    width: auto;
    max-height: 40px;
    margin-left: 0;
}
.campaign-detail .action-campaign-donate .btn-donate-disabled{
    font-family: 'Rubik', sans-serif;
    font-weight: 500;
    font-style: normal;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 24px;
    grid-gap: 8px;
    gap: 8px;
    width: auto;
    max-height: 40px;
    margin-left: 0;
    background: #3399F9;
    color: #FFFFFF !important;
    cursor: not-allowed;
}

.campaign-detail .float-btn-donate button.btn-share-sosmed{
    padding: 8px;
    grid-gap: 4px;
    gap: 4px;
    width: 40px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #D1D5DB;
    border-radius: 8px;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-right: 0;
}
.campaign-detail .float-btn-donate .btn-donate{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 24px;
    grid-gap: 8px;
    gap: 8px;
    width: auto;
    max-height: 40px;
    margin-left: 0;
}
.campaign-detail .float-btn-donate .btn-donate-disabled{
    font-family: 'Rubik', sans-serif;
    font-weight: 500;
    font-style: normal;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 24px;
    grid-gap: 8px;
    gap: 8px;
    width: auto;
    max-height: 40px;
    margin-left: 0;
    background: #3399F9;
    color: #FFFFFF !important;
    cursor: not-allowed;
}
.txt-orang-donasi {
    position: relative;
    margin: 0px auto 6px auto;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: #6B7280;
}
.campaign-detail .icon-lembaga {
    margin-top: -11px;
    position: absolute;
}
.box-disclaimer {
    display: block;
    text-align: left;
    padding: 12px;
    grid-gap: 10px;
    gap: 10px;
    background: #FEF6E8;
    border-radius: 4px;
    font-family: 'Rubik';
    font-style: normal;
    font-size: 12px;
    line-height: 16px;
    color: #374151;
    margin-bottom: 16px;
}
.section-report {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #6B7280;
    margin-bottom: 16px;
}
.profile-crop {
    width: 40px;
    height: 40px;
    overflow: hidden;
    border: 1px solid #E5E7EB;
    border-radius: 64px;
}
.profile-crop img {
    color: transparent !important;
    width: 100% !important;
    height: 100% !important;
    border-radius: 100% !important;
    object-fit: cover !important;
    object-position: center center !important;
}
.penggalang-crop {
    width: 48px;
    height: 48px;
    overflow: hidden;
    border: 1px solid #E5E7EB;
    border-radius: 64px;
}
.penggalang-crop img {
    color: transparent !important;
    width: 100% !important;
    height: 100% !important;
    border-radius: 100% !important;
    object-fit: cover !important;
    object-position: center center !important;
}
.txt-terkumpul {
    color: #F9A215 !important;
}
.btn-cta-disabled {
    background: #E5E7E9 !important;
    color: rgba(49, 53, 59, 0.38) !important;
    box-shadow: unset !important;

}
.btn-secondary {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    color: #F9A215 !important;
}
.info-campaign-expired {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px;
    grid-gap: 8px;
    gap: 8px;
    background: #DCFCE7;
    border-radius: 8px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #6B7280;
}
/* .list-donatur .modal-content{
    max-width: 400px;
    margin: auto;
}
.list-donatur .modal-footer{
    min-height: 40px;
}
.list-donatur .modal-header{
    padding: 17px 16px;
}
.list-donatur .modal-header{
    border-bottom: 4px solid #E5E5E5;
}
.list-donatur .modal-body{
    overflow: auto;
    max-height: 400px;
    padding:0px;
}
.list-donatur .modal-body .list-donatur-loader {
    padding: 20px 0px;
    text-align: center;
}

.list-donatur .modal-body > div{
    border-bottom: 1px solid #E5E5E5;
    padding: 8px 15px;
}

.list-donatur .modal-title{
    font-size: 15px;
    font-weight: 400;
}
.list-donatur .close{
    background: var(--primary1);
    border-radius: 50%;
    margin-top: 0px;
    margin-right: 0px;
    padding: 0px 5px;
    opacity:inherit;
    outline:none;
}
.list-donatur .close > span:first-child{
    color: white;
    font-weight: 300;
} */
.slide-donate{
    padding: 12px 16px;
    width: 222px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left:2px;
    margin-right:10px;
    min-height: 165px;
}

@media (max-width: 410px) {
    .slide-donate{
        width:175px;
        min-height: 140px;
    }
}
.slide-donate .content-avatar{
    margin-top:0px;
    grid-template-columns: 1fr 7fr;
    align-items: flex-start;
    grid-gap: 10px;
}

.slide-donate .content-avatar .wrapper-img .ic-legal img{
    height: 7px;
    width: 7px;
    margin-left: 1px;
    cursor: pointer;
    margin-top: -10px;
}
.slide-donate .content-avatar .wrapper-img{
    position: relative;
    margin: auto;
}
.slide-donate .content-avatar .wrapper-img img{
    width: 46px;
    height: 46px;
    border-radius: 50%;
}

.slide-donate .content-avatar, .content-avatar:hover{
    color: #000000 !important;
    text-decoration: none;
}
.slide-donate .content-avatar{
    grid-template-columns: 1fr 11fr;
}

.slide-donate div{
    margin-bottom: 0px !important;
}

.modal-content .content-avatar{
    grid-column-gap: 10px;
    grid-template-columns: 1fr 11fr;
}
.modal-content .content-avatar .wrapper-img img {
    width:46px;
    height:46px;
    border-radius: 50%;
}

.list-donatur .modal-content .modal-body{
    overflow:auto;
    height:400px;
}
.content-news > div{
    padding-bottom: 20px;
}
.content-news .wrap-content-news{
    margin-left: -10px;
    padding: 0px 10px;
}
.content-news .wrap-content-disbursement{
    background: #FFEED9;
    padding: 16px 10px;
    border-radius: 5px;
}
.content-news .news-title{
    color:var(--text-title);
    margin-bottom: 8px;
}
.content-news.btn-groups button{
    width:95%;
    background: var(--primary1);
    border:1px solid var(--primary1);
    border-radius: 4px;
    color:#FFFFFF;
    padding:5px 10px;
    font-size:12px;
    margin-top:10px;
    height:40px;
}
.content-news.btn-groups button:hover{
    color:#FFFFFF;
    text-decoration:none;
}
.content-news.btn-outline{
    background:#FFFFFF !important;
    color: var(--primary1) !important;
}

.content-news.btn-groups button > .spinner-border{
    width: 1.3rem;
    height: 1.3rem;
    margin-top: 3px;
}

.content-news{
    display:grid;
    grid-template-columns: 1fr 9fr;
    position:relative;
}
.content-news > div:first-child::before{
    content:"";
    width:12px;
    height:12px;
    border-radius: 50%;
    position: absolute;
    background:var(--primary1);
    left:5px;
    top:5px;
}
.content-news > div:first-child{
    border-left: 2px solid var(--primary3);
    margin-left:10px;
    margin-top:5px;
    width:30px;
}

.content-news .wrap-content-news{
    margin-top:10px;
}

.content-news .manage-news{
    margin-bottom: 7px !important;
    align-items: baseline !important;
}

.form-news .container-donate{
    margin-bottom:30px;
}
.form-news .container-donate .campaign-card-image .center-cropped{
    border-radius: 0px;
    width:65px;
    height:40px;
}

.content-news .description img{
    max-width: 100%!important;
    height: auto!important;
}
.withdrawal .container-donate{
    margin-bottom:30px;
}
.withdrawal .container-donate .campaign-card-image .center-cropped{
    border-radius: 0px;
    width:63px;
    height:36px;
}

.withdrawal .fund{
    border: 1px solid var(--mono-gray5);
    border-bottom: 0px;
    box-sizing: border-box;
    border-radius: 8px;
    margin-top:16px;
}
.withdrawal .content-grid{
    margin-top:0px;
    margin:5px 0px;
}
.withdrawal .fund > div{
    padding:10px 16px;
}
.withdrawal .fund > div{
    border-bottom: 1px solid var(--mono-gray4);
}

.withdrawal .content-less::after {
    content: "";
    height: 0px;
    background: white;
    position: absolute;
    width: 100%;
    bottom: 0;
}
.withdrawal .content-less{
    /* height:65px; */
    height:0px;
    padding-top:0px !important;
    position: relative;
    overflow: hidden;
    border-bottom: 0px !important;
    align-items: baseline;
}
.withdrawal .content-less > div:first-child{
    align-items: baseline;
    height: 65px;
}

.withdrawal .section{
    padding-top:20px;
    padding-bottom:20px;
}


.withdrawal .content-banks{
    box-sizing: border-box;
    border-radius: 4px;
    padding: 0px 24px;
}

.withdrawal .form-upload input{
    box-shadow: none !important;
}
.withdrawal .read-more{
    background: var(--primary3);
    border-radius: 8px;
    border: 1px solid var(--primary3);
    font-size: 12px;
    line-height: 18px;
    color:var(--primary1);
    padding:6px 25px;
    padding-right:45px;
    position:relative;
    margin-bottom:10px;
}

.withdrawal .read-more img{
    top: 7px;
    position: absolute;
    right: 18px;
}

.withdrawal .wrap-input-amount{
    grid-template-columns: 1fr 10fr;
    background: #FFFFFF;
    border:1px solid var(--mono-gray5);
    border-radius: 8px;
}
.withdrawal .wrap-input-amount > div:first-child{
    border-right:1px solid var(--mono-gray5);
}
.withdrawal .wrap-input-amount div{
    padding:10px 16px;

}
.withdrawal .form-hook input{
 color:var(--text-caption);
}
.withdrawal .wrap-input-amount input{
    width: 100%;
    outline: none;
    border: none;
    background: none;
    box-shadow: none !important;
    text-align: right;
    font-weight: 600;
    border-radius: 0px;
    padding: 0px;
    font-size: 20px;
}
.withdrawal .bg-light-brown{
    padding-bottom: 20px;
}
.withdrawal .btn-dashed, .withdrawal .btn-dashed:focus{
    border-style: dashed;
    border-color:var(--primary1);
    box-shadow: none;
    background-color: #FFFFFF;
}
.withdrawal .cs-lb::after{
    background-color:var(--primary3);
}
.withdrawal .form-label{
    margin-bottom:8px;
}

.withdrawal .form-upload{
    position: relative;
    border:none;
    padding:0px;
    margin-top:10px;
    margin-bottom:10px;
}
.withdrawal .form-upload input{
    position: absolute;
    text-align: right;
    -moz-opacity: 0;
    opacity: 0;
    width: 100%;
    height: 65px;
    z-index: 2;
    top: 0px;
    cursor: pointer;
    left: 0;
    right: 0;
}
.withdrawal .container-file{
    z-index: 1;
    text-align: center;
    margin:auto;
    font-weight: 600;
}


.withdrawal .dana-cair{
    margin-top: -8px;
    padding-bottom: 13px !important;
    border-bottom:none !important;
}

/* .withdrawal .form-hook .error-input{
    margin-left:0px;
} */

.campaign-charge .red{
    color:var(--red)
}

.withdrawal .close-campaign label{
    margin-left:32px !important;
    margin-top:16px;
    color:var(--text-title) !important;
    font-size: 14px;
}
.withdrawal .close-campaign .styled-checkbox + label:before{
    margin-top:-6px;
}
.withdrawal .close-campaign .styled-checkbox + label:after{
    margin-top:-4px;
}
.fliter-all-campaign {
    width: 100%;
    text-align: center;
    padding-right: 130px;
}
.fliter-all-campaign .btn-actions {
    position: fixed;
    bottom: 30px;
    z-index: 1;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    width:133px;
    padding-top:12px;
    padding-bottom:12px;
}
.fliter-all-campaign .btn-actions .filter-icon {
    margin-right: 5px;
    margin-bottom: 3px;
}

.campaign-ajuan-filter {
    padding-left: 25px;
}
.campaign-ajuan-filter label {
    font-weight: 600;
    margin-left: 10px;
    padding-bottom: 5px;
}
.campaign-ajuan-filter input {
	-webkit-appearance: none;
	border: 1px solid #cacece;
    box-shadow: 1px 4px 4px rgba(0, 0, 0, 0.15);
	padding: 7px;
	border-radius: 2px;
	display: inline-block;
    position: relative;
}
.campaign-ajuan-filter input:active, .campaign-ajuan-filter input:checked:active {
    box-shadow: 1px 4px 4px rgba(0, 0, 0, 0.15);
}

.campaign-ajuan-filter input:checked {
	background-color: #e9ecee;
	border: 1px solid #adb8c0;
    box-shadow: 1px 4px 4px rgba(0, 0, 0, 0.15);
	color: #99a1a7;
}
.campaign-ajuan-filter input:checked:after {
	content: '\2714';
	font-size: 12px;
	position: absolute;
	top: -2px;
	left: 2px;
	color: #99a1a7;
}
.campaign-ajuan-result {
    margin-top: 15px;
}
.campaign-ajuan-card {
    display: block;
    padding: 12px 16px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    margin-bottom: 16px;
}
.campaign-ajuan-card .campaign-title {
    font-size: 14px;
    font-weight: normal;
    color: #000;
    font-size: 14px;
}
.campaign-ajuan-card .campaign-info {
    display: flex;
    flex-direction: row;
    margin-top: 15px;
}

.campaign-ajuan-card .campaign-info p {
    color: #000;
    margin: 0px;
}
.campaign-approval {
    padding-top: 57px;
}
.campaign-approval-banner img {
    width: 100%;
}
.campaign-approval-title {
    margin: 16px 0px;
}
.campaign-approval-title > div {
    margin-top: 10px;
}
.campaign-approval-title p {
    color: #000;
    font-size: 10px;
    margin: 0px;
}
.campaign-approval-title .btn {
    font-size: 12px;
    padding: 4px 12px;
}
.campaign-approval-section {
    margin: 16px 0px;
}
.campaign-approval textarea {
    font-size: 12px;
}
.campaign-approval .btn-action .btn {
    margin: 0px 5px;
    padding: 11px 0px;
    font-weight: bold;
    font-size: 12px;
    font-weight: bold;
}
.campaign-report-title {
    margin-bottom: 25px;
}
.campaign-report-images {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.campaign-report-images .campaign-report-img-container {
    position: relative;
    width: 60px;
    height: 60px;
    margin: 0px 10px 10px 0px;
}
.campaign-report-images .campaign-report-img-container .img-item {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    border: 1px solid #D4D2D3;
}
.campaign-report-images .campaign-report-img-container .img-action {
    position: absolute;
    top: -2px;
    right: -2px;
    border-radius: 10px;
    background: #ACACAC;
    width: 20px;
    height: 20px;
    text-align: center;
    border: 2px solid #fff;
    cursor: pointer;
}
.campaign-report-images .campaign-report-img-container .img-action img {
    width: 8px;
    height: 8px;
    margin-bottom: 4px;
}
.campaign-report-images .campaign-report-btn-add {
    width: 60px;
    height: 60px;
    border: 1px solid #D4D2D3;
    border-radius: 8px;
    padding: 5px;
}
.campaign-report-images .campaign-report-btn-add > div {
    background: #E5E7E9;
    border-radius: 5px;
    text-align: center;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.campaign-report-images .campaign-report-btn-add > div img {
    width: 20px;
    height: 18px;
}

.campaign-report-btn-add-block {
    background-color: #fff !important;
    border: 1px dashed #D4D2D3 !important;
    border-radius: 8px;
    color: var(--primary1) !important;
}
.campaign-report-btn-add-block img {
    margin-right: 10px;
    margin-bottom: 3px;
}

.campaign-report-modal .cropper-view-box{
    /* outline-color: var(--primary1); */
    outline-color: #ffffff;
}
.campaign-report-modal .cropper-point{
    /* background-color: var(--primary1); */
    background-color: #ffffff;
}

.campaign-report .widget-campaign{
    display:grid;
    grid-template-columns: 1fr 11fr;
    grid-column-gap: 16px;
}
.campaign-report .widget-campaign .campaigner img{
    margin-left:3px;
}
.campaign-report .widget-campaign .campaign-card-image .center-cropped{
    width: 90px;
    max-height: 60px;
}
.campaign-report-success-image {
    text-align: center;
    margin: 50px 47px 30px 47px;
}

.campaign-report-success-text {
    text-align: center;
    margin-bottom: 40px;
}

.campaign-report-success-text p {
    color: #6F6F6F;
}
.fliter-all-campaign {
    width: 100%;
    text-align: center;
    padding-right: 130px;
}
.fliter-all-campaign .btn-actions {
    position: fixed;
    bottom: 30px;
    z-index: 1;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    width:133px;
    padding-top:12px;
    padding-bottom:12px;
}
.fliter-all-campaign .btn-actions .filter-icon {
    margin-right: 5px;
    margin-bottom: 3px;
}
.container-donate .section{
    padding: 15px 20px;
}
.container-donate form{
    margin-top:0px;
    margin-bottom:30px;
}
.container-donate .widget-campaign{
    display:grid;
    grid-template-columns: 1fr 11fr;
    grid-column-gap: 16px;
}
.container-donate .widget-campaign .campaigner img{
    margin-left:3px;
}
.container-donate .campaign-card-image .center-cropped{
    width: 54px;
    height: 38px;
}
.container-donate .suggest-donate{
    border: 1px solid var(--mono-gray5);
    padding: 12px 16px;
    border-radius: 8px;
    cursor:pointer;
    margin-bottom: 10px;
}
.container-donate .suggest-donate > div{
    grid-template-columns: 5fr 7fr;
}

.container-donate .suggest-donate .amount{
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
}

.container-donate .suggest-donate:hover .amount{
    color:#212529;
}
.container-donate .sd-active .amount{
    color:var(--mono-gray7);
}

.container-donate .suggest-donate .desc{
    background: var(--mono-gray6);
    border-radius: 8px;
    padding:0px 10px;
    color:var(--primary1);
    margin-top:5px;
    text-align: left;
}
.container-donate .sd-active .desc{
    background: #ffffff;
}

.container-donate .suggest-donate:hover{
    background: #ffe4c3;
    border: 1px solid #ffe4c3;
}

.container-donate .sd-active{
    background: var(--primary1);
    border: 1px solid var(--primary1);
}

.container-donate .wrap-input-amount{
    grid-template-columns: 1fr 10fr;
    background: var(--mono-gray6);
    border:1px solid var(--mono-gray4);
    border-radius: 8px;
    font-weight: 600;
}
.container-donate .wrap-input-amount > div:first-child{
    border-right:1px solid var(--mono-gray4);
}
.container-donate .wrap-input-amount div{
    padding:10px 16px;

}
.container-donate .wrap-input-amount input{
    width: 100%;
    outline: none;
    border: none;
    background: none;
    box-shadow: none !important;
    text-align: right;
    font-weight: 600;
    border-radius: 0px;
    padding: 0px;
    font-size: 20px;
}
.container-donate .styled-checkbox + label{
    margin-left:45px !important;
}

.container-donate .styled-checkbox + label:before{
    top:0px;
}
.container-donate .styled-checkbox:checked + label:after{
    top:7px;
}

/* payment channel */
.container-donate .md-radio label{
    width:100%;
    padding:10px;
    display: flex;
    align-items: center;
}
.container-donate .md-radio label:before{
    right:10px;
    left:inherit;
}
.container-donate .md-radio label:after{
    right: 13px;
    left: inherit;
}
.container-donate .icon-channel{
    width:40px;
    margin-right:20px;
}
.container-donate hr{
    border-top: 1px solid var(--mono-gray6);
}


.wrap-auth-login{
    grid-template-columns: 2fr 8fr;
}

.wrap-auth-login img{
    height:65px;
    width:auto;
    border-radius:50%;
    margin-right:5px;
}
.block-rek{
    background: #E8F7FF;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    margin-top: 15px;
    padding:5px 15px;
}
.block-rek .rek{
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #007DBE;
    padding: 5px 10px;
}
.block-rek img{
    width:80%;
}

.container-donate .payment-group{
    background-color: var(--mono-gray6);
    padding:20px;
    padding-bottom: 10px;
}

.container-donate .item-pay-type{
    padding:20px;
    padding-top:0px;
    padding-bottom: 0px;
}

.item-pay-type .md-radio label:after{
    top:23px;
}

.float-btn-donate{
    margin:0px;
    grid-template-columns:1fr 11fr;
    grid-column-gap: 10px;
}


.float-btn-donate .btn:first-child {
    width: 100%;
}

.info-form .txt-notice {
    color: #111827;
}

.txt-digits {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #9CA3AF;
}

.ic-info {
    position: absolute;
    margin-bottom: 20px;
    margin-top: 3px;
}
.donate-main .box-nominal {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 12px;
    grid-gap: 12px;
    gap: 12px;
    border: 1px solid #D1D5DB;
    border-radius: 8px;
    flex: none;
    order: 2;
    align-self: stretch;
    flex-grow: 0;
    margin-top: 12px;
}
.donate-main img.ic-right {
    padding-top: 5px;
    float: right !important;
    right: 40px;
    position: absolute;
    width: 7.2px;
    color: #fff;
}
.donate-main .ct-title {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #6B7280;
}
.donate-main .box-nominal .ct-title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #374151;
}
.donate-main .container-donate .wrap-input-amount > div:first-child {
    border-right: unset;
}
.donate-main .txt-subtitle-m {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600 !important;
    font-size: 16px;
    line-height: 24px;
    color: #374151;
}
.campaign-card-image .center-cropped {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}
.donate-main .container-donate .wrap-input-amount {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
}
.txt-three-digit {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #9CA3AF;
}
.donate-tbt .accordion__item {
    border-left: unset;
    border-right: unset;
    border-top: unset;
    border-bottom: 1px solid var(--mono-gray5);
}
.donate-tbt .accordion__button {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #6B7280;
    padding: 15px 0 15px 0;
}
.txt-cara-pembayaran {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #374151;
}
.txt-copy {
    background: #FEECD0;
    border-radius: 16px;
    width: 52px;
    padding: 2px 12px;
    grid-gap: 10px;
    gap: 10px;
    right: 33px;
    float: right;
    position: absolute;
    font-weight: 500 !important;
    font-size: 12px;
    line-height: 16px;
    color: #F9A215;
}
.container-donate .payment-group.ct-title.txt-body-m.fw-6 {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #374151;
    background: #fff;
}
.md-radio.box-new-payment label:before, .md-radio label:after {
    display: none;
}
.box-new-payment img.ic-right {
    float: right;
    right: 0;
    position: absolute;
}
.container-donate .md-radio.box-new-payment label {
    padding: 0px;
}
.donate-tbt .form-hook .form-group label, .error-input {
    margin-left: unset;
}
.txt-ket {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px !important;
    line-height: 20px !important;
    text-align: center;
    color: #6B7280 !important;
    margin-bottom: 10px;
    margin-top: 10px;
}
.txt-thanks {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: #374151 !important;
    margin-top: 17px;
}
.txt-program {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px !important;
    line-height: 20px !important;
    color: #6B7280 !important;
}
.color-bold {
    color: #374151 !important;
}
.txt-expired {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #6B7280;
}
.animate__pulse-red{
    /* width: 100%;
    height: 100%; */
    -webkit-animation: pulse 1s infinite;
            animation: pulse 1s infinite;
    border-radius: 50%;
    box-shadow: 0 0 0 0 #ffffff;
    color: #F59E0B;
    background: #fff;
    height: 18px;
    width: 18px;
  }
  
  @-webkit-keyframes pulse {
    0% {
      background-color: #001F3F;
    }
    100% {
      background-color: #FF4136;
    }
  }
  
  @keyframes pulse {
    0% {
      background-color: #001F3F;
    }
    100% {
      background-color: #FF4136;
    }
  }
.donate-check .section{
    padding:20px;
}
.my-donate {
    padding-bottom: 75px;
    padding-top: 160px;
    background-color: var(--mono-gray6);
}
@media only screen and (max-width: 476px) {
    .my-donate {
        padding-top: 160px;
    }
}
.my-donate .filter{
    height: auto;
    position: fixed;
    background: white;
    z-index: 99;
    top: 46px;
    max-width: 480px;
    left:0;
    right:0;
    margin:auto;
}
.my-donate .filter .search-form{
    margin:20px 0px;
    margin-top:0px;
}
.my-donate .filter .section{
    padding-bottom: 0px;
}
.my-donate .list-campaign {
    margin-top:100px;
}

.my-donate-result .d-flex{
    margin-bottom: 8px;
    margin-top: 3px;
}
.bukti-header {
    text-align: center;
    background: linear-gradient(0deg, rgba(208, 163, 107, 0.5), rgba(208, 163, 107, 0.5)), #FFFFFF;
    padding: 15px;
}
.bukti-nominal {
    text-align: center;
    border: 1px dashed #D4D2D3;
    border-radius: 8px;
    padding: 5px;
}
.bukti-donasi .amount{
    margin:8px;
    border-radius: 8px;
    border: 1px dashed var(--mono-gray5);
    padding:5px;
    text-align: center;
}

.bukti-donasi .pending{
    color: var(--yellow);
}
.bukti-donasi{
    background: #ffffff;
}
.bukti-donasi.container {
    padding-top: 16px;
    padding-bottom: 16px;
}
.bukti-donasi .group-btn {
    display: contents;
}
.bukti-donasi .group-btn .first-btn {
    width: 32%;
    padding: 9px 24px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    align-items: center;
    text-align: center;
    color: #F9A215;
}
.bukti-donasi .group-btn .second-btn {
    font-size: 14px;
    font-weight: 500;
    color: #FFFFFF;
    float: right;
    right: 0;
    position: absolute;
    width: 55%;
    padding: 10px 24px;
}
.bukti-donasi.container-donate .campaign-card-image .center-cropped {
    width: 84px;
    height: 48px;
}
.bukti-donasi .widget-campaign .ct-title {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #374151;
}
.bukti-donasi .widget-campaign .txt-campaigner {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #F9A215;
    margin-top: 5px;
}
.img-rotate {
    -webkit-animation:spin 3s linear infinite;
    animation:spin 3s linear infinite;
}
@-webkit-keyframes spin { 
    100% { -webkit-transform: rotate(360deg); } 
}
@keyframes spin { 
    100% { 
        transform:rotate(360deg); 
    } 
}
.container-donate .section{
    padding: 15px 20px;
}
.container-donate form{
    margin-top:0px;
    margin-bottom:30px;
}
.container-donate .widget-campaign{
    display:grid;
    grid-template-columns: 1fr 11fr;
    grid-column-gap: 16px;
}
.container-donate .widget-campaign .campaigner img{
    margin-left:3px;
}
.container-donate .campaign-card-image .center-cropped{
    width: 54px;
    height: 38px;
}
.container-donate .suggest-donate{
    border: 1px solid var(--mono-gray5);
    padding: 12px 16px;
    border-radius: 8px;
    cursor:pointer;
    margin-bottom: 10px;
}
.container-donate .suggest-donate > div{
    grid-template-columns: 5fr 7fr;
}

.container-donate .suggest-donate .amount{
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
}

.container-donate .suggest-donate:hover .amount{
    color:#212529;
}
.container-donate .sd-active .amount{
    color:var(--mono-gray7);
}

.container-donate .suggest-donate .desc{
    background: var(--mono-gray6);
    border-radius: 8px;
    padding:0px 10px;
    color:var(--primary1);
    margin-top:5px;
    text-align: left;
}
.container-donate .sd-active .desc{
    background: #ffffff;
}

.container-donate .suggest-donate:hover{
    background: #ffe4c3;
    border: 1px solid #ffe4c3;
}

.container-donate .sd-active{
    background: var(--primary1);
    border: 1px solid var(--primary1);
}

.container-donate .wrap-input-amount{
    grid-template-columns: 1fr 10fr;
    background: var(--mono-gray6);
    border:1px solid var(--mono-gray4);
    border-radius: 8px;
    font-weight: 600;
}
.container-donate .wrap-input-amount > div:first-child{
    border-right:1px solid var(--mono-gray4);
}
.container-donate .wrap-input-amount div{
    padding:10px 16px;

}
.container-donate .wrap-input-amount input{
    width: 100%;
    outline: none;
    border: none;
    background: none;
    box-shadow: none !important;
    text-align: right;
    font-weight: 600;
    border-radius: 0px;
    padding: 0px;
    font-size: 20px;
}
.container-donate .styled-checkbox + label{
    margin-left:45px !important;
}

.container-donate .styled-checkbox + label:before{
    top:0px;
}
.container-donate .styled-checkbox:checked + label:after{
    top:7px;
}

/* payment channel */
.container-donate .md-radio label{
    width:100%;
    padding:10px;
    display: flex;
    align-items: center;
}
.container-donate .md-radio label:before{
    right:10px;
    left:inherit;
}
.container-donate .md-radio label:after{
    right: 13px;
    left: inherit;
}
.container-donate .icon-channel{
    width:40px;
    margin-right:20px;
}
.container-donate hr{
    border-top: 1px solid var(--mono-gray6);
}


.wrap-auth-login{
    grid-template-columns: 2fr 8fr;
}

.wrap-auth-login img{
    height:65px;
    width:auto;
    border-radius:50%;
    margin-right:5px;
}
.block-rek{
    background: #E8F7FF;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    margin-top: 15px;
    padding:5px 15px;
}
.block-rek .rek{
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #007DBE;
    padding: 5px 10px;
}
.block-rek img{
    width:80%;
}

.container-donate .payment-group{
    background-color: var(--mono-gray6);
    padding:20px;
    padding-bottom: 10px;
}

.container-donate .item-pay-type{
    padding:20px;
    padding-top:0px;
    padding-bottom: 0px;
}

.item-pay-type .md-radio label:after{
    top:23px;
}

.float-btn-donate{
    margin:0px;
    grid-template-columns:1fr 11fr;
    grid-column-gap: 10px;
}


.float-btn-donate .btn:first-child {
    width: 100%;
}

.info-form .txt-notice {
    color: #111827;
}

.txt-digits {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #9CA3AF;
}

.ic-info {
    position: absolute;
    margin-bottom: 20px;
    margin-top: 3px;
}
.donate-main .box-nominal {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 12px;
    grid-gap: 12px;
    gap: 12px;
    border: 1px solid #D1D5DB;
    border-radius: 8px;
    flex: none;
    order: 2;
    align-self: stretch;
    flex-grow: 0;
    margin-top: 12px;
}
.donate-main img.ic-right {
    padding-top: 5px;
    float: right !important;
    right: 40px;
    position: absolute;
    width: 7.2px;
    color: #fff;
}
.donate-main .ct-title {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #6B7280;
}
.donate-main .box-nominal .ct-title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #374151;
}
.donate-main .container-donate .wrap-input-amount > div:first-child {
    border-right: unset;
}
.donate-main .txt-subtitle-m {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600 !important;
    font-size: 16px;
    line-height: 24px;
    color: #374151;
}
.campaign-card-image .center-cropped {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}
.donate-main .container-donate .wrap-input-amount {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
}
.txt-three-digit {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #9CA3AF;
}
.donate-tbt .accordion__item {
    border-left: unset;
    border-right: unset;
    border-top: unset;
    border-bottom: 1px solid var(--mono-gray5);
}
.donate-tbt .accordion__button {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #6B7280;
    padding: 15px 0 15px 0;
}
.txt-cara-pembayaran {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #374151;
}
.txt-copy {
    background: #FEECD0;
    border-radius: 16px;
    width: 52px;
    padding: 2px 12px;
    grid-gap: 10px;
    gap: 10px;
    right: 33px;
    float: right;
    position: absolute;
    font-weight: 500 !important;
    font-size: 12px;
    line-height: 16px;
    color: #F9A215;
}
.container-donate .payment-group.ct-title.txt-body-m.fw-6 {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #374151;
    background: #fff;
}
.md-radio.box-new-payment label:before, .md-radio label:after {
    display: none;
}
.box-new-payment img.ic-right {
    float: right;
    right: 0;
    position: absolute;
}
.container-donate .md-radio.box-new-payment label {
    padding: 0px;
}
.donate-tbt .form-hook .form-group label, .error-input {
    margin-left: unset;
}
.txt-ket {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px !important;
    line-height: 20px !important;
    text-align: center;
    color: #6B7280 !important;
    margin-bottom: 10px;
    margin-top: 10px;
}
.txt-thanks {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: #374151 !important;
    margin-top: 17px;
}
.txt-program {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px !important;
    line-height: 20px !important;
    color: #6B7280 !important;
}
.color-bold {
    color: #374151 !important;
}
.txt-expired {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #6B7280;
}
.animate__pulse-red{
    /* width: 100%;
    height: 100%; */
    -webkit-animation: pulse 1s infinite;
            animation: pulse 1s infinite;
    border-radius: 50%;
    box-shadow: 0 0 0 0 #ffffff;
    color: #F59E0B;
    background: #fff;
    height: 18px;
    width: 18px;
}
.box-nominal-donasi {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1fr 1fr;
}
.box-payment {
    cursor: pointer;
    margin-top: 2px;
    display: flex;
    padding: 8px 12px;
    align-items: center;
    grid-gap: 12px;
    gap: 12px;
    align-self: stretch;
    border-radius: 6px;
    border: 1px solid #E5E7EB;
    border: 1px solid var(--neutral-n-200, #E5E7EB);
    background: #FFF;
    background: var(--generic-white, #FFF);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}  
.box-payment .ct-body {
    flex: 1 0;
    color: #374151;
    color: var(--neutral-n-700, #374151);
    font-family: Rubik;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
}
.suggest-donate.new .amount{
    font-family: Rubik !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 16px !important;
}
.donate-main .container {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
}
  @-webkit-keyframes pulse {
    0% {
      background-color: #001F3F;
    }
    100% {
      background-color: #FF4136;
    }
  }
  @keyframes pulse {
    0% {
      background-color: #001F3F;
    }
    100% {
      background-color: #FF4136;
    }
  }
.container-donate .section{
    padding: 15px 20px;
}
.container-donate form{
    margin-top:0px;
    margin-bottom:30px;
}
.container-donate .widget-campaign{
    display:grid;
    grid-template-columns: 1fr 11fr;
    grid-column-gap: 16px;
}
.container-donate .widget-campaign .campaigner img{
    margin-left:3px;
}
.container-donate .campaign-card-image .center-cropped{
    width: 54px;
    height: 38px;
}
.container-donate .suggest-donate{
    border: 1px solid var(--mono-gray5);
    padding: 12px 16px;
    border-radius: 8px;
    cursor:pointer;
    margin-bottom: 10px;
}
.container-donate .suggest-donate > div{
    grid-template-columns: 5fr 7fr;
}

.container-donate .suggest-donate .amount{
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
}

.container-donate .suggest-donate:hover .amount{
    color:#212529;
}
.container-donate .sd-active .amount{
    color:var(--mono-gray7);
}

.container-donate .suggest-donate .desc{
    background: var(--mono-gray6);
    border-radius: 8px;
    padding:0px 10px;
    color:var(--primary1);
    margin-top:5px;
    text-align: left;
}
.container-donate .sd-active .desc{
    background: #ffffff;
}

.container-donate .suggest-donate:hover{
    background: #ffe4c3;
    border: 1px solid #ffe4c3;
}

.container-donate .sd-active{
    background: var(--primary1);
    border: 1px solid var(--primary1);
}

.container-donate .wrap-input-amount{
    grid-template-columns: 1fr 10fr;
    background: var(--mono-gray6);
    border:1px solid var(--mono-gray4);
    border-radius: 8px;
    font-weight: 600;
}
.container-donate .wrap-input-amount > div:first-child{
    border-right:1px solid var(--mono-gray4);
}
.container-donate .wrap-input-amount div{
    padding:10px 16px;

}
.container-donate .wrap-input-amount input{
    width: 100%;
    outline: none;
    border: none;
    background: none;
    box-shadow: none !important;
    text-align: right;
    font-weight: 600;
    border-radius: 0px;
    padding: 0px;
    font-size: 20px;
}
.container-donate .styled-checkbox + label{
    margin-left:45px !important;
}

.container-donate .styled-checkbox + label:before{
    top:0px;
}
.container-donate .styled-checkbox:checked + label:after{
    top:7px;
}

/* payment channel */
.container-donate .md-radio label{
    width:100%;
    padding:10px;
    display: flex;
    align-items: center;
}
.container-donate .md-radio label:before{
    right:10px;
    left:inherit;
}
.container-donate .md-radio label:after{
    right: 13px;
    left: inherit;
}
.container-donate .icon-channel{
    width:40px;
    margin-right:20px;
}
.container-donate hr{
    border-top: 1px solid var(--mono-gray6);
}


.wrap-auth-login{
    grid-template-columns: 2fr 8fr;
}

.wrap-auth-login img{
    height:65px;
    width:auto;
    border-radius:50%;
    margin-right:5px;
}
.block-rek{
    background: #E8F7FF;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    margin-top: 15px;
    padding:5px 15px;
}
.block-rek .rek{
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #007DBE;
    padding: 5px 10px;
}
.block-rek img{
    width:80%;
}

.container-donate .payment-group{
    background-color: var(--mono-gray6);
    padding:20px;
    padding-bottom: 10px;
}

.container-donate .item-pay-type{
    padding:20px;
    padding-top:0px;
    padding-bottom: 0px;
}

.item-pay-type .md-radio label:after{
    top:23px;
}

.float-btn-donate{
    margin:0px;
    grid-template-columns:1fr 11fr;
    grid-column-gap: 10px;
}


.float-btn-donate .btn:first-child {
    width: 100%;
}

.donate-tbt {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #6B7280;
}
.donate-tbt hr {
    border-top: 1px solid #E5E7EB;
    margin-top: 12px;
    margin-bottom: 12px;
}

.donate-tbt .form-hook .form-group label, .error-input {
    font-style: normal;
    font-size: 14px;
    font-weight: 400;
    color: #6B7280;
    line-height: 20px;
}
.donate-tbt textarea.form-control {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #374151;
}
.donate-tbt textarea.form-control::-webkit-input-placeholder {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #9CA3AF;
}
.donate-tbt textarea.form-control:-ms-input-placeholder {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #9CA3AF;
}
.donate-tbt textarea.form-control::-ms-input-placeholder {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #9CA3AF;
}
.donate-tbt textarea.form-control::placeholder {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #9CA3AF;
}

.donate-tbt .txt-title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #374151;
}
.donate-tbt .txt-donasi {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    display: flex;
    align-items: center;
    color: #374151;
}
.donate-tbt .txt-checkbox {
    margin-top: 7px;
}
.donate-tbt input.form-control {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #374151;
}
.donate-tbt input.form-control::-webkit-input-placeholder {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #9CA3AF;
}
.donate-tbt input.form-control:-ms-input-placeholder {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #9CA3AF;
}
.donate-tbt input.form-control::-ms-input-placeholder {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #9CA3AF;
}
.donate-tbt input.form-control::placeholder {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #9CA3AF;
}
.donate-tbt .txt-body {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #6B7280;
}
.donate-tbt .txt-subtitle {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #6B7280;
}
.donate-tbt .box-nominal {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 12px;
    grid-gap: 12px;
    gap: 12px;
    border: 1px solid #D1D5DB;
    border-radius: 8px;
    flex: none;
    order: 2;
    align-self: stretch;
    flex-grow: 0;
    margin-top: 12px;
}
.donate-tbt .container-donate .wrap-input-amount > div:first-child {
    border-right: unset;
}
.donate-tbt .sum-donate {
    display: flex;
    width: 100%;
}
.donate-tbt .box-operasional h5 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #374151;
    padding: 15px 0 15px 0;
}
.donate-tbt .box-operasional .section-nominal {
    display: flex;
    margin-bottom: 22px;
}
.donate-tbt .box-operasional .txt-nominal {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #F9A215;
}
.donate-tbt .btn-ganti {
    flex-direction: row;
    align-items: flex-start;
    padding: 2px 12px;
    grid-gap: 10px;
    grid-gap: 10px;
    gap: 10px;
    width: 55px;
    height: 24px;
    background: #FEECD0;
    border-radius: 16px;
    float: right;
    right: 20px;
    position: absolute;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #F9A215;
    border: none;
    cursor: pointer;
}
.donate-tbt .box-channel {
    background: #FFFFFF;
    border: 1px solid #F3F4F6;
    border-radius: 4px;
    padding: 4px 8px;
    width: 44px;
    margin-right: 10px;
}
.donate-tbt .box-channel .icon-channel {
    width: 28px;
    height: auto;
    margin-right: 20px;
}
.donate-tbt .box-operasional .link-operasi {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #F9A215;
    cursor: pointer;
}
.box-donate-operasional {
    border: 1px solid #E5E7EB;
    border-radius: 8px;
}
.box-donate-operasional .row {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 16px;
    padding-bottom: 6px;
}
.box-donate-operasional .suggest-donate {
    flex-direction: row;
    justify-content: center;
    padding: 10px 20px;
    grid-gap: 8px;
    gap: 8px;
    background: #F9A215;
    border: unset;
    border-radius: 8px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
}
.box-donate-operasional .suggest-donate:hover {
    padding: 10px 20px;
    grid-gap: 8px;
    gap: 8px;
    background: #F9A215;
    border: unset;
    color: #FFFFFF;
}
.box-donate-operasional .field-donatur-amount {
    background: #FFFFFF;
    border: 1px solid #D1D5DB;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
}
.container-donate .box-donate-operasional .suggest-donate:hover .amount {
    color: #FFFFFF;
}
.container-donate .box-donate-operasional hr {
    border-top: 1px solid #E5E7EB;
    margin: unset;
}
.box-donate-operasional .txt-caption {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #6B7280;
}
.donate-tbt .sum-donate .text-donate{
    width: 50%;
    padding: 8px 0 0 12px;
}
.donate-tbt .sum-donate p {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 1px;
    color: #6B7280;
}
.donate-tbt .sum-donate h5 {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 14px;
    color: #374151;
}
.donate-tbt .custom-switch .custom-control-label::before {
    background-color: #E5E7EB !important;
    border-radius: 64px !important;
    width: 44px !important;
    height: 24px !important;
    border: none !important;
}
.donate-tbt .custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border: none !important;
    background-color: #E53577 !important;
}
.donate-tbt .custom-switch .custom-control-label::after {
    background: #FFFFFF !important;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05) !important;
    width: 21px !important;
    height: 21px !important;
    border-radius: 50%;
    top: calc(0.21rem + 2px);
}
.donate-tbt .custom-switch .custom-control-input:checked~.custom-control-label::after {
    left: calc(-1.7rem + 2px);
}
.donate-tbt .custom-control.custom-switch {
    right: 30px;
    float: right;
    position: absolute;
}
.donate-tbt .accordion-donasi {
    margin: unset;
}
.donate-tbt .accordion-donasi p{
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #6B7280;
}
.donate-tbt .accordion-donasi .accordion-body{
    width: 100%;
    margin: 12px 0 12px 0;
    border-bottom: 1px solid #E5E7EB;
}
.donate-tbt .accordion-donasi .accordion-body:last-child{
    border-bottom: unset;
    margin: unset;
}
.donate-tbt .accordion-donasi button{
    background: none;
    display: flex;
    border: none;
}
.donate-tbt .accordion-donasi .collapse:not(.show) {
    display: block;
}
.donate-tbt .accordion-donasi .collapse:not(.show) .arrow-down{
    display: block;
    margin: -25px 0 15px 105px;
}
.donate-tbt .accordion-donasi .collapse:not(.show) .arrow-up{
    display: none;
}
.donate-tbt .accordion-donasi .collapse:not(.show) .accordion-body {
    display: none;
}
.donate-tbt .accordion-donasi .collapse.show .arrow-up{
    display: block;
    margin: -25px 0 15px 105px;
}
.donate-tbt .accordion-donasi .collapsing .arrow-up{
    display: block;
    margin: -25px 0 15px 105px;
}
.donate-tbt .accordion-donasi .collapse.show .arrow-down{
    display: none;
}
.donate-tbt .accordion-donasi .collapsing .arrow-down{
    display: none;
}
.donate-tbt .accordion-donasi .collapsing .accordion-body{
    display: flex;
}
.donate-tbt .accordion-donasi .collapse.show .accordion-body{
    display: flex;
}
.donate-tbt .accordion-donasi .accordion-body .txt-donasi{
    position: absolute;
    float: right;
    right: 25px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    text-align: right;
    color: #6B7280;

}
.donate-tbt .btn-ganti.section-accordion {
    top: 125px;
}
.donate-tbt .txt-date-payment {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #F9A215;
}
.donate-tbt .float-btn-donate .btn{
    width: 100% !important;
    text-align: center;
}
.donate-tbt .float-btn-donate .btn-primary:hover{
    border: unset;
}
.donate-tbt .float-btn-donate .btn-secondary:hover{
    background: #FFFFFF;
}
.donate-tbt .float-btn-donate .btn-secondary {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    color: #F9A215;
}
.donate-tbt .txt-short-desc {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #9CA3AF;
}
.donate-tbt .content-transfer {
    display: flex;
    margin-bottom: 20px;
}
.donate-tbt .content-transfer .icon-channel {
    background: #FFFFFF;
    border: 1px solid #F3F4F6;
    border-radius: 4px;
    padding: 4px 8px;
    width: 44px;
    margin-right: unset;
}
.donate-tbt .content-transfer p {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #374151;
    margin-top: 8px;
    margin-bottom: 0;
}
.donate-tbt .content-transfer img {
    float: right;
    right: 20px;
    position: absolute;
}
.donate-tbt span.txt-subtitle.fw-7.ct-title {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #374151;
}
.donate-tbt .nominal-donate {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #374151;
}
.donate-tbt .top-logo {
    text-align: left;
    background: #F3F4F6;
}
.donate-tbt img.loader-img {
    margin: -20px 0 0 0;
}
.modal-operasi .txt-title {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #374151;
}
.modal-operasi .modal-body p {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #6B7280;
}

.container-donate .section{
    padding: 15px 20px;
}
.container-donate form{
    margin-top:0px;
    margin-bottom:30px;
}
.container-donate .widget-campaign{
    display:grid;
    grid-template-columns: 1fr 11fr;
    grid-column-gap: 16px;
}
.container-donate .widget-campaign .campaigner img{
    margin-left:3px;
}
.container-donate .campaign-card-image .center-cropped{
    width: 54px;
    height: 38px;
}
.container-donate .suggest-donate{
    border: 1px solid var(--mono-gray5);
    padding: 12px 16px;
    border-radius: 8px;
    cursor:pointer;
    margin-bottom: 10px;
}
.container-donate .suggest-donate > div{
    grid-template-columns: 5fr 7fr;
}

.container-donate .suggest-donate .amount{
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
}

.container-donate .suggest-donate:hover .amount{
    color:#212529;
}
.container-donate .sd-active .amount{
    color:var(--mono-gray7);
}

.container-donate .suggest-donate .desc{
    background: var(--mono-gray6);
    border-radius: 8px;
    padding:0px 10px;
    color:var(--primary1);
    margin-top:5px;
    text-align: left;
}
.container-donate .sd-active .desc{
    background: #ffffff;
}

.container-donate .suggest-donate:hover{
    background: #ffe4c3;
    border: 1px solid #ffe4c3;
}

.container-donate .sd-active{
    background: var(--primary1);
    border: 1px solid var(--primary1);
}

.container-donate .wrap-input-amount{
    grid-template-columns: 1fr 10fr;
    background: var(--mono-gray6);
    border:1px solid var(--mono-gray4);
    border-radius: 8px;
    font-weight: 600;
}
.container-donate .wrap-input-amount > div:first-child{
    border-right:1px solid var(--mono-gray4);
}
.container-donate .wrap-input-amount div{
    padding:10px 16px;

}
.container-donate .wrap-input-amount input{
    width: 100%;
    outline: none;
    border: none;
    background: none;
    box-shadow: none !important;
    text-align: right;
    font-weight: 600;
    border-radius: 0px;
    padding: 0px;
    font-size: 20px;
}
.container-donate .styled-checkbox + label{
    margin-left:45px !important;
}

.container-donate .styled-checkbox + label:before{
    top:0px;
}
.container-donate .styled-checkbox:checked + label:after{
    top:7px;
}

/* payment channel */
.container-donate .md-radio label{
    width:100%;
    padding:10px;
    display: flex;
    align-items: center;
}
.container-donate .md-radio label:before{
    right:10px;
    left:inherit;
}
.container-donate .md-radio label:after{
    right: 13px;
    left: inherit;
}
.container-donate .icon-channel{
    width:40px;
    margin-right:20px;
}
.container-donate hr{
    border-top: 1px solid var(--mono-gray6);
}


.wrap-auth-login{
    grid-template-columns: 2fr 8fr;
}

.wrap-auth-login img{
    height:65px;
    width:auto;
    border-radius:50%;
    margin-right:5px;
}
.block-rek{
    background: #E8F7FF;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    margin-top: 15px;
    padding:5px 15px;
}
.block-rek .rek{
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #007DBE;
    padding: 5px 10px;
}
.block-rek img{
    width:80%;
}

.container-donate .payment-group{
    background-color: var(--mono-gray6);
    padding:20px;
    padding-bottom: 10px;
}

.container-donate .item-pay-type{
    padding:20px;
    padding-top:0px;
    padding-bottom: 0px;
}

.item-pay-type .md-radio label:after{
    top:23px;
}

.float-btn-donate{
    margin:0px;
    grid-template-columns:1fr 11fr;
    grid-column-gap: 10px;
}


.float-btn-donate .btn:first-child {
    width: 100%;
}

.donate-tbt {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #6B7280;
}
.donate-tbt hr {
    border-top: 1px solid #E5E7EB;
    margin-top: 12px;
    margin-bottom: 12px;
}

.donate-tbt .form-hook .form-group label, .error-input {
    font-style: normal;
    font-size: 14px;
    font-weight: 400;
    color: #6B7280;
    line-height: 20px;
}
.donate-tbt textarea.form-control {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #374151;
}
.donate-tbt textarea.form-control::-webkit-input-placeholder {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #9CA3AF;
}
.donate-tbt textarea.form-control:-ms-input-placeholder {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #9CA3AF;
}
.donate-tbt textarea.form-control::-ms-input-placeholder {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #9CA3AF;
}
.donate-tbt textarea.form-control::placeholder {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #9CA3AF;
}

.donate-tbt .txt-title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #374151;
}
.donate-tbt .txt-donasi {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    display: flex;
    align-items: center;
    color: #374151;
}
.donate-tbt .txt-checkbox {
    margin-top: 7px;
}
.donate-tbt input.form-control {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #374151;
}
.donate-tbt input.form-control::-webkit-input-placeholder {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #9CA3AF;
}
.donate-tbt input.form-control:-ms-input-placeholder {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #9CA3AF;
}
.donate-tbt input.form-control::-ms-input-placeholder {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #9CA3AF;
}
.donate-tbt input.form-control::placeholder {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #9CA3AF;
}
.donate-tbt .txt-body {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #6B7280;
}
.donate-tbt .txt-subtitle {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #6B7280;
}
.donate-tbt .box-nominal {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 12px;
    grid-gap: 12px;
    gap: 12px;
    border: 1px solid #D1D5DB;
    border-radius: 8px;
    flex: none;
    order: 2;
    align-self: stretch;
    flex-grow: 0;
    margin-top: 12px;
}
.donate-tbt .container-donate .wrap-input-amount > div:first-child {
    border-right: unset;
}
.donate-tbt .sum-donate {
    display: flex;
    width: 100%;
}
.donate-tbt .box-operasional h5 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #374151;
    padding: 15px 0 15px 0;
}
.donate-tbt .box-operasional .section-nominal {
    display: flex;
    margin-bottom: 22px;
}
.donate-tbt .box-operasional .txt-nominal {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #F9A215;
}
.donate-tbt .btn-ganti {
    flex-direction: row;
    align-items: flex-start;
    padding: 2px 12px;
    grid-gap: 10px;
    grid-gap: 10px;
    gap: 10px;
    width: 55px;
    height: 24px;
    background: #FEECD0;
    border-radius: 16px;
    float: right;
    right: 20px;
    position: absolute;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #F9A215;
    border: none;
    cursor: pointer;
}
.donate-tbt .box-channel {
    background: #FFFFFF;
    border: 1px solid #F3F4F6;
    border-radius: 4px;
    padding: 4px 8px;
    width: 44px;
    margin-right: 10px;
}
.donate-tbt .box-channel .icon-channel {
    width: 28px;
    height: auto;
    margin-right: 20px;
}
.donate-tbt .box-operasional .link-operasi {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #F9A215;
    cursor: pointer;
}
.box-donate-operasional {
    border: 1px solid #E5E7EB;
    border-radius: 8px;
}
.box-donate-operasional .row {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 16px;
    padding-bottom: 6px;
}
.box-donate-operasional .suggest-donate {
    flex-direction: row;
    justify-content: center;
    padding: 10px 20px;
    grid-gap: 8px;
    gap: 8px;
    background: #F9A215;
    border: unset;
    border-radius: 8px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
}
.box-donate-operasional .suggest-donate:hover {
    padding: 10px 20px;
    grid-gap: 8px;
    gap: 8px;
    background: #F9A215;
    border: unset;
    color: #FFFFFF;
}
.box-donate-operasional .field-donatur-amount {
    background: #FFFFFF;
    border: 1px solid #D1D5DB;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
}
.container-donate .box-donate-operasional .suggest-donate:hover .amount {
    color: #FFFFFF;
}
.container-donate .box-donate-operasional hr {
    border-top: 1px solid #E5E7EB;
    margin: unset;
}
.box-donate-operasional .txt-caption {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #6B7280;
}
.donate-tbt .sum-donate .text-donate{
    width: 50%;
    padding: 8px 0 0 12px;
}
.donate-tbt .sum-donate p {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 1px;
    color: #6B7280;
}
.donate-tbt .sum-donate h5 {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 14px;
    color: #374151;
}
.donate-tbt .custom-switch .custom-control-label::before {
    background-color: #E5E7EB !important;
    border-radius: 64px !important;
    width: 44px !important;
    height: 24px !important;
    border: none !important;
}
.donate-tbt .custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border: none !important;
    background-color: #E53577 !important;
}
.donate-tbt .custom-switch .custom-control-label::after {
    background: #FFFFFF !important;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05) !important;
    width: 21px !important;
    height: 21px !important;
    border-radius: 50%;
    top: calc(0.21rem + 2px);
}
.donate-tbt .custom-switch .custom-control-input:checked~.custom-control-label::after {
    left: calc(-1.7rem + 2px);
}
.donate-tbt .custom-control.custom-switch {
    right: 30px;
    float: right;
    position: absolute;
}
.donate-tbt .accordion-donasi {
    margin: unset;
}
.donate-tbt .accordion-donasi p{
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #6B7280;
}
.donate-tbt .accordion-donasi .accordion-body{
    width: 100%;
    margin: 12px 0 12px 0;
    border-bottom: 1px solid #E5E7EB;
}
.donate-tbt .accordion-donasi .accordion-body:last-child{
    border-bottom: unset;
    margin: unset;
}
.donate-tbt .accordion-donasi button{
    background: none;
    display: flex;
    border: none;
}
.donate-tbt .accordion-donasi .collapse:not(.show) {
    display: block;
}
.donate-tbt .accordion-donasi .collapse:not(.show) .arrow-down{
    display: block;
    margin: -25px 0 15px 105px;
}
.donate-tbt .accordion-donasi .collapse:not(.show) .arrow-up{
    display: none;
}
.donate-tbt .accordion-donasi .collapse:not(.show) .accordion-body {
    display: none;
}
.donate-tbt .accordion-donasi .collapse.show .arrow-up{
    display: block;
    margin: -25px 0 15px 105px;
}
.donate-tbt .accordion-donasi .collapsing .arrow-up{
    display: block;
    margin: -25px 0 15px 105px;
}
.donate-tbt .accordion-donasi .collapse.show .arrow-down{
    display: none;
}
.donate-tbt .accordion-donasi .collapsing .arrow-down{
    display: none;
}
.donate-tbt .accordion-donasi .collapsing .accordion-body{
    display: flex;
}
.donate-tbt .accordion-donasi .collapse.show .accordion-body{
    display: flex;
}
.donate-tbt .accordion-donasi .accordion-body .txt-donasi{
    position: absolute;
    float: right;
    right: 25px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    text-align: right;
    color: #6B7280;

}
.donate-tbt .btn-ganti.section-accordion {
    top: 125px;
}
.donate-tbt .txt-date-payment {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #F9A215;
}
.donate-tbt .float-btn-donate .btn{
    width: 100% !important;
    text-align: center;
}
.donate-tbt .float-btn-donate .btn-primary:hover{
    border: unset;
}
.donate-tbt .float-btn-donate .btn-secondary:hover{
    background: #FFFFFF;
}
.donate-tbt .float-btn-donate .btn-secondary {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    color: #F9A215;
}
.donate-tbt .txt-short-desc {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #9CA3AF;
}
.donate-tbt .content-transfer {
    display: flex;
    margin-bottom: 20px;
}
.donate-tbt .content-transfer .icon-channel {
    background: #FFFFFF;
    border: 1px solid #F3F4F6;
    border-radius: 4px;
    padding: 4px 8px;
    width: 44px;
    margin-right: unset;
}
.donate-tbt .content-transfer p {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #374151;
    margin-top: 8px;
    margin-bottom: 0;
}
.donate-tbt .content-transfer img {
    float: right;
    right: 20px;
    position: absolute;
}
.donate-tbt span.txt-subtitle.fw-7.ct-title {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #374151;
}
.donate-tbt .nominal-donate {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #374151;
}
.donate-tbt .top-logo {
    text-align: left;
    background: #F3F4F6;
}
.donate-tbt .content-space {
    border-bottom: 8px solid #F2F2F2;
}
.donate-tbt .content-space::before {
    box-shadow: unset;
}
.donate-tbt .txt-green {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #22C55E;
    grid-gap: 10px;
    gap: 10px;
    padding: 2px 4px;
    background: #DCFCE7;
}
.donate-tbt .item-salur-type {
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 8px;
    height: 64px;
    border: 1px solid #E5E7EB;
    border-radius: 8px;
}
.donate-tbt .item-salur-type.active {
    border: 1px solid #F9A215;
}
.donate-tbt .item-salur-type .md-radio label {
    width: 100%;
    padding: 10px;
    display: block;
    margin: -15px 0 12px 0;
    align-items: center;
}
.donate-tbt .item-salur-type .md-radio label:before {
    top: 22px;
}
.donate-tbt .item-salur-type .md-radio label:after {
    top: 25px;
}
.donate-tbt.donate-beras .wrap-input-amount {
    display: flex;
    width: 100%;
    background: #F3F4F6;
    border: 1px solid #F3F4F6;
}
.donate-tbt.donate-beras .wrap-input-amount input {
    text-align: left;
}
.donate-tbt.donate-beras .wrap-input-amount > div:first-child{
    text-align: right;
    width: 100%;
}
.donate-tbt .info-form {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #6B7280;
    background: #FFEFBC;
    border-radius: 8px;
    padding: 8px;
    justify-content: start;
    border: 1px solid #FDDAA1;
}
.donate-tbt img.ic-info {
    position: absolute;
}
.txt-type-salur {
    text-align: left !important;
}
.modal-settingtime .txt-title {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #374151;
}
.modal-settingtime .modal-body p {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #6B7280;
}
.modal-settingtime .tab-settingtime {
    display: block;
    float: left;
    width: 50%;
}
.tab-settingtime a{
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}
.tab-settingtime.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link {
    color: #9CA3AF;
    background: #FFFFFF;
    border: unset;
}
.tab-settingtime.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #111827;
    background: #F3F4F6;
    border: unset;
}
.modal-settingtime .modal-content .modal-body > div {
    border-bottom: unset;
    padding: 8px 10px;
}
.donate-tbt .container-donate.container {
    padding: 16px 20px;
}
.donate-tbt.checkout-beras {
    font-size: 14px;
}
.donate-tbt.checkout-beras .box-bordered p {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin: unset;
    color: #6B7280;
    max-width: 235px;
}
.donate-tbt.checkout-beras .img-banner {
    margin-top: 12px;
    margin-bottom: 12px;
}
.txt-short-desc .txt-time {
    color: #374151;
}
.donate-tbt .sec-setting-time {
    display: block;
    padding: 8px 12px;
    grid-gap: 12px;
    gap: 12px;
    border: 1px solid #E5E7EB;
    border-radius: 8px;
}
.donate-tbt .sec-setting-time .txt-time {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
}
.btn-link {
    cursor: pointer;
    text-decoration: unset !important;
}
.donate-tbt .error-input {
    color: var(--red);
}
.donate-tbt .form-penyaluran {
    padding: 16px 20px;
}
.donate-tbt .form-penyaluran .form-hook .form-group label {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #111827;
    margin: 4px 0;
}
.radio-settingtime {
    text-align: left;
    color: #9CA3AF;
    padding: 0 10px;
}
.radio-settingtime.active {
    background: #F3F4F6;
    color: #111827;
}
.radio-settingtime input {
    display: none;
}
.radio-settingtime label {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    padding: 8px;
    margin: unset;
    cursor: pointer;
}
.radio-settingtime label.disabled {
    color: #FCA5A5;
}
.modal-settingtime .row{
    padding: 20px;
}
.modal-settingtime .grid-settingtime {
    width: 50%;
}
.modal-settingtime .grid-settingtime p {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #9CA3AF;
    margin: 0 20px 20px 20px;
}
.modal-settingtime button.btn.btn-block {
    padding: 10px 24px;
    border-radius: 8px;
}
.modal-settingtime button.btn.btn-secondary.btn-block {
    border: 1px solid rgba(49, 53, 59, 0.12);
    color: rgba(49, 53, 59, 0.96);
}
.modal-settingtime button.btn.btn-primary.btn-block {
    background: #F9A215;
}
.donate-tbt.checkout-beras .bg-copy {
    background: #FEECD0;
    border-radius: 16px;
    float: right;
    padding: 2px 12px;
    grid-gap: 10px;
    gap: 10px;
}


.container-donate .section{
    padding: 15px 20px;
}
.container-donate form{
    margin-top:0px;
    margin-bottom:30px;
}
.container-donate .widget-campaign{
    display:grid;
    grid-template-columns: 1fr 11fr;
    grid-column-gap: 16px;
}
.container-donate .widget-campaign .campaigner img{
    margin-left:3px;
}
.container-donate .campaign-card-image .center-cropped{
    width: 54px;
    height: 38px;
}
.container-donate .suggest-donate{
    border: 1px solid var(--mono-gray5);
    padding: 12px 16px;
    border-radius: 8px;
    cursor:pointer;
    margin-bottom: 10px;
}
.container-donate .suggest-donate > div{
    grid-template-columns: 5fr 7fr;
}

.container-donate .suggest-donate .amount{
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
}

.container-donate .suggest-donate:hover .amount{
    color:#212529;
}
.container-donate .sd-active .amount{
    color:var(--mono-gray7);
}

.container-donate .suggest-donate .desc{
    background: var(--mono-gray6);
    border-radius: 8px;
    padding:0px 10px;
    color:var(--primary1);
    margin-top:5px;
    text-align: left;
}
.container-donate .sd-active .desc{
    background: #ffffff;
}

.container-donate .suggest-donate:hover{
    background: #ffe4c3;
    border: 1px solid #ffe4c3;
}

.container-donate .sd-active{
    background: var(--primary1);
    border: 1px solid var(--primary1);
}

.container-donate .wrap-input-amount{
    grid-template-columns: 1fr 10fr;
    background: var(--mono-gray6);
    border:1px solid var(--mono-gray4);
    border-radius: 8px;
    font-weight: 600;
}
.container-donate .wrap-input-amount > div:first-child{
    border-right:1px solid var(--mono-gray4);
}
.container-donate .wrap-input-amount div{
    padding:10px 16px;

}
.container-donate .wrap-input-amount input{
    width: 100%;
    outline: none;
    border: none;
    background: none;
    box-shadow: none !important;
    text-align: right;
    font-weight: 600;
    border-radius: 0px;
    padding: 0px;
    font-size: 20px;
}
.container-donate .styled-checkbox + label{
    margin-left:45px !important;
}

.container-donate .styled-checkbox + label:before{
    top:0px;
}
.container-donate .styled-checkbox:checked + label:after{
    top:7px;
}

/* payment channel */
.container-donate .md-radio label{
    width:100%;
    padding:10px;
    display: flex;
    align-items: center;
}
.container-donate .md-radio label:before{
    right:10px;
    left:inherit;
}
.container-donate .md-radio label:after{
    right: 13px;
    left: inherit;
}
.container-donate .icon-channel{
    width:40px;
    margin-right:20px;
}
.container-donate hr{
    border-top: 1px solid var(--mono-gray6);
}


.wrap-auth-login{
    grid-template-columns: 2fr 8fr;
}

.wrap-auth-login img{
    height:65px;
    width:auto;
    border-radius:50%;
    margin-right:5px;
}
.block-rek{
    background: #E8F7FF;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    margin-top: 15px;
    padding:5px 15px;
}
.block-rek .rek{
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #007DBE;
    padding: 5px 10px;
}
.block-rek img{
    width:80%;
}

.container-donate .payment-group{
    background-color: var(--mono-gray6);
    padding:20px;
    padding-bottom: 10px;
}

.container-donate .item-pay-type{
    padding:20px;
    padding-top:0px;
    padding-bottom: 0px;
}

.item-pay-type .md-radio label:after{
    top:23px;
}

.float-btn-donate{
    margin:0px;
    grid-template-columns:1fr 11fr;
    grid-column-gap: 10px;
}


.float-btn-donate .btn:first-child {
    width: 100%;
}

.donate-tbt {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #6B7280;
}
.donate-tbt hr {
    border-top: 1px solid #E5E7EB;
    margin-top: 12px;
    margin-bottom: 12px;
}

.donate-tbt .form-hook .form-group label, .error-input {
    font-style: normal;
    font-size: 14px;
    font-weight: 400;
    color: #6B7280;
    line-height: 20px;
}
.donate-tbt textarea.form-control {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #374151;
}
.donate-tbt textarea.form-control::-webkit-input-placeholder {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #9CA3AF;
}
.donate-tbt textarea.form-control:-ms-input-placeholder {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #9CA3AF;
}
.donate-tbt textarea.form-control::-ms-input-placeholder {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #9CA3AF;
}
.donate-tbt textarea.form-control::placeholder {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #9CA3AF;
}

.donate-tbt .txt-title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #374151;
}
.donate-tbt .txt-donasi {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    display: flex;
    align-items: center;
    color: #374151;
}
.donate-tbt .txt-checkbox {
    margin-top: 7px;
}
.donate-tbt input.form-control {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #374151;
}
.donate-tbt input.form-control::-webkit-input-placeholder {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #9CA3AF;
}
.donate-tbt input.form-control:-ms-input-placeholder {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #9CA3AF;
}
.donate-tbt input.form-control::-ms-input-placeholder {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #9CA3AF;
}
.donate-tbt input.form-control::placeholder {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #9CA3AF;
}
.donate-tbt .txt-body {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #6B7280;
}
.donate-tbt .txt-subtitle {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #6B7280;
}
.donate-tbt .box-nominal {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 12px;
    grid-gap: 12px;
    gap: 12px;
    border: 1px solid #D1D5DB;
    border-radius: 8px;
    flex: none;
    order: 2;
    align-self: stretch;
    flex-grow: 0;
    margin-top: 12px;
}
.donate-tbt .container-donate .wrap-input-amount > div:first-child {
    border-right: unset;
}
.donate-tbt .sum-donate {
    display: flex;
    width: 100%;
}
.donate-tbt .box-operasional h5 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #374151;
    padding: 15px 0 15px 0;
}
.donate-tbt .box-operasional .section-nominal {
    display: flex;
    margin-bottom: 22px;
}
.donate-tbt .box-operasional .txt-nominal {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #F9A215;
}
.donate-tbt .btn-ganti {
    flex-direction: row;
    align-items: flex-start;
    padding: 2px 12px;
    grid-gap: 10px;
    grid-gap: 10px;
    gap: 10px;
    width: 55px;
    height: 24px;
    background: #FEECD0;
    border-radius: 16px;
    float: right;
    right: 20px;
    position: absolute;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #F9A215;
    border: none;
    cursor: pointer;
}
.donate-tbt .box-channel {
    background: #FFFFFF;
    border: 1px solid #F3F4F6;
    border-radius: 4px;
    padding: 4px 8px;
    width: 44px;
    margin-right: 10px;
}
.donate-tbt .box-channel .icon-channel {
    width: 28px;
    height: auto;
    margin-right: 20px;
}
.donate-tbt .box-operasional .link-operasi {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #F9A215;
    cursor: pointer;
}
.box-donate-operasional {
    border: 1px solid #E5E7EB;
    border-radius: 8px;
}
.box-donate-operasional .row {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 16px;
    padding-bottom: 6px;
}
.box-donate-operasional .suggest-donate {
    flex-direction: row;
    justify-content: center;
    padding: 10px 20px;
    grid-gap: 8px;
    gap: 8px;
    background: #F9A215;
    border: unset;
    border-radius: 8px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
}
.box-donate-operasional .suggest-donate:hover {
    padding: 10px 20px;
    grid-gap: 8px;
    gap: 8px;
    background: #F9A215;
    border: unset;
    color: #FFFFFF;
}
.box-donate-operasional .field-donatur-amount {
    background: #FFFFFF;
    border: 1px solid #D1D5DB;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
}
.container-donate .box-donate-operasional .suggest-donate:hover .amount {
    color: #FFFFFF;
}
.container-donate .box-donate-operasional hr {
    border-top: 1px solid #E5E7EB;
    margin: unset;
}
.box-donate-operasional .txt-caption {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #6B7280;
}
.donate-tbt .sum-donate .text-donate{
    width: 50%;
    padding: 8px 0 0 12px;
}
.donate-tbt .sum-donate p {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 1px;
    color: #6B7280;
}
.donate-tbt .sum-donate h5 {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 14px;
    color: #374151;
}
.donate-tbt .custom-switch .custom-control-label::before {
    background-color: #E5E7EB !important;
    border-radius: 64px !important;
    width: 44px !important;
    height: 24px !important;
    border: none !important;
}
.donate-tbt .custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border: none !important;
    background-color: #E53577 !important;
}
.donate-tbt .custom-switch .custom-control-label::after {
    background: #FFFFFF !important;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05) !important;
    width: 21px !important;
    height: 21px !important;
    border-radius: 50%;
    top: calc(0.21rem + 2px);
}
.donate-tbt .custom-switch .custom-control-input:checked~.custom-control-label::after {
    left: calc(-1.7rem + 2px);
}
.donate-tbt .custom-control.custom-switch {
    right: 30px;
    float: right;
    position: absolute;
}
.donate-tbt .accordion-donasi {
    margin: unset;
}
.donate-tbt .accordion-donasi p{
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #6B7280;
}
.donate-tbt .accordion-donasi .accordion-body{
    width: 100%;
    margin: 12px 0 12px 0;
    border-bottom: 1px solid #E5E7EB;
}
.donate-tbt .accordion-donasi .accordion-body:last-child{
    border-bottom: unset;
    margin: unset;
}
.donate-tbt .accordion-donasi button{
    background: none;
    display: flex;
    border: none;
}
.donate-tbt .accordion-donasi .collapse:not(.show) {
    display: block;
}
.donate-tbt .accordion-donasi .collapse:not(.show) .arrow-down{
    display: block;
    margin: -25px 0 15px 105px;
}
.donate-tbt .accordion-donasi .collapse:not(.show) .arrow-up{
    display: none;
}
.donate-tbt .accordion-donasi .collapse:not(.show) .accordion-body {
    display: none;
}
.donate-tbt .accordion-donasi .collapse.show .arrow-up{
    display: block;
    margin: -25px 0 15px 105px;
}
.donate-tbt .accordion-donasi .collapsing .arrow-up{
    display: block;
    margin: -25px 0 15px 105px;
}
.donate-tbt .accordion-donasi .collapse.show .arrow-down{
    display: none;
}
.donate-tbt .accordion-donasi .collapsing .arrow-down{
    display: none;
}
.donate-tbt .accordion-donasi .collapsing .accordion-body{
    display: flex;
}
.donate-tbt .accordion-donasi .collapse.show .accordion-body{
    display: flex;
}
.donate-tbt .accordion-donasi .accordion-body .txt-donasi{
    position: absolute;
    float: right;
    right: 25px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    text-align: right;
    color: #6B7280;

}
.donate-tbt .btn-ganti.section-accordion {
    top: 125px;
}
.donate-tbt .txt-date-payment {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #F9A215;
}
.donate-tbt .float-btn-donate .btn{
    width: 100% !important;
    text-align: center;
}
.donate-tbt .float-btn-donate .btn-primary:hover{
    border: unset;
}
.donate-tbt .float-btn-donate .btn-secondary:hover{
    background: #FFFFFF;
}
.donate-tbt .float-btn-donate .btn-secondary {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    color: #F9A215;
}
.donate-tbt .txt-short-desc {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #9CA3AF;
}
.donate-tbt .content-transfer {
    display: flex;
    margin-bottom: 20px;
}
.donate-tbt .content-transfer .icon-channel {
    background: #FFFFFF;
    border: 1px solid #F3F4F6;
    border-radius: 4px;
    padding: 4px 8px;
    width: 44px;
    margin-right: unset;
}
.donate-tbt .content-transfer p {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #374151;
    margin-top: 8px;
    margin-bottom: 0;
}
.donate-tbt .content-transfer img {
    float: right;
    right: 20px;
    position: absolute;
}
.donate-tbt span.txt-subtitle.fw-7.ct-title {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #374151;
}
.donate-tbt .nominal-donate {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #374151;
}
.donate-tbt .top-logo {
    text-align: left;
    background: #F3F4F6;
}
.donate-tbt .content-space {
    border-bottom: 8px solid #F2F2F2;
}
.donate-tbt .content-space::before {
    box-shadow: unset;
}
.donate-tbt .txt-green {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #22C55E;
    grid-gap: 10px;
    gap: 10px;
    padding: 2px 4px;
    background: #DCFCE7;
}
.donate-tbt .item-salur-type {
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 8px;
    height: 64px;
    border: 1px solid #E5E7EB;
    border-radius: 8px;
}
.donate-tbt .item-salur-type.active {
    border: 1px solid #F9A215;
}
.donate-tbt .item-salur-type .md-radio label {
    width: 100%;
    padding: 10px;
    display: block;
    margin: -15px 0 12px 0;
    align-items: center;
}
.donate-tbt .item-salur-type .md-radio label:before {
    top: 22px;
}
.donate-tbt .item-salur-type .md-radio label:after {
    top: 25px;
}
.donate-tbt.donate-beras .wrap-input-amount {
    display: flex;
    width: 100%;
    background: #F3F4F6;
    border: 1px solid #F3F4F6;
}
.donate-tbt.donate-beras .wrap-input-amount input {
    text-align: left;
}
.donate-tbt.donate-beras .wrap-input-amount > div:first-child{
    text-align: right;
    width: 100%;
}
.donate-tbt .info-form {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #6B7280;
    background: #FFEFBC;
    border-radius: 8px;
    padding: 8px;
    justify-content: start;
    border: 1px solid #FDDAA1;
}
.donate-tbt img.ic-info {
    position: absolute;
}
.txt-type-salur {
    text-align: left !important;
}
.modal-settingtime .txt-title {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #374151;
}
.modal-settingtime .modal-body p {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #6B7280;
}
.modal-settingtime .tab-settingtime {
    display: block;
    float: left;
    width: 50%;
}
.tab-settingtime a{
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}
.tab-settingtime.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link {
    color: #9CA3AF;
    background: #FFFFFF;
    border: unset;
}
.tab-settingtime.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #111827;
    background: #F3F4F6;
    border: unset;
}
.modal-settingtime .modal-content .modal-body > div {
    border-bottom: unset;
    padding: 8px 10px;
}
.donate-tbt .container-donate.container {
    padding: 16px 20px;
}
.donate-tbt.checkout-beras {
    font-size: 14px;
}
.donate-tbt.checkout-beras .box-bordered p {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin: unset;
    color: #6B7280;
    max-width: 235px;
}
.donate-tbt.checkout-beras .img-banner {
    margin-top: 12px;
    margin-bottom: 12px;
}
.txt-short-desc .txt-time {
    color: #374151;
}
.donate-tbt .sec-setting-time {
    display: block;
    padding: 8px 12px;
    grid-gap: 12px;
    gap: 12px;
    border: 1px solid #E5E7EB;
    border-radius: 8px;
}
.donate-tbt .sec-setting-time .txt-time {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
}
.btn-link {
    cursor: pointer;
    text-decoration: unset !important;
}
.donate-tbt .error-input {
    color: var(--red);
}
.donate-tbt .form-penyaluran {
    padding: 16px 20px;
}
.donate-tbt .form-penyaluran .form-hook .form-group label {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #111827;
    margin: 4px 0;
}
.radio-settingtime {
    text-align: left;
    color: #9CA3AF;
    padding: 0 10px;
}
.radio-settingtime.active {
    background: #F3F4F6;
    color: #111827;
}
.radio-settingtime input {
    display: none;
}
.radio-settingtime label {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    padding: 8px;
    margin: unset;
    cursor: pointer;
}
.radio-settingtime label.disabled {
    color: #FCA5A5;
}
.modal-settingtime .row{
    padding: 20px;
}
.modal-settingtime .grid-settingtime {
    width: 50%;
}
.modal-settingtime .grid-settingtime p {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #9CA3AF;
    margin: 0 20px 20px 20px;
}
.modal-settingtime button.btn.btn-block {
    padding: 10px 24px;
    border-radius: 8px;
}
.modal-settingtime button.btn.btn-secondary.btn-block {
    border: 1px solid rgba(49, 53, 59, 0.12);
    color: rgba(49, 53, 59, 0.96);
}
.modal-settingtime button.btn.btn-primary.btn-block {
    background: #F9A215;
}
.donate-tbt.checkout-beras .bg-copy {
    background: #FEECD0;
    border-radius: 16px;
    float: right;
    padding: 2px 12px;
    grid-gap: 10px;
    gap: 10px;
}
.txt-medium {
    font-weight: 500;
    color: #374151;
}
.form-group .txt-desc {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #6B7280;
    margin-bottom: 4px;
}
.penerima-manfaat .group-btn {
    display: contents;
}
.penerima-manfaat .group-btn .first-btn {
    width: 45%;
    margin: 0 0 0 20px;
}
.penerima-manfaat .group-btn .second-btn {
    float: right;
    right: 0;
    position: absolute;
    width: 45%;
    margin: 0 20px 0 0;
}
.penerima-manfaat .btn-secondary {
    color: #6B7280;
    background: #FFFFFF;
    border: unset;
}
.penerima-manfaat .btn-secondary:hover, .btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active {
    color: #6B7280 !important;
    background: rgba(49, 53, 59, 0.04) !important;
    box-shadow: unset !important;
    border: unset !important;
}
.penerima-manfaat .content-info {
    text-align: center;
}
.penerima-manfaat .content-upload-image {
    text-align: center;
}
.penerima-manfaat .img-upload {
    margin-bottom: 8px;
}
.penerima-manfaat .content-upload-image .txt-caption {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    font-feature-settings: 'salt' on, 'liga' off;
    color: #F9A215;
    display: block;
}
.desc-penerima {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 12px;
    grid-gap: 12px;
    gap: 12px;
    background: #F3F4F6;
    border-radius: 8px;
    color: #6B7280;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}


.faq-header {
    padding: 20px;
    background: var(--primary3);
}
.faq-header .search-form {
    margin: 10px 0px;
    background: var(--mono-gray6);
    border-radius: 4px;
}
.faq-header .search-form-pointer .form-control {
    cursor: pointer;
}
.faq-header .search-form .form-label {
    display: none;
}
.faq-header .search-form .input-group {
    box-shadow: none !important;
    padding: 0px;
    border-radius: 4px;
}
.faq-header .search-form .form-control {
    height: 36px;
    font-size:12px;
    padding-left:0px;
}
.faq-header .search-form .form-control, 
.faq-header .search-form .input-group-prepend, 
.faq-header .search-form .input-group-text {
    background: var(--mono-gray7);
    border: none;
}
.faq-header .search-form .form-control:focus{
    outline: none;
    box-shadow: none;
}

.faq-search .cp-card {
    margin: 0px;
    border-radius: 0px;
}
.faq-search .cp-card:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}
.faq-search .cp-card:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}
.sample-img{
    width:188px;
    height:126px;
    background: #C4C4C4;
    margin:auto;
    margin-top:10px;
    margin-bottom:10px;
}

.splash-screen .result-donation{
    background: linear-gradient(360deg, #FFEED9 18.53%, rgba(255, 255, 255, 0) 100%);
}
.splash-screen .content-space::after{
    background:#ffefda;
    height:20px;
}
/* .splashscreen{
    padding:0px;
    background:#007DBE;
}
.splashscreen .navbar{
    background:#007DBE;
    box-shadow: none !important;
}

.splashscreen .btn{
    font-size:12px;
    font-weight: 600;
    padding:10px 20px;
    color:#007DBE;
}
.splashscreen .pray-text{
    margin-top: 20% !important;
    margin-bottom: 15% !important;
}

.splash-danger{
    background: #fbc3dc;
    color: #a94940 !important;
}

.splash-danger .text-white{
    color: #a94940 !important;
}

.splash-warning{
    background: #f8fbc3;
    color: #a98440  !important;
}
.splash-warning .text-white{
    color: #a98440  !important;
} */

.setting-preview-card-profile {
    border-radius: 4px;
    display: grid;
    grid-template-columns: 1fr 9fr;
    grid-gap: 16px;
    align-items:center;
}
.setting-preview-card-profile img {
    width:48px;
    height:48px;
    border-radius: 30px;
}
.separator-thick {
    border-top: 3px solid rgba(0,0,0,.1);
    margin: 0px;
}
.setting-preview {
    padding-top: 0px !important;
}
.setting-preview-menu {
    padding: 10px 0px;
}
.setting-preview-menu .setting-preview-menu-item {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid rgba(0,0,0,.1);
    padding: 10px 0px;
}
.setting-preview-menu .setting-preview-menu-item img {
    width: 18px;
    height: 18px;
    margin: 5px 20px 5px 0px;
}
.setting-preview-menu .setting-preview-menu-item .menu-info {
    margin: auto 0px;
}
.setting-preview-menu .setting-preview-menu-item .menu-info .txt-subtitle {
    margin: 0px 0px 2px 0px;
    font-size: 12px;
    line-height: 18px;
    color: #6F6F6F;
    font-weight: 500;
}

.personal-data .setting-preview-card-profile {
    align-items: flex-start;
}
.personal-data .setting-preview-card-profile > .load-avatar{
    margin:auto;
}
.personal-data .form-hook .btn {
    margin-top:34px;
}




.mycampaign-report{
    margin-top:65px !important;
}

.mycampaign-report .tab-menu{
    display: grid;
    grid-template-columns: auto auto;
    text-align: center;
    margin-bottom: 15px;
}
.mycampaign-report .tab-menu > div{
    padding-bottom: 4px;
    border-bottom: 1px solid var(--mono-gray5);
    cursor: pointer;
}
.mycampaign-report .tab-menu .active{
    color:var(--primary1);
    border-bottom: 2px solid var(--primary1);
}
.mycampaign-report .list-campaign {
    margin-top:83px;
}
.report-detail .report-status {
    padding: 20px;
    margin-bottom: 5px;
}
.report-header {
    display: flex;
    flex-wrap: wrap;
}
.report-header img {
    width: 65px;
    height: 40px;
    margin-right: 16px
}
.report-evidence {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.report-evidence img {
    width: 60px;
    height: 60px;
    border: 1px solid var(--mono-gray4);
    border-radius: 8px;
    margin-right: 10px;
}

.report-detail .widget-campaign{
    display:grid;
    grid-template-columns: 1fr 11fr;
    grid-column-gap: 16px;
    align-items: center;
}
.report-detail .widget-campaign .campaigner img{
    margin-left:3px;
}
.report-detail .campaign-card-image .center-cropped{
    width: 73px;
    height: 36px;
}

.respond-card{
    background-color: var(--mono-gray6);
    border-radius: 8px;
    padding:15px;
}
.respond-card .card-profile{
    border-radius: 4px;
    display: grid;
    grid-template-columns: 1fr 9fr;
    grid-gap: 16px;
    margin-bottom:10px;
    align-items:center;
}
.respond-card .card-profile > div:first-child > img{
    width:48px;
    height:48px;
}
/* Banners Stylesheet */
.header-landing-tbt header {
    background: #EA9A43;
}
.header-landing-tbt header .head-title {
    color: #FFFFFF;
}
.header-landing-tbt .ic-arrow-left {
    background: url(https://bantutetangga.com/static/media/ic-back-white.4ae4b2f9.svg) no-repeat;
}

.main-landing-tbt {
    padding: 0 !important;
}
.main-landing-tbt .section-banner img {
    width: 100%;
    height: auto;
}

.main-landing-tbt .section{
    padding:20px;
}

.main-landing-tbt .banner-home {
    padding: 16px 20px;
    grid-gap: 8px;
    gap: 8px;
    background: #172C3D;
    min-height: 150px;
}
.main-landing-tbt .banner-home img {
    width: 100%;
    height: auto;
    max-height: 280px;
    border-radius: 8px;
}
.main-landing-tbt .banner-home h6 {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
}

.main-landing-tbt .banner-home .slick-next {
    right: 30px;
}
.main-landing-tbt .banner-home .slick-prev {
    left: 20px;
    z-index: 1;
}
.main-landing-tbt .banner-home .slick-next::before,
.main-landing-tbt .banner-home .slick-prev::before {
    font-size: 30px;
}
.main-landing-tbt .banner-home .slick-dots {
    bottom: 5px;
}
.main-landing-tbt .banner-home .slick-dots .slick-active button::before {
    color: var(--primary1);
    font-size: 8px;
    opacity: 1;
}
.main-landing-tbt .banner-home .slick-dots li button::before {
    color: #FFFFFF;
    font-size: 8px;
    opacity: 1;
}
.main-landing-tbt .slick-dots li {
    margin:0px;
}

.main-landing-tbt .content-banner img {
    width: 100%;
    height: auto;
}

.main-landing-tbt nav.tabs {
    padding: 0 !important;
}

.main-landing-tbt .tabs .nav-item.nav-link {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #6B7280;
    border: unset;
    border-bottom: 1px solid #D1D5DB;
}

.main-landing-tbt .tabs .nav-item.nav-link.active {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #F9A215;
    border: unset;
    border-bottom: 1px solid #F9A215;
    background: unset;
}

.main-landing-tbt .txt-donatur {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #374151;
}

.main-landing-tbt .content-avatar img {
    margin: 25px 12px 0 0;
}

.main-landing-tbt .txt-pesan {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #6B7280;
}

.main-landing-tbt .section-berita {
    margin-top: 40px;
}
.main-landing-tbt .section-berita .content-news > div {
    padding-bottom: 15px;
}
.main-landing-tbt .section-berita .content-news img {
    border-radius: 4px;
}

/* Favourite Campaign Stylesheet */
.main-landing-tbt .favourite-campaign-home {
    margin: 30px 0px 30px 0px;
    padding: 0px;
}

.main-landing-tbt .favourite-campaign-home .slick-next {
    top: 40%;
    z-index: 1;
    right: 15px;
}
.main-landing-tbt .favourite-campaign-home .slick-prev {
    top: 40%;
    left: 5px;
    z-index: 1;
}
.main-landing-tbt .favourite-campaign-home .slick-next::before, 
.main-landing-tbt .favourite-campaign-home .slick-prev::before {
    color: #fff;
    font-size: 30px;
    -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.main-landing-tbt .favourite-campaign-home .more-campaign{
    height: 191px;
    margin-top:10px;
    padding-top: 70px;
    width:180px;
    text-align: center;
}
.main-landing-tbt .favourite-campaign-home .more-campaign img{
    margin:auto;
}

/* Campaign Stylesheet */
.main-landing-tbt .campaign-home {
    
}
.main-landing-tbt .campaign-home-category {
    overflow-x: auto;
    white-space: nowrap;
    margin-top: 10px;
}
.main-landing-tbt .campaign-home-category button {
    margin: 0px 5px 0px 0px;
    display: inline-block;
    font-size:12px;
    font-weight: 600;
    line-height: 15px;
    padding:6px 10px !important;
    border-radius: 4px;
}


.main-landing-tbt .campaign-home-category .btn-disabled {
    background-color: var(--mono-gray7);
    border-color: var(--mono-gray5);
    color:var(--text-body);
}
.main-landing-tbt .campaign-home .campaign-home-header .campaign-home-header-text {
    text-align: left;
    z-index: 1;
}
.main-landing-tbt .campaign-home .campaign-home-header .campaign-home-header-action {
    text-align: right;
    font-weight: normal;
}

/** Loading */
.main-landing-tbt .image-cover {
    width: 100%;
}
.main-landing-tbt .cover-loading {
    height: 169px;
}
.main-landing-tbt .akun-saya{
    padding-top:0px;
    margin-top:-15px;
}

.main-landing-tbt .footer .container{
    background-color: var(--primary2);
    padding:32px 20px;
    color:var(--mono-gray7);
}
.main-landing-tbt .footer .address{
    margin-top:16px;
    line-height: 22px;
    margin-bottom: 32px;
}
.main-landing-tbt .footer-logo{
    margin-bottom: 20px;
}
.main-landing-tbt .footer-menu{
    display:flex;
    justify-content: space-between;
}

.main-landing-tbt .footer-menu > div div{
    margin-bottom: 8px;
}

.main-landing-tbt .footer .copyright{
    padding: 8px 20px 64px 20px;
    background-color: var(--primary1);
    text-align: center;
    color: var(--mono-gray7);
}

.main-landing-tbt .footer .sosmed{
    margin-top:32px;
    display: flex;
    justify-content: center;
    grid-gap:7%;
    gap:7%;
}

.main-landing-tbt .footer .sosmed image{

}

/** Button Show All */
.btn-home-showall {
    width: 100%;
    text-align: center;
}    

.line-border-bottom {
    border-bottom: 1px solid #E5E7EB;
}

.section-mitra-tbt {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px 20px;
    grid-gap: 8px;
    gap: 8px;
    background: #FFFFFF;
}
.section-mitra-tbt h5 {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #374151;
}
.section-mitra-tbt .list-logo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    grid-gap: 24px;
    gap: 24px;
}
.section-mitra-tbt img:first-child {
    margin-right: 28px;
}

.section-tbt-stats {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px 20px;
    grid-gap: 8px;
    gap: 8px;
    background: #172C3D;
}
.section-tbt-stats h4 {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #F9A215;
}
.section-tbt-stats p {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
}
.section-tbt-stats .stats-first {
    display: flex;
    flex-direction: row;
    padding: 0px;
    grid-gap: 8px;
    gap: 8px;
    width: 100%;
}
.section-tbt-stats .stats-first div {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
}
.section-tbt-stats .stats-second {
    display: flex;
    flex-direction: row;
    padding: 0px;
    grid-gap: 8px;
    gap: 8px;
}

@media (max-width: 320px){
    .main-landing-tbt .section {
        padding: 0px 10px;
    }
}
.main-tbt-category p {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #6B7280;
}
.main-tbt-category .container-donate .item-pay-type {
    padding: 12px 20px;
    grid-gap: 24px;
    gap: 24px;
}
.main-tbt-category .container-donate .item-pay-type a{
    display: inline-block !important;
}
.main-tbt-category .container-donate .float-right {
    right: 0;
    position: absolute;
    width: unset;
}
.main-tbt-category .container-donate .float-left {
    width: unset;
}
.main-tbt-category .float-left.txt-body.ct-title {
    max-width: 235px;
}
.main-tbt-category .float-left.txt-body.ct-title h6{
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #374151;
}
.main-tbt-category .float-left.txt-body.ct-title p{
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #6B7280;
}
.btn-linkmenu {
    cursor: pointer;
    text-decoration: unset !important;
    border: unset;
    background: unset;
    text-align: left;
}
.sample-img{
    width:188px;
    height:126px;
    background: #C4C4C4;
    margin:auto;
    margin-top:10px;
    margin-bottom:10px;
}

.splash-screen .result-donation{
    background: linear-gradient(360deg, #FFEED9 18.53%, rgba(255, 255, 255, 0) 100%);
}
.splash-screen .content-space::after{
    background:#ffefda;
    height:20px;
}
/* .splashscreen{
    padding:0px;
    background:#007DBE;
}
.splashscreen .navbar{
    background:#007DBE;
    box-shadow: none !important;
}

.splashscreen .btn{
    font-size:12px;
    font-weight: 600;
    padding:10px 20px;
    color:#007DBE;
}
.splashscreen .pray-text{
    margin-top: 20% !important;
    margin-bottom: 15% !important;
}

.splash-danger{
    background: #fbc3dc;
    color: #a94940 !important;
}

.splash-danger .text-white{
    color: #a94940 !important;
}

.splash-warning{
    background: #f8fbc3;
    color: #a98440  !important;
}
.splash-warning .text-white{
    color: #a98440  !important;
} */
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-size: 12px;
  background-color: #e5e5e5;
}
a,
a:hover {
  color: var(--primary1);
  text-decoration: none;
}
h5 {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.17px;
}
h6 {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.17px;
}
.cursor-pointer {
  cursor: pointer;
}

.w-40 {
  width: 40% !important;
}
.w-60 {
  width: 60% !important;
}
.w-90 {
  width: 90% !important;
}
.w-80 {
  width: 80% !important;
}
.w-70 {
  width: 70% !important;
}
.w-30 {
  width: 30% !important;
}
.ma-0 {
  margin: auto;
}
.mt-10 {
  margin-top: 10px;
}
.m-20 {
  margin: 20px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-16 {
  margin-top: 16px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-15 {
  margin-bottom: 15px;
}
.mb-16 {
  margin-bottom: 16px;
}
.mb-20 {
  margin-bottom: 20px;
}
.pt-10 {
  padding-top: 10px;
}
.pt-20 {
  padding-top: 20px;
}
.pb-10 {
  padding-bottom: 10px;
}
.pb-20 {
  padding-bottom: 20px;
}
.pl-20 {
  padding-left: 20px;
}
.pr-20 {
  padding-right: 20px;
}
main {
  min-height: calc(100vh);
  position: relative;
  width: 100%;
  box-sizing: border-box;
  background-color: #ffffff;
  padding-top: 57px;
  padding-bottom: 62px;
}
.top-logo {
  padding: 12px 16px;
  background: var(--primary3);
  text-align: center;
}
.container {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 30px;
}
.section {
  padding: 16px 20px;
}
.box-bordered {
  border: 1px solid var(--mono-gray5);
  border-radius: 8px;
  padding: 12px 16px;
  margin-top: 5px;
}
.box-gradient-bottom {
  background: linear-gradient(
    360deg,
    #ffeed9 18.53%,
    rgba(255, 255, 255, 0) 100%
  );
}
.content-space {
  position: relative;
  bottom: 0;
  width: 100%;
  color: black;
  height: 3px;
  border-bottom: 5px solid #f2f2f2;
  /* box-shadow: 0px 6px 16px rgb(151 151 151 / 16%); */
}
.content-space::before {
  content: "";
  position: absolute;
  width: 99%;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
  margin-left: 2px;
  top: -11px;
  height: 10px;
}

.content-space::after {
  content: "";
  position: absolute;
  width: 100%;
  bottom: 1px;
  height: 14px;
  background: white;
}
.nav,
main {
  margin: 0 auto !important;
  max-width: 480px;
}

.no-header {
  padding-top: 25px;
}

.no-border {
  border: none !important;
}
.no-padding {
  padding: 0px !important;
}
.no-bg {
  background: none !important;
}
.no-bs {
  box-shadow: none !important;
}

.nav {
  padding-top: 10px;
}

.navbar .btn {
  margin-left: 20px;
  margin-right: 20px;
}

/*---------------------------------*/

/* custom button */
button:focus {
  outline: none !important;
}

.btn {
  letter-spacing: -0.165px;
  border-radius: 10px;
  font-weight: 600;
  padding: 13.5px 16px;
  font-size: 14px;
  line-height: 22px;
}
.btn .arrow-right {
  margin-left: 12px;
}
.btn-lg {
  padding: 16.5px 16px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}
.btn-sm {
  font-weight: 600;
  padding: 8px 16px;
  font-size: 14px;
  line-height: 22px;
}

.btn-primary {
  background: linear-gradient(99.96deg, #ff8514 6.49%, var(--primary1) 92.13%);
  box-shadow: 0px 6px 16px rgba(255, 133, 20, 0.16);
  border: none;
  color: #ffffff;
}
.btn-primary:hover {
  background-color: var(--primary2);
  border: 1px solid var(--primary2);
  color: #ffffff;
}
.btn-primary.focus,
.btn-primary:focus,
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active {
  background: linear-gradient(99.96deg, #ff8514 6.49%, var(--primary1) 92.13%);
  box-shadow: 0px 6px 16px rgba(255, 133, 20, 0.16);
  border: none;
}
.btn-primary.disabled,
.btn-primary:disabled {
  background-color: #e5e7e9;
  border: 1px solid #e5e7e9;
  /* color:var(--text-disabled); */
}

.btn-donate {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  font-style: normal;
  background: #007ff8;
  box-shadow: 0px 1px 3px rgba(0, 127, 248, 0.1),
    0px 1px 2px -1px rgba(0, 127, 248, 0.1);
  border-radius: 8px;
  border: none;
  color: #ffffff;
}
.btn-donate:hover {
  background: #66b2fb;
  box-shadow: 0px 1px 3px rgba(0, 127, 248, 0.1),
    0px 1px 2px -1px rgba(0, 127, 248, 0.1);
  border: none;
  color: #ffffff;
}
.btn-donate.focus,
.btn-donate:focus,
.btn-donate:not(:disabled):not(.disabled).active,
.btn-donate:not(:disabled):not(.disabled):active {
  background: linear-gradient(92.07deg, #3399f9 0%, #0066c6 99.85%);
  box-shadow: 0px 1px 3px rgba(0, 127, 248, 0.1),
    0px 1px 2px -1px rgba(0, 127, 248, 0.1);
  border-radius: 8px;
  border: none;
}
.btn-donate.disabled,
.btn-donate:disabled {
  background-color: #e5e7e9;
  border: 1px solid #e5e7e9;
  /* color:var(--text-disabled); */
}

.btn-secondary {
  background-color: transparent;
  border: 1px solid var(--primary1);
  color: var(--primary2);
}
.btn-secondary:hover {
  background-color: #ffeed9;
  color: var(--primary2);
  border: 1px solid var(--primary1);
}
.btn-secondary.focus,
.btn-secondary:focus,
.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active {
  background: #fff8f0;
  border: 1px solid #f9a215;
  box-sizing: border-box;
  box-shadow: inset 2px 2px 10px #f9a215;
  color: var(--primary2);
}
.btn-secondary.disabled,
.btn-secondary:disabled {
  background-color: #e5e7e9;
  border: 1px solid #e5e7e9;
  color: var(--text-disabled);
}
.btn-disabled,
.btn-disabled:hover {
  color: var(--text-disabled);
  border-color: #e5e7e9;
  background-color: #e5e7e9;
  cursor: inherit;
}

.btn-disabled2,
.btn-disabled2:hover {
  color: var(--text-disabled);
  border-color: #e5e7e9;
  cursor: inherit;
}
.btn-dashed {
  border-style: dashed;
  border-color: var(--mono-gray4);
  box-shadow: none;
  background-color: #ffffff;
}

.btn-gray,
.btn-gray:hover {
  border: 1px solid var(--text-caption);
  color: var(--text-caption);
}
.btn img {
  margin-top: -3px;
  margin-right: 3px;
  border-radius: unset;
}

/* dropdown */
.dropdown-menu {
  border-radius: 8px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  padding: 0px;
  border: none;
}
.dropdown-item {
  padding: 8px 18px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.65);
  border-bottom: 1px solid var(--mono-gray6);
}
.dropdown-item:last-child {
  border: none;
}
.btn-dropdown > .btn-primary,
.btn-dropdown > .btn-primary:hover,
.btn-dropdown .show > .btn-primary.dropdown-toggle,
.btn-dropdown > .btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  background: none;
  border: none;
  padding: 0px !important;
  box-shadow: none;
  outline: none;
}
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
  border: none;
  outline: none;
}
.btn-dropdown > .btn-primary::after {
  margin: 0;
  margin-right: 2px;
}
.dropdown-item.active,
.dropdown-item:active,
.dropdown-item:hover {
  background-color: var(--mono-gray6);
}
.dropdown-divider {
  margin: 0px 10px;
  border-top: 1px solid #ffeed9;
}
.btn-dropdown {
  margin-right: 23px;
  margin-left: -6px;
}
.btn-dropdown .btn {
  margin-right: -20px;
}
.btn-dropdown .btn img {
  margin-left: 16px;
}
@media (max-width: 410px) {
  .btn {
    /* font-size: 12px; */
    /* line-height: 18px; */
    /* padding:13.5px 16px;  */
  }
  .btn-sm {
    /* padding:8px 16px; */
  }
  .spinner-border {
    width: 1rem !important;
    height: 1rem !important;
  }
}

@media (max-width: 321px) {
  .btn {
    /* font-size: 11px; */
    /* line-height: 17px;
        padding: 13.5px 16px;
        padding: 10px 12px; */
    border-radius: 8px;
  }
}

/* -------------------------------- */
/* Flex */
.content-flex {
  display: flex;
  align-items: baseline;
}
.content-flex > div {
  margin-right: 2px;
}
.content-flex > div:last-child {
  margin-right: 0px;
}

/* Grid */
.content-grid {
  display: grid;
  align-items: center;
}
.grid-2 {
  grid-template-columns: auto auto;
}
.grid-4-6 {
  grid-template-columns: 4fr 6fr;
}
.grid-3-7 {
  grid-template-columns: 3fr 7fr;
}
.grid-2-8 {
  grid-template-columns: 2fr 8fr;
}
.grid-1-9 {
  grid-template-columns: 1fr 9fr;
  grid-column-gap: 16px;
}
.grid-1-8-1 {
  grid-template-columns: 1fr 8fr 1fr;
}

.grid-8-2 {
  grid-template-columns: 8fr 2fr;
}
.content-grid > div:last-child {
  text-align: right;
}

/* modal */

.modal-content {
  max-width: 480px;
  margin: auto;
  border-radius: 8px;
  border: none;
  padding: 12px;
}
.modal-content .modal-title {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
}
.modal-content .close {
  font-size: 40px;
  font-weight: normal;
  color: #333333;
  padding: 0px;
  padding-top: 10px;
  padding-right: 10px;
}
.modal-content.modal-footer {
  min-height: 40px;
}
.modal-content .modal-header {
  padding: 8px 12px;
  border: none;
  margin-bottom: 10px;
}
.modal-content .modal-body {
  padding: 0px;
}
.modal-content .modal-body > div {
  border-bottom: 1px solid #d4d2d3;
  padding: 8px 10px;
}
.modal-content .close > span:first-child {
  color: #333333;
  font-weight: 300;
}

/* -------------------------------- */

p {
  margin-bottom: 20px;
}
.clear-both {
  clear: both;
}

/* container firebase */
.container-firebase {
  margin-bottom: 10px;
}

/* Toastify */

.Toastify__toast-container--top-center {
  width: 427px !important;
  top: 6em;
}

@media (max-width: 427px) {
  .Toastify__toast-container--top-center {
    width: 90% !important;
    margin-left: 5%;
  }
}
.Toastify__toast {
  min-height: 10px !important;
  padding-left: 15px !important;
  border-radius: 4px;
}
.Toastify__toast--error {
  color: var(--red);
  background-color: #faeded;
  border: 1px solid #fbcacb;
}
.Toastify__toast--info {
  color: var(--blue);
  background-color: #ebf1f8 !important;
  border: 1px solid #b9dffc;
}
.Toastify__toast svg > path {
  color: var(--mono-gray1);
  opacity: 0.2;
}

.Toastify__toast--success {
  color: var(--green);
  background-color: #e7f9f1;
  border: 1px solid #c6f1cf;
}

.Toastify__toast--warning {
  color: #ffb443;
  background-color: #fff8e4;
  border: 1px solid #fff0c2;
}

/* Progress Bar */
.progress {
  width: 100%;
  height: 6px;
  background: #ebebeb;
  border-radius: 3px;
  overflow: hidden;
}
.progress-bar {
  display: block;
  height: 100%;
  background-color: #f9a215;
  background-size: 300% 100%;
  -webkit-animation: progress-animation 2s linear infinite;
          animation: progress-animation 2s linear infinite;
  width: 17%;
  border-radius: 3px;
}
.progress-big {
  height: 8px;
  border-radius: 4px;
}
.progress-big .progress-bar {
  border-radius: 4px;
}

/* spinner */
.spinner-border {
  width: 1.2rem;
  height: 1.2rem;
}
.spinner-border-sm {
  width: 1rem;
  height: 1rem;
}

/* text */
.text-middle {
  vertical-align: middle;
  margin: auto;
  text-align: center;
  margin-top: 40vh;
}
.text-left {
  text-align: left !important;
}

/* Alert */
.alert {
  padding: 8px;
  text-align: center;
  border-radius: 8px;
}
.alert-danger {
  color: var(--text-title) !important;
  background-color: var(--primary3);
  border: none;
}
.alert-info {
  color: #018dc0 !important;
  background-color: #cbebfb;
  border: 1px solid #3abbe9;
}
.alert-info label {
  color: #018dc0 !important;
}
.alert-info a {
  color: var(--secondary2);
  font-weight: 600;
}

.alert-info .styled-checkbox:checked + label:before {
  background-color: #018dc0 !important;
  border: 1px solid #387297;
}
.alert-sm {
  padding: 3px 10px !important;
  font-size: 10px;
  line-height: 15px;
}

.info-green {
  background: rgba(17, 192, 91, 0.1);
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 10px;
}

.info-red {
  background: #f63f441a;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 10px;
}
.info-red2 {
  background: #f9c8c7;
  border-radius: 8px;
  padding: 12px 16px;
  margin-bottom: 10px;
  border: 1px solid #e95e5e;
  color: #e95e5e;
  justify-content: start;
}

.info-form {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  padding: 12px 16px;
  color: #6b7280;
  justify-content: start;
  background: #fef3c7;
  border: 1px solid #fddaa1;
  border-radius: 4px;
}
.info-form p {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #6b7280;
}
.info-form .notice {
  top: -15px;
  position: relative;
}
.info-form a {
  color: var(--secondary2);
}
.info-form2 {
  background: #cbebfb;
  border-radius: 8px;
  padding: 10px 16px;
  color: #018dc0;
  border: 1px solid #3abbe9;
  justify-content: start;
}

.info-form2 a {
  color: var(--secondary2);
  font-weight: 600;
}

.info-form3 {
  border-radius: 5px;
  padding: 10px 16px;
  color: var(--text-body);
  background: #ebf1f8;
  border: 1px solid #b9dffc;
}

.info-conditions {
  background: #f2f2f2;
  border-radius: 8px;
  padding: 10px 16px;
  color: #6f6f6f;
  border: 1px solid #f2f2f2;
  justify-content: start;
}

.hide {
  display: none;
}
.show {
  display: block;
}

.react-datepicker-wrapper {
  display: block !important;
}

.campaign-content {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 10px;
}

/* Loading text */
.gradient {
  -webkit-animation-duration: 1.8s;
          animation-duration: 1.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-name: placeHolderShimmer;
          animation-name: placeHolderShimmer;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  background-color: #f6f7f8;
  background-color: linear-gradient(
    to right,
    #fafafa 8%,
    #f4f4f4 38%,
    #fafafa 54%
  );
  background-size: 1000px 640px;
  border-radius: 4px;
  margin-top: 10px;
}

@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.cover-loading {
  height: 200px;
  width: 100%;
}

.text-loading {
  height: 10px;
}
.text-loading-90 {
  width: 90%;
}
.text-loading-80 {
  width: 80%;
}
.text-loading-70 {
  width: 70%;
}
.text-loading-60 {
  width: 60%;
}
.text-loading-50 {
  width: 50%;
}
.text-loading-40 {
  width: 40%;
}
.text-loading-30 {
  width: 30%;
}
.text-loading-20 {
  width: 20%;
}
.height-5 {
  height: 5px;
}
.height-20 {
  height: 20px;
}
.height-30 {
  height: 30px;
}
.height-50 {
  height: 50px;
}
.height-70 {
  height: 70px;
}
.height-100 {
  height: 100px;
}

.react-confirm-alert-body {
  color: #333 !important;
}
.react-confirm-alert-body h1 {
  font-size: 16px !important;
  color: #803d37 !important;
}
.react-confirm-alert-button-group > button {
  /* background-color: var(--primary1) !important; */
  background-color: #fbc3dc !important;
  color: #803d37;
  border: 0.1px solid #e8abc6;
  padding: 4px 15px;
  border-radius: 4px;
}

.react-confirm-alert-button-group > button:last-child {
  background-color: white !important;
}

.checkbox {
  margin-left: 16px;
  position: relative;
  color: #777777;
  border-radius: 4px;
}
.checkbox input {
  width: 20px;
  height: 20px;
}
.checkbox span {
  position: absolute;
  left: 30px;
  top: 2px;
}

.grid-3 {
  grid-template-columns: 0.4fr auto auto;
}
.grid-top {
  align-items: flex-start;
}

/* accordion */
.accordion {
  border: none;
  margin-top: 10px;
}
.accordion__item {
  margin-bottom: 8px;
  border-radius: 8px;
  border: 1px solid var(--mono-gray5);
}
.accordion__button {
  background-color: #ffffff;
  border-radius: 5px;
  font-weight: 600;
  font-size: 12px;
  padding: 15px;
  border-color: var(--mono-gray4);
}
.accordion__button:hover {
  background-color: var(--mono-gray6);
}
.accordion__button:before {
  color: var(--text-disabled);
  position: absolute;
  right: 26px;
  transform: rotate(45deg);
}
.accordion__button:focus {
  outline: none;
}
.accordion__panel {
  padding-top: 10px;
}
.accordion__panel ul {
  padding: 0px 20px;
}

/* card */
.cp-card {
  padding: 16px;
  border: none;
  margin-bottom: 10px;
  background: #ffffff;
  box-shadow: 0px 6px 16px rgba(151, 151, 151, 0.16);
  border-radius: 8px;
}
.card-state {
  border: 1px solid var(--mono-gray4);
  box-sizing: border-box;
  border-radius: 16px;
  color: var(--text-caption);
  padding: 2px 16px;
}
.state-green {
  border-color: var(--green);
  color: var(--green);
}
.state-yellow {
  border-color: var(--yellow);
  color: var(--yellow);
}
.state-primary1 {
  border-color: var(--primary1);
  color: var(--primary1);
}

.state-red {
  border-color: var(--red);
  color: var(--red);
}
.cp-card hr {
  margin-top: 10px;
  margin-bottom: 10px;
  border-color: var(--mono-gray5);
}
.cp-card .img-campaign {
  width: 63px;
}
.cp-card .progress {
  height: 3px;
  border-radius: 3px;
  margin: 10px 0px;
}

.cp-card .card-actions {
  display: flex;
  padding-left: 0;
  justify-content: space-between;
  align-items: center;
}
.cp-card .card-actions > div,
.cp-card .card-actions > a {
  margin: 0px 5px;
}
.flex-start {
  align-items: flex-start;
}

/* Firebase UI */
.firebaseui-idp-icon-wrapper {
  display: block !important;
}
.firebaseui-container {
  max-width: 100% !important;
}
.firebaseui-card-content {
  padding: 0px !important;
}
.firebaseui-idp-list {
  display: flex;
  justify-content: center;
}
.firebaseui-list-item {
  border: 1px solid var(--mono-gray4);
  margin: 0px !important;
  padding: 0px 6px !important;
  border-radius: 10px;
  /* width: 80%; */
  width: 56%;
}
.firebaseui-idp-button {
  box-shadow: none !important;
  text-align: center !important;
  display: flex !important;
  justify-content: center !important;
  align-items: baseline !important;
}
.firebaseui-idp-button .firebaseui-idp-icon-wrapper {
  position: relative;
}
.firebaseui-idp-text,
.firebaseui-idp-google > .firebaseui-idp-text {
  font-weight: 600;
  font-size: 12px !important;
  line-height: 18px;
  color: #6f6f6f !important;
  font-family: "Rubik";
  margin-top: 3px !important;
  padding-left: 12px !important;
}
.firebaseui-idp-text,
.firebaseui-idp-text.firebaseui-idp-text-long {
  display: none !important;
}

.firebaseui-idp-text,
.firebaseui-idp-text.firebaseui-idp-text-short {
  display: table-cell !important;
}
.firebaseui-idp-icon {
  width: 20px !important;
  height: 20px !important;
}
.firebaseui-idp-facebook {
  background-color: #ffffff !important;
}
.firebaseui-idp-facebook .firebaseui-idp-icon-wrapper {
  background-color: #3b5998;
}
.firebaseui-idp-facebook .firebaseui-idp-icon {
  background-color: #3b5998;
}

.firebaseui-idp-list > li:first-child {
  margin-right: 8px !important;
}
.firebaseui-idp-list > li:last-child {
  margin-left: 8px !important;
}
.opacity {
  opacity: 0.7;
}
.loading-screen {
  position: absolute;
  margin: auto;
  left: 50%;
  top: 50%;
}

/* React Datepicker UI*/
.react-datepicker-wrapper {
  cursor: pointer;
}
.react-datepicker-popper {
  top: -9px;
  right: 0px;
  left: inherit !important;
}
.react-datepicker {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-size: 12px;
  border: 1px solid var(--mono-gray4);
  -webkit-filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.15));
          filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.15));
  border-radius: 5px;
}
.react-datepicker__current-month {
  font-size: 14px;
  color: var(--text-title);
  font-weight: normal;
}
.react-datepicker__day-name {
  margin-top: 15px;
  font-size: 14px;
}
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: var(--text-title);
}
.react-datepicker__day--disabled {
  color: var(--text-disabled);
}
.react-datepicker__header {
  background-color: #f0f0f0;
  border-bottom: 1px solid var(--mono-gray4);
}
.react-datepicker__navigation--next {
  border-left-color: #333333;
}
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: transparent;
}
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: var(--primary1);
  color: #ffffff;
  border: none;
  outline: none;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  background-color: var(--primary3);
  border: none;
  outline: none;
}
.react-datepicker__day--disabled:hover,
.react-datepicker__month-text--disabled:hover,
.react-datepicker__quarter-text--disabled:hover,
.react-datepicker__year-text--disabled:hover {
  background-color: transparent;
}
.react-datepicker__day--today {
  border: 1px solid var(--primary1);
  color: var(--text-title);
  font-weight: normal;
  border-radius: 0.3rem;
}

/* slick */
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
          filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

/* Rectangle */
.box-gradient-botup-brown {
  background: linear-gradient(
    360deg,
    #ffeed9 18.53%,
    rgba(255, 255, 255, 0) 100%
  );
}
.box-gradient-botup-brown .content-space::after {
  background: #ffefda;
  height: 20px;
}

.box-gradient-botup-gray {
  background: linear-gradient(
    360deg,
    #e2e2e2 18.53%,
    rgba(255, 255, 255, 0) 100%
  );
}
.box-gradient-botup-gray .content-space::after {
  background: #e5e5e5;
  height: 20px;
}

/* limit string */
.str-limit {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-break: break-word;
  overflow: hidden;
}
.str-limit-1 {
  -webkit-line-clamp: 1;
}
.str-limit-2 {
  -webkit-line-clamp: 2;
}
.str-limit-3 {
  -webkit-line-clamp: 3;
}

.list-option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--mono-gray5);
  cursor: pointer;
  grid-gap: 8px;
  gap: 8px;
  color: var(--text-title);
}
.list-option:hover {
  color: var(--text-title);
}
.list-option > div:first-child,
.list-option > div:last-child {
  padding-top: 9px;
  padding-bottom: 9px;
  margin-top: 2px;
  margin-bottom: 2px;
}

.my-donate .state {
  display: flex;
  list-style: none;
  padding-left: 0;
  justify-content: space-between;
  overflow-x: auto;
}
.my-donate .state li {
  border: 1px solid var(--mono-gray5);
  border-radius: 8px;
  text-align: center;
  margin-left: 5px;
  margin-right: 5px;
  line-height: 18px;
  padding: 8px 12px;
  width: 100%;
  color: var(--text-caption);
  cursor: pointer;
  font-weight: 600;
  align-items: center;
  white-space: nowrap;
  display: flex;
  justify-content: space-between;
}
.my-donate .state li:first-child {
  margin-left: 0px;
}
.my-donate .state li:last-child {
  margin-right: 0px;
}
.my-donate .state .active {
  background-color: var(--primary1);
  border: 1px solid var(--primary1);
  color: #ffffff;
}

.modal-filter {
  display: flex;
  list-style: none;
  padding-left: 0;
  justify-content: space-between;
  overflow-x: auto;
}
.modal-filter li {
  border: 1px solid var(--mono-gray5);
  border-radius: 8px;
  text-align: center;
  margin-left: 5px;
  margin-right: 5px;
  line-height: 18px;
  padding: 8px 12px;
  width: 100%;
  color: var(--text-caption);
  cursor: pointer;
  font-weight: 600;
  align-items: center;
  white-space: nowrap;
  display: flex;
  justify-content: space-between;
}
.modal-filter li:first-child {
  margin-left: 0px;
}
.modal-filter li:last-child {
  margin-right: 0px;
}
.modal-filter .active {
  background-color: var(--primary3);
  border: 1px solid var(--primary1);
  color: var(--primary1);
}
.border-dashed {
  border-style: dashed;
}

.modal-confirm .modal-header {
  display: block;
  text-align: center;
}
.modal-confirm .modal-title {
  font-size: 18px;
  color: var(--text-title);
  font-weight: 700;
}
.modal-confirm .modal-body {
  text-align: center;
  margin-bottom: 16px;
}
.modal-confirm .modal-footer {
  justify-content: space-around;
  font-size: 14px;
  padding: 0px;
  padding-top: 12px;
}

.modal-bottom .modal-content {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.modal-bottom .modal-title {
  display: flex;
  justify-content: space-between;
  grid-gap: 16px;
  gap: 16px;
}
.modal-bottom .modal-body {
  max-height: 500px;
  overflow: auto;
}

.favourite-card {
  display: block;
  width: 180px;
  margin: 10px 15px 0px 0px;
  border: 1px solid #f2f2f2;
  margin-bottom: 8px;
  border-radius: 8px;
  margin-left: 1.8px;
}
.favourite-card:hover {
  text-decoration: none;
  color: #374151;
}
.favourite-card-image {
  width: 100%;
  height: 100px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.favourite-card-name {
  color: #374151;
  margin-top: 8px;
  margin-bottom: 4px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-word;
  overflow: hidden;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
}
.favorite-card-campaigner-name {
  margin-bottom: 5px;
  font-size: 10px;
  line-height: 16px;
  color: var(--primary1);
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-word;
  overflow: hidden;
  max-width: 105px;
}
.favorite-card-campaigner-name img {
  display: unset !important;
  margin-left: 3px;
}
.favourite-card-info {
  height: 97px;
  width: 90%;
  position: relative;
  padding: 0px 5px;
}
.favourite-card-progress-percentage {
  height: 3px !important;
}
.favourite-card-progress-info {
  width: 100%;
  position: absolute;
  bottom: 0px;
}
.favourite-card-progress-nominal {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 4px;
}
.favourite-card-progress-info-left {
  text-align: left;
}
.favourite-card-progress-info-right {
  flex: 1 1;
  text-align: right;
}
.favourite-card-progress-nominal p {
  margin: 3px 0px 0px 0px;
  color: #969696;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}

.preverification .campaigner-type-v1 {
  display: block;
  flex: unset;
}

.preverification .campaigner-type-v1 .active {
  background: #fef2de;
  border: 1px solid #f9a215;
}

.preverification .campaigner-type-v1 > div {
  position: relative;
  border: 1px solid var(--mono-gray5);
  border-radius: 8px;
  text-align: left;
  padding: 32px 16px;
  cursor: pointer;
  min-height: 80px;
  margin: 8px;
}

.icon-verified-fav {
  top: 4px;
  position: relative;
}

.txt-icon-menu {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #6b7280;
}
.txt-hide {
  display: none;
}
.txt-checkbox {
  max-width: 85%;
}

.section.description .txt-body-m p {
  line-height: 20px;
  margin-bottom: 16px;
}

.blog-card {
  display: block;
  width: 240px;
  margin: 10px 15px 0px 0px;
  border: 1px solid #f2f2f2;
  margin-bottom: 8px;
  border-radius: 8px;
  margin-left: 1.8px;
}
.blog-card:hover {
  text-decoration: none;
  color: #374151;
}
.blog-card-image {
  width: 100%;
  height: 136px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.blog-card-name {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #374151;
  margin-top: 8px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-word;
  overflow: hidden;
}
.blog-card .txt-date {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #9ca3af;
}
.favorite-card-campaigner-name img {
  display: unset !important;
  margin-left: 3px;
}
.favourite-campaign-home .section-title {
  display: flex;
}
.favourite-campaign-home .section-title a {
  display: flex;
  float: right;
  right: 35px;
  position: absolute;
  margin-top: 5px;
}
.favourite-campaign-home .section-title img {
  margin-left: 6px;
}
.favourite-campaign-home .section-title .txt-to-blog {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #f9a215;
}
.blog-card-info {
  height: 72px;
  width: 90%;
  position: relative;
  padding: 0px 5px;
}
.blog-card-progress-percentage {
  height: 3px !important;
}
.blog-card-progress-info {
  width: 100%;
  position: absolute;
  bottom: 0px;
}
.blog-card-progress-nominal {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 4px;
}
.blog-card-progress-info-left {
  text-align: left;
}
.blog-card-progress-info-right {
  flex: 1 1;
  text-align: right;
}
.blog-card-progress-nominal p {
  margin: 3px 0px 0px 0px;
  color: #969696;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}

@media (max-width: 450px) {
  .campaign-card-campaigner-name {
    max-width: 100px;
  }
}

@media (max-width: 375px) {
  .favourite-card-info {
    height: 85px;
  }
  .favourite-card-name {
    font-size: 12px;
    line-height: 18px;
  }
  .favourite-card-progress-nominal p {
    font-size: 10px;
    line-height: 16px;
  }
  .favorite-card-campaigner-name {
    font-size: 8px;
    line-height: 14px;
  }
  .campaign-card-campaigner-name {
    max-width: 90px;
  }
}
@media (max-width: 330px) {
  .campaign-card-campaigner-name {
    max-width: 65px;
  }
}
.custom-toggle-react.react-toggle--checked .react-toggle-track {
  background-color: #f9a215;
  border: #f9a215;
}
.custom-toggle-react.react-toggle--checked:hover .react-toggle-track {
  background-color: #f9a215;
}

.custom-toggle-react .react-toggle-track {
  background-color: #e5e7eb;
}

.custom-toggle-react:hover .react-toggle-track {
  background-color: #e5e7eb;
}

.react-toggle-thumb {
  border: none;
}
.modal-content-pwa .modal-content {
  padding: unset;
  max-width: 380px;
}
.modal-content-pwa .modal-content .img-thumb-pwa {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  height: 231px;
  width: 100%;
  object-fit: cover;
  object-position: center;
}
.modal-install-pwa {
  align-items: center;
  background: #FFFFFF;
  border-radius: 8px;
  padding: 16px 24px 16px 24px;
}
.modal-install-pwa  h6 {
  color: #374151;
  color: var(--neutral-n-700, #374151);
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 155.556% */
  padding-bottom: 6px;
}
.modal-install-pwa p {
  color: #6B7280;
  color: var(--neutral-n-500, #6B7280);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.modal-install-pwa button {
  margin: 0 auto;
  display: flex;
}
.modal-install-pwa button svg{
  margin-right: 8px;
}
.modal-install-pwa .btn-back {
  margin-top: 8px;
}
.modal-install-pwa #install-pwa {
  display: flex;
  border-radius: 6px;
  padding: 12px;
  margin: 16px auto;
}
.modal-install-pwa #install-pwa span {
  display: flex;
}
.modal-install-pwa #install-pwa span svg {
  margin-right: 8px;
} 
.bg-footer-modal {
  border-radius: 8px;
}
.bg-footer-modal button {
  width: 100%;
  border: unset;
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  align-self: stretch;
  background: #E5E7EB;
  background: var(--neutral-n-200, #E5E7EB);
  cursor: pointer;
  display: flex;
  color: #6B7280;
  color: var(--neutral-n-500, #6B7280);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  border-radius: 8px;
}
.txt-desc-payment {
  border-radius: 8px;
  background: #F3F4F6;
  background: var(--neutral-n-100, #F3F4F6);
  color: #6B7280;
  color: var(--neutral-n-500, #6B7280);
  font-family: 'Rubik';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  margin-top: 20px;
  padding: 2px 12px;
  display: inline-block;
}
.data-group-payment {
  display: -webkit-box;
}


.form-hook .form-group label, .error-input{
	margin-left:16px;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	color:var(--text-caption);
}
.form-hook .error-input{
	color:var(--red);
	margin-top:4px;
}
.form-hook input, .form-hook textarea, .form-hook select, .form-control{
    border: 1px solid var(--text-disabled);
    box-sizing: border-box;
    border-radius: 8px;
    padding:10.5px 16px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color:#242424;
    height:auto;
}
.form-hook .fh-input-group{
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

.form-hook textarea{
	min-height: 128px;
}

.form-hook .input-group input{
	border-right:none;
}
.form-hook .input-group .input-group-prepend > span{
	background: #ffffff;
    border-radius: 0px 5px 5px 0px;
    border: 1px solid #D4D2D3;
    border-left: none;
}


.form-hook .ig-left, .form-hook .ig-right {
    position: relative;
}
.form-hook .ig-right .form-control{
	padding-right:45px;
	cursor: pointer;
}
.form-hook .ig-right .input-right{
  position: absolute;
  right: 0;
  margin-right: 16px;
  top: 12px;
}

.form-hook .ig-left .form-control{
	padding-left:64px;
}
.form-hook .ig-left .input-left{
	position: absolute;
    left: 0;
    top: 1px;
    border-right: 1px solid var(--text-disabled);
    padding: 11px 16px;
}

.form-hook .btn-next{
	margin-top:40px;
}

.input-file .container-file{
	border-radius: 5px;
	border: 1px solid var(--text-disabled);
	padding:10px 16px;
}

/* ck editor */

.ck.ck-editor__editable_inline{
	border: 1px solid var(--text-disabled);
	min-height: 300px;
  }
  .ck-toolbar, .ck-content{
	border-radius:5px !important;
	border:none !important;
  }
  
  .container-ckeditor{
	border: 1px solid #c4c4c4;
	border-radius: 4px;
  }
  .ck-focused{
	box-shadow: none !important;
  } */


/* form alert */
.error-input {
	color: var(--red);
	margin-top:5px;
}

/* Responsive */
@media (min-width: 411px){
	/* body */
	.form-hook input, .form-hook textarea, .form-hook select, .form-hook .fh-input-group, .form-control{
	  /* font-size: 14px; */
	  /* line-height: 22px; */
	}
  }
  
  
  @media (max-width: 420px) {
	.form-hook input, .form-hook textarea, .form-hook select, .form-hook .fh-input-group, .form-control{
		/* font-size: 12px; */
		/* line-height: 18px; */
	}
	.form-hook .form-group label, .error-input{
		/* font-size: 10px !important; */
		/* line-height: 16px; */
	}
  }


/* toggles */
@-webkit-keyframes ripple {
	 0% {
		 box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.0);
	}
	 50% {
		 box-shadow: 0px 0px 0px 15px rgba(0, 0, 0, 0.1);
	}
	 100% {
		 box-shadow: 0px 0px 0px 15px rgba(0, 0, 0, 0);
	}
}
@keyframes ripple {
	 0% {
		 box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.0);
	}
	 50% {
		 box-shadow: 0px 0px 0px 15px rgba(0, 0, 0, 0.1);
	}
	 100% {
		 box-shadow: 0px 0px 0px 15px rgba(0, 0, 0, 0);
	}
}
.md-radio {
	margin: 16px 0;
}
.md-radio.md-radio-inline {
	display: inline-block;
}
.md-radio input[type="radio"] {
	display: none;
}
.md-radio input[type="radio"]:checked + label:before {
	border-color: var(--primary1);
	background: var(--primary1);
	-webkit-animation: ripple 0.2s linear forwards;
	        animation: ripple 0.2s linear forwards;
}
.md-radio input[type="radio"]:checked + label:after {
	transform: scale(1);
}
.md-radio label {
	display: inline-block;
	min-height: 20px;
	position: relative;
	padding: 0 30px;
	margin-bottom: 0;
	cursor: pointer;
	vertical-align: bottom;
}
.md-radio label:before, .md-radio label:after {
	 position: absolute;
	 content: '';
	 border-radius: 50%;
	 transition: all 0.3s ease;
	 transition-property: transform, border-color;
}
.md-radio label:before {
	left: 0;
    width: 20px;
    height: 20px;
    border: 1px solid var(--primary1);
}
.md-radio label:after {
    left: 3px;
    width: 14px;
    height: 14px;
	transform: scale(0);
	background: var(--primary1);
	top:3px;
}
*, *:before, *:after {
	 box-sizing: border-box;
}


.styled-checkbox {
	position: absolute;
	opacity: 0;
}
.styled-checkbox + label {
	position: relative;
	cursor: pointer;
	padding: 0;
	color:var(--text-body);
	margin-left:25px !important;
	/* margin-left:45px !important; */
}
.styled-checkbox + label:before {
	content: '';
	margin-right: 10px;
	display: inline-block;
	vertical-align: text-top;
	width: 21px;
    height: 21px;
    border: 1px solid var(--mono-gray4);
	background-color: #ffffff;
	border-radius: 4px;
	margin-top:-2px;
	position:absolute;
	left:-32px;
	top:4px;
}
/* .styled-checkbox:hover + label:before { */
.styled-checkbox:hover {
	background: var(--primary1);
	border-color: var(--primary1);
}
.styled-checkbox:focus + label:before {
	box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
}
.styled-checkbox:checked + label:before {
	background: var(--primary1);
	border-color: var(--primary1);
}
.styled-checkbox:disabled + label {
	color: #b8b8b8;
	cursor: auto;
}
.styled-checkbox:disabled + label:before {
	box-shadow: none;
	background: #ddd;
}
.styled-checkbox:checked + label:after {
	content: '';
    position: absolute;
	left: -27px;
    top: 11px;
    background: white;
    width: 3px;
    height: 3px;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
    transform: rotate(45deg);
}

